











































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class PointNormalPlate extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  @Prop() private viewMode!: number;
  @Prop() private isTwoColor!: boolean;

  private get scorePlayer1(): number | string {
    const winner = this.$store.getters['match/getWinner']();
    const score = this.$store.getters['match/getPlayer1Score']();

    if (winner === this.C_WINNER_TBD) {
      return score;
    } else if (winner === this.C_WINNER_P1) {
      return 'W';
    } else if (winner === this.C_WINNER_P2) {
      return score;
    } else if (winner === this.C_WINNER_NONE) {
      return 'Draw';
    } else {
      return 0;      // ERROR
    }
  }

  private get scorePlayer2(): number | string {
    const winner = this.$store.getters['match/getWinner']();
    const score = this.$store.getters['match/getPlayer2Score']();

    if (winner === this.C_WINNER_TBD) {
      return score;
    } else if (winner === this.C_WINNER_P2) {
      return 'W';
    } else if (winner === this.C_WINNER_P1) {
      return score;
    } else if (winner === this.C_WINNER_NONE) {
      return 'Draw';
    } else {
      return 0;      // ERROR
    }
  }

  private get scoreTable1(): string[] {
    const result = this.$store.getters['match/getResult']();
    if (result === '') {
      return ['A', 'A', 'A'];
    } else {
      return result.split('');
    }
  }

private get scoreTable2(): string[] {
    const result = this.$store.getters['match/getResult']();
    if (result === '') {
      return ['B', 'B', 'B'];
    } else {
      return result.split('');
    }
  }

  private get raceToPlayer1(): number {
    return this.$store.getters['match/getPlayer1RaceTo']();
  }

  private get raceToPlayer2(): number {
    return this.$store.getters['match/getPlayer2RaceTo']();
  }

  private get btnColorP1(): string {
    const score1 = this.$store.getters['match/getPlayer1Score']();
    const score2 = this.$store.getters['match/getPlayer2Score']();
    const diff = this.$store.getters['match/getPlayer1RaceTo']() - score1;
    const status = this.$store.getters['match/getGameStatus']();
    const isFirst = this.isFirst;

    if (status < this.C_STATUS_PLAY) {
      return 'btn-secondary';
    } else {
      if (isFirst) {
        if (diff <= 1) {
          return 'btn-outline-danger';
        } else {
          return 'btn-outline-primary';
        }
      } else {
        if (diff <= 1) {
          return 'btn-danger disabled';
        } else {
          return 'btn-primary disabled';
        }
      }
    }
  }

  private get btnColorP2(): string {
    const score1 = this.$store.getters['match/getPlayer1Score']();
    const score2 = this.$store.getters['match/getPlayer2Score']();
    const diff = this.$store.getters['match/getPlayer2RaceTo']() - score2;
    const status = this.$store.getters['match/getGameStatus']();
    const isFirst = this.isFirst;
    if (status < this.C_STATUS_PLAY) {
      return 'btn-secondary';
    } else {
      if (!isFirst) {
        if (diff <= 1) {
          return 'btn-outline-danger';
        } else {
          if (this.isTwoColor) {
            return 'btn-outline-success';
          } else {
            return 'btn-outline-primary';
          }
        }
      } else {
        if (diff <= 1) {
          return 'btn-danger disabled';
        } else {
          if (this.isTwoColor) {
            return 'btn-success disabled';
          } else {
            return 'btn-primary disabled';
          }
        }
      }
    }
  }
}
