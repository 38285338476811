





































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {SoloDataParam} from '@/model/interface/SoloDataParam';
import BootstrapVue    from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class BowlardPlay extends Vue {
  @Prop() private isFirst!: boolean;
  @Prop() private pinNum!: number;
  @Emit('setScore') private setScore(point: number): void { return; }

  private disabled(num: number): boolean {
    if (this.pinNum > num) {
      return false;
    } else {
      return true;
    }
  }
}
