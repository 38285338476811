































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue';
import BowlardListTable from '@/components/score/solo/BowlardListTable.vue';
import libAxios         from '@/mixin/LibAxios';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    BowlardListTable,
  },
})
export default class ScoreSoloList extends Vue {
  private usrType!: number;
  private gameName!: string;
  private gameNum: number = 0;
  private nextUrl!: {name: string};
  private searchParam: {[key: string]: any} = {};
  private gameList: any[] = [];
  private currentPage: number = 0;
  private rows: number = 0;
  private perPage: number = 10;
  private connection: boolean = true;

  private breadItems: Array<{[key: string]: any}> = [
    {text: 'Top', to: { name: 'top' }},
    {text: 'Score', to: { name: 'score' }},
    {text: 'Bolard Score List', active: true},
  ];

  @Watch('currentPage')
  private onTextChanged(newNum: number, oldNum: number) {
    this.searchParam.game_type = this.gameNum;
    this.searchParam.page_num = newNum;
    this.searchParam.page_limit = 10;
    this.searchParam.order_column = 'gs_id';
    this.searchParam.order_option = 'DESC';
    this.$store.dispatch('searchScoreSoloAction', this.searchParam);
    this.getSoloScoreList();
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          const pageNum = 1;
          this._getResumeData(0); // Gameではないので戻り値は無視
          this.usrType = this.$store.getters.getUsrType();
          this.gameName = this.$route.params.type;

          if (this.gameName === this.C_NAME_BOWLARD) {
            this.gameNum = this.C_NUM_BOWLARD;
            this.nextUrl = {name: 'score-bowlard'};
          } else {
            throw new Error('Unknown Game Type');
          }
          this.currentPage = pageNum;
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getSoloScoreList() {
    this.connection = true;

    // 保存先から取得する
    const saveParam = this.$store.getters.getSearchScoreSolo();
    const search = JSON.parse(saveParam);

    const token = this.$store.getters.getToken();
    libAxios.get('game-data/solo', token, search)
    .then((param) => {
      const tempList: any[] = [];
      const nowDT = this._formatDate(new Date(), 'Y-m-d');
      const usrID = this.$store.getters.getUsrId();
      for (const item of param.data) {
        const tmpItem: {[key: string]: any} = {};
        let tmpViewDT = item.start_dt;
        if (tmpViewDT.substr(0, 10) === nowDT) {
          tmpItem.isToday = true;
          tmpViewDT = tmpViewDT.substr(11, 5);
        } else {
          tmpItem.isToday = false;
          tmpViewDT = tmpViewDT.substr(5, 5);
        }
        tmpItem.viewDT = tmpViewDT;
        tmpItem.score = item.score;
        tmpItem.place = item.d_name;
        if (item.d_id !== 0 && item.d_tbl !== 0) {
          tmpItem.place += ' ' + item.d_tbl + '番台';
        }
        tmpItem.title = item.e_name;
        tmpItem.param = item.param;
        tmpItem.id = item.gs_id;
        if (item.e_id === 0) {
          tmpItem.canDel = true;
        } else {
          tmpItem.canDel = false;
        }
        tempList.push(tmpItem);
      }
      this.gameList = tempList;
      this.rows = param.cnt;
      this.connection = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private async delSoloScore(id: number) {
    if (!window.confirm('削除してよいですか？\n削除したデータの復旧はできません。')) {
      return;
    }
    const token = this.$store.getters.getToken();
    return await libAxios.delete('game-data/solo/' + id, token, {})
    .then(() => {
      this.getSoloScoreList();
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
