






















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class GameJudgePanel extends Vue {
  @Emit('actionType') private judgePoint(judgeChar: string, ball: number): void { return; }

  private judgeType(judgeChar: string): void {
    this.judgePoint(judgeChar, 0);
  }
}
