


























































import {Component, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class StopWatch extends Vue {
  private stopWatch!: any;
  private workTime: number = 0;
  private viewMode: number = 1;
  private count1: number = 0;
  private count2: number = 0;

  private get ViewWatch(): string {
    return this.formatTimeHHSS(this.workTime);
  }

  private formatTimeHHSS(sec: number): string {
    const mm = Math.floor(sec / 60);
    const ss = sec % 60;

    let retStr = ('0' + mm).slice(-2);
    retStr += ':' + ('0' + ss).slice(-2);
    return retStr;
  }

  private watchStart(): void {
    this.viewMode = 2;
    clearInterval(this.stopWatch);
    this.stopWatch = setInterval(() => {
      this.workTime++;
    }, 1000);
  }

  private watchPause(): void {
    clearInterval(this.stopWatch);
    this.viewMode = 3;
  }

  private watchRestart(): void {
    this.viewMode = 2;
    clearInterval(this.stopWatch);
    this.stopWatch = setInterval(() => {
      this.workTime++;
    }, 1000);
  }

  private watchClear(): void {
    this.workTime = 0;
    this.viewMode = 1;
  }

  private countUp(num: number): void {
    if (num === 1) {
      this.count1++;
    } else if (num === 2) {
      this.count2++;
    }
  }

  private countDown(num: number): void {
    if (num === 1) {
      this.count1--;
    } else if (num === 2) {
      this.count2--;
    }
  }
}
