























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {EventParameter} from '@/model/interface/EventParameter';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class DartsHeader extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isSetting!: boolean;
  @Prop() private viewMode!: number;

  @Emit('reverse') private reverse(): void { return; }
  @Emit('undo') private undo(): void { return; }
  @Emit('setting') private setting(): void { return; }

  private get useUndo(): boolean {
    if (this.isSetting || this.viewMode === this.C_VIEW_START) {
      return true;
    } else {
      return false;
    }
  }

  private get playTimeSpan(): string {
    const status = this.$store.getters['darts/getGameStatus']();
    const playTimeSpan = this.$store.getters['darts/getTimeSpan']();
    if (status < this.C_STATUS_PLAY) {
      return '準備中';
    } else {
      if (this.viewMode === this.C_VIEW_START) {
        return 'START';
      } else {
        const tmpTimeSpan = Math.floor(playTimeSpan);
        return this._formatTimeSpan(tmpTimeSpan);
      }
    }
  }

  private destroy(): void {
    /*
    const eventId = this.$store.getters['darts/getEventId']();
    if (eventId !== 0) {
      const tmpID = window.prompt('試合中のデータの破棄するためにIDを入力してください。');
      if (eventId.toString() === tmpID) {
        this.$store.dispatch('darts/destroyAction');
        this.$router.push({name: 'darts'});
      }
    } else {
      */
      if (window.confirm('ここまでのデータを破棄しますか？')) {
        this.$store.dispatch('darts/destroyAction');
        this.$router.push({name: 'darts'});
      }
    // }
  }
}
