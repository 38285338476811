import Vue from 'vue';
import Vuex from 'vuex';
import match   from '@/store/match';
import solo    from '@/store/solo';
import multi   from '@/store/multi';
import darts   from '@/store/darts';
import event   from '@/store/event';
import {RootParameter} from '@/model/interface/RootParameter';
import {Department}    from '@/model/class/department';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    usrType: 0,
    usrStatus: 0,
    usrRole: 0,
    orgId: 0,
    usrId: 0,
    usrName: 'Guest',
    usrNameShort: 'Guest',
    // pref
    departments: [],
    sex: 0,
    birthday: '',
    age: 0,
    roles: [],
    rolesFlg: {canEditInfo: false, canEditMsg: false, canEditEvent: false, canEditAdmin: false},
    messages: [],
    tableNum: 0,
    searchScore: '',
    searchScoreSolo: '',
    searchScoreMulti: '',
    searchEvent: '',
    searchRegular: '',
    contactTime: 0,
  } as RootParameter,
  getters: {
    getToken: (state: RootParameter, getters: any) => (): string => {
      return state.token;
    },
    getUsrType: (state: RootParameter, getters: any) => (): number => {
      return state.usrType;
    },
    getUsrStatus: (state: RootParameter, getters: any) => (): number => {
      return state.usrStatus;
    },
    getUsrRole: (state: RootParameter, getters: any) => (): number => {
      return state.usrRole;
    },
    getOrgId: (state: RootParameter, getters: any) => (): number => {
      return state.orgId;
    },
    getUsrId: (state: RootParameter, getters: any) => (): number => {
      return state.usrId;
    },
    getUsrName: (state: RootParameter, getters: any) => (): string => {
      return state.usrName;
    },
    getUsrNameShort: (state: RootParameter, getters: any) => (): string => {
      return state.usrNameShort;
    },
    getDepartments: (state: RootParameter, getters: any) => (): Department[] => {
      return state.departments;
    },
    getSex: (state: RootParameter, getters: any) => (): number => {
      return state.sex;
    },
    getBirthday: (state: RootParameter, getters: any) => (): string => {
      return state.birthday;
    },
    getAge: (state: RootParameter, getters: any) => (): number => {
      return state.age;
    },
    getRoles: (state: RootParameter, getters: any) => (): any[] => {
      return state.roles;
    },
    getRolesFlg: (state: RootParameter, getters: any) => (): {[key: string]: boolean} => {
      return state.rolesFlg;
    },
    getMessage: (state: RootParameter, getters: any) => (): any[] => {
      return state.messages;
    },
    getTableNum: (state: RootParameter, getters: any) => (): number => {
      return state.tableNum;
    },
    getSearchScore: (state: RootParameter, getters: any) => (): string => {
      return state.searchScore;
    },
    getSearchScoreSolo: (state: RootParameter, getters: any) => (): string => {
      return state.searchScoreSolo;
    },
    getSearchScoreMulti: (state: RootParameter, getters: any) => (): string => {
      return state.searchScoreMulti;
    },
    getSearchEvent: (state: RootParameter, getters: any) => (): string => {
      return state.searchEvent;
    },
    getSearchRegular: (state: RootParameter, getters: any) => (): string => {
      return state.searchRegular;
    },
    getContactTime: (state: RootParameter, getters: any) => (): number => {
      return state.contactTime;
    },
  },
  mutations: {
    setToken(state: RootParameter, payload: string) {
      state.token = payload;
    },
    setUsrType(state: RootParameter, payload: number) {
      state.usrType = payload;
    },
    setUsrStatus(state: RootParameter, payload: number) {
      state.usrStatus = payload;
    },
    setUsrRole(state: RootParameter, payload: number) {
      state.usrRole = payload;
    },
    setOrgId(state: RootParameter, payload: number) {
      state.orgId = payload;
    },
    setUsrId(state: RootParameter, payload: number) {
      state.usrId = payload;
    },
    setUsrName(state: RootParameter, payload: string) {
      state.usrName = payload;
    },
    setUsrNameShort(state: RootParameter, payload: string) {
      state.usrNameShort = payload;
    },
    setDepartments(state: RootParameter, payload: Department[]) {
      state.departments = payload;
    },
    setTableNum(state: RootParameter, payload: number) {
      state.tableNum = payload;
    },
    setSearchScore(state: RootParameter, payload: string) {
      state.searchScore = payload;
    },
    setSearchScoreSolo(state: RootParameter, payload: string) {
      state.searchScoreSolo = payload;
    },
    setSearchScoreMulti(state: RootParameter, payload: string) {
      state.searchScoreMulti = payload;
    },
    setSearchEvent(state: RootParameter, payload: string) {
      state.searchEvent = payload;
    },
    setSearchRegular(state: RootParameter, payload: string) {
      state.searchRegular = payload;
    },
    setContactTime(state: RootParameter, payload: number) {
      state.contactTime = payload;
    },
    setGuestParam(state: RootParameter) {
      state.token        = '';
      state.usrType      = 0;
      state.usrStatus    = 0;
      state.usrRole      = 0;
      state.orgId        = 0;
      state.usrId        = 0;
      state.usrName      = 'Guest';
      state.usrNameShort = 'Guest';
      state.departments  = [];
      state.sex          = 0;
      state.birthday     = '';
      state.age          = 0;
      state.roles        = [];
      state.rolesFlg     = {canEditInfo: false, canEditMsg: false, canEditEvent: false, canEditAdmin: false};
      state.messages     = [];
      state.tableNum     = 0;
    },
    setMemberParam(state: RootParameter, payload: RootParameter) {
      state.token        = payload.token;
      state.usrType      = payload.usrType;
      state.usrStatus    = payload.usrStatus;
      state.usrRole      = payload.usrRole;
      state.orgId        = payload.orgId;
      state.usrId        = payload.usrId;
      state.usrName      = payload.usrName;
      state.usrNameShort = payload.usrNameShort;
      state.departments  = payload.departments;
      state.sex          = payload.sex;
      state.birthday     = payload.birthday;
      state.age          = payload.age;
      state.roles        = payload.roles;
      state.rolesFlg     = payload.rolesFlg;
      state.messages     = payload.messages;
      state.tableNum     = payload.tableNum;
    },
  },
  actions: {
    loginAction(context: any, postParam: RootParameter) { // {[key: string]: number}
      context.commit('setMemberParam', postParam);
      window.localStorage.setItem('profile', JSON.stringify(postParam));
    },
    logoutAction(context: any) {
      context.commit('setGuestParam');
      window.localStorage.removeItem('profile');
    },
    searchScoreAction(context: any, searchScore: {[key: string]: any}) {
      context.commit('setSearchScore', JSON.stringify(searchScore));
    },
    searchScoreSoloAction(context: any, searchScoreSolo: {[key: string]: any}) {
      context.commit('setSearchScoreSolo', JSON.stringify(searchScoreSolo));
    },
    searchScoreMultiAction(context: any, searchScoreMulti: {[key: string]: any}) {
      context.commit('setSearchScoreMulti', JSON.stringify(searchScoreMulti));
    },
    searchEventAction(context: any, searchEvent: {[key: string]: any}) {
      context.commit('setSearchEvent', JSON.stringify(searchEvent));
    },
    searchRegularAction(context: any, searchRegular: {[key: string]: any}) {
      context.commit('setSearchRegular', JSON.stringify(searchRegular));
    },
    contactTimeAction(context: any, ContactTime: number) {
      context.commit('setContactTime', ContactTime);
    },
  },
  modules: {
    match,
    solo,
    multi,
    event,
    darts,
  },
});
