




























import {Component, Vue} from 'vue-property-decorator';
import Header        from '@/components/common/Header.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class Darts extends Vue {
  private usrType: number = 1;
  private breadItems: Array<{[key: string]: any}> = [
    {text: 'Top', to: { name: 'top' }},
    {text: 'Darts', active: true},
  ];

private created(): void {
    try { // ログイン専用
      this._checkLogin().
      then((isLogin: boolean) => {
        // TODO
        // this._getResumeData(0); // Gameではないので戻り値は無視
        this.usrType = this.$store.getters.getUsrType();
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private dStartMatch(gameNum: number): void {
    if (this.usrType === this.C_MODE_GUEST)  {
      const param: {[key: string]: any} = {
        gameType: gameNum,
        player1: {id: 0, name: 'GUEST1', race_to: 501},
        player2: {id: 0, name: 'GUEST2', race_to: 501},
      };

      param.matchMode = this.C_MODE_GUEST;
      param.matchStatus = this.C_STATUS_CONFIG;
      this.$store.dispatch('darts/initGuestAction', param);

      if (gameNum === this.CD_NUM_01) {
        this.$router.push({name: 'd-score-zero_one'});
      }
    } else {
      if (gameNum === this.CD_NUM_01) {
        this.$router.push({name: 'darts-place-player', params: {type: this.CD_NAME_O1}});
      }
    }
  }
}
