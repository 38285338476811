
































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class DeptParam extends Vue {
  @Prop() private deptParam!: {[key: string]: any};
  @Prop() private errParam!: {[key: string]: any};
  private dScorp: number = 1;
  private dName: string = '';
  private dNameS: string = '';
  private dPcode1: string = '';
  private dPcode2: string = '';
  private dPref: number = 0;
  private dAddr: string = '';
  private dTel1: string = '';
  private dTel2: string = '';
  private dTel3: string = '';
  private dFax1: string = '';
  private dFax2: string = '';
  private dFax3: string = '';
  private dHp: string = '';
  private dFb: string = '';
  private dCntTbl: number = 1;
  private isVenue: number = 0;
  private btnName: string = '';

  @Emit('saveData') private _saveData(sendParam: {[key: string]: any}): void { return; }

  private get dNameState(): boolean {
    return (this.dName.length > 0);
  }

  private get dNameSState(): boolean {
    return (this.dNameS.length > 0);
  }

  private get dPrefState(): boolean {
    return (this.dPref !== 0);
  }

  private get canSend(): boolean {
    return this.dNameState && this.dNameSState && this.dPrefState;
  }

  private created(): void {
    if (this.deptParam) {
      this.dScorp = this.deptParam.d_scorp;
      this.dName  = this.deptParam.d_name;
      this.dNameS = this.deptParam.d_name_s;
      const dPcodeBuf = (this.deptParam.d_pcode + '-').split('-');
      this.dPcode1  = dPcodeBuf[0];
      this.dPcode2  = dPcodeBuf[1];
      this.dPref  = this.deptParam.d_pref;
      this.dAddr  = this.deptParam.d_addr;
      const dTelBuf = (this.deptParam.d_tel + '--').split('-');
      this.dTel1    = dTelBuf[0];
      this.dTel2    = dTelBuf[1];
      this.dTel3    = dTelBuf[2];
      const dFaxBuf = (this.deptParam.d_fax + '--').split('-');
      this.dFax1    = dFaxBuf[0];
      this.dFax2    = dFaxBuf[1];
      this.dFax3    = dFaxBuf[2];
      this.dHp      = this.deptParam.d_hp;
      this.dFb      = this.deptParam.d_fb;
      this.dCntTbl = this.deptParam.d_cnt_tbl;
      this.isVenue = this.deptParam.is_venue;
      this.btnName = '更新';
    } else {
      this.btnName = '追加';
    }
  }

  private saveData(): void {
    const tmpPcode = (this.dPcode1 !== '' && this.dPcode2 !== '') ? this.dPcode1 + '-' + this.dPcode2 : '';
    const tmpTel = (this.dTel1 !== '' && this.dTel2 !== '' && this.dTel3 !== '') ?
      this.dTel1 + '-' + this.dTel2 + '-' + this.dTel3 : '';
    const tmpFax = (this.dFax1 !== '' && this.dFax2 !== '' && this.dFax3 !== '') ?
      this.dFax1 + '-' + this.dFax2 + '-' + this.dFax3 : '';

    const postParam: {[key: string]: any} = {
      d_scorp:   this.dScorp,
      d_name:    this.dName,
      d_name_s:  this.dNameS,
      d_pcode:   tmpPcode,
      d_pref:    this.dPref,
      d_addr:    this.dAddr,
      d_tel:     tmpTel,
      d_fax:     tmpFax,
      d_hp:      this.dHp,
      d_fb:      this.dFb,
      d_cnt_tbl: this.dCntTbl,
      is_venue:  this.isVenue,
    };
    this._saveData(postParam);
  }
}

