
































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {PlayerParameter} from '@/model/interface/PlayerParameter';
import DeptPlayerSelect from '@/components/score/common/DeptPlayerSelect.vue';
import PlayerSearch from '@/components/score/common/PlayerSearch.vue';
import BootstrapVue      from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    DeptPlayerSelect,
    PlayerSearch,
  },
})
export default class DeptPlayerPanel extends Vue {
  @Prop() private useGhost!: boolean;
  @Prop() private useGuest!: boolean;
  @Prop() private usePublic!: boolean;
  private usrType!: number;
  private tabIndex: number = 0;
  private playerSelected: PlayerParameter = {id: 0, name: '', nameShort: ''};
  private playerSearch: PlayerParameter   = {id: 0, name: '', nameShort: ''};

  @Emit('setPlayer') private setPlayer(playerTab: number, player: PlayerParameter): void { return; }

  private get _TabIndex(): number {
    return this.tabIndex;
  }

  private set _TabIndex(value: number) {
    if (value === 0) {
      this.setPlayer(value, this.playerSelected);
    } else if (value === 1) {
      this.setPlayer(value, this.playerSearch);
    } else if (value === 2 || value === 3) {
      this.setPlayer(value, {id: 0, name: 'Guest', nameShort: 'Guest'});
    }
    this.tabIndex = value;
  }

  private created(): void {
    this.usrType = this.$store.getters.getUsrType();
  }

  private setPlayerSelected(player: PlayerParameter): void {
    this.playerSelected = player;
    this.setPlayer(this.tabIndex, player);
  }

  private setPlayerSearched(player: PlayerParameter): void {
    this.playerSearch = player;
    this.setPlayer(this.tabIndex, player);
  }
}
