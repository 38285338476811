




















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {EventParameter} from '@/model/interface/EventParameter';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class MultiHeader extends Vue {
  @Prop() private isSetting!: boolean;
  @Prop() private currentNum!: number;
  @Prop() private playTime!: number;
  private event!: EventParameter;

  @Emit('undo') private undo(): void { return; }
  @Emit('toRestart') private toRestart(): void { return; }
  @Emit('destroy') private destroy(): void { return; }

  private get useUndo(): boolean {
    if (this.isSetting || this.currentNum < 1) {
      return true;
    } else {
      return false;
    }
  }

  private get useToRestart(): boolean {
    if (this.event.id === 0 && !this.isSetting) {
      return false;
    } else {
      return true;
    }
  }

  // TODO イベントならなし
  private get useDestroy(): boolean {
    if (this.event.id === 0) {
      return false;
    } else {
      return true;
    }
  }

  private get playTimeSpan(): string {
    const playTimeSpan = this.$store.getters['multi/getTimeSpan']();
    if (this.isSetting) {
      return '準備中';
    } else {
      const tmpTimeSpan = Math.floor(playTimeSpan);
      return this._formatTimeSpan(tmpTimeSpan);
    }
  }

  private created(): void {
    this.event = this.$store.getters['multi/getEvent']();
  }
}
