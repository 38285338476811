
























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {EventStep}    from '@/model/class/EventStep';
import EventHeader    from '@/components/event-admin/common/EventHeader.vue';
import libAxios       from '@/mixin/LibAxios';
import BootstrapVue   from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    EventHeader,
  },
})
export default class EAConfig extends Vue {
  private connection: boolean = false;
  private usrId: number = 0;
  private configList: any[] = [];

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Event管理 Top', to: { name: 'event-admin-top' }},
    { text: '試合設定一覧', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.usrId = this.$store.getters.getUsrId();
          this.getConfigList();
        } else {
          this.$router.push({name: 'error'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getConfigList() {
    this.connection = true;
    const token = this.$store.getters.getToken();
    libAxios.get('event-config', token, {})
    .then((response) => {
      this.configList = response.data;
      this.connection = false;
      return;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
