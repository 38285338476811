































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import GameRotationBall from '@/components/score/match/GameRotationBall.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    GameRotationBall,
  },
})
export default class GameRotationBreak extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  private score1: number = this.$store.getters['match/getPlayer1Score']();
  private score2: number = this.$store.getters['match/getPlayer2Score']();
  private raceTo1: number = this.$store.getters['match/getPlayer1RaceTo']();
  private raceTo2: number = this.$store.getters['match/getPlayer2RaceTo']();

  private initBalls: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  private workBalls: number[] = [];

  @Emit('setBalls') private setBalls(num: number[]): void { return; }
  @Emit('actionType') private breakPoint(breakType: string, balls: number[]): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get isWin(): boolean {
    const sum = this.workBalls.reduce((prev, current) => prev += current, 0);

    if (sum > 0) {
      if (this.isFirst) {
        if ( (this.score1 + sum - this.raceTo1) >= 0)  {
          return true;
        }
      } else {
        if ( (this.score2 + sum - this.raceTo2) >= 0)  {
          return true;
        }
      }
    }
    return false;
  }

  private get isNoIn(): boolean {
    return (this.workBalls.length === 0);
  }

  private breakType(breakChar: string): void {
    const sum = this.workBalls.reduce((prev, current) => prev += current, 0);
    const plus = (this.isFirst) ? 1 : 2;
    for (const tmpBall of this.workBalls) {
      this.initBalls[tmpBall - 1] = plus;
    }
    if (sum > 0) {
      this.$store.dispatch('match/addScoreAction', {isFirst: this.isFirst, addPoint: sum});
    }
    this.setBalls(this.initBalls);
    this.breakPoint(breakChar, this.workBalls);
  }
}
