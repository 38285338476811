


















































































import {Component, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue'; // @ is an alias to /src
import libAxios        from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  // mixins: [LibCommon],実装方法を残すためコメントアウト
  components: {
    Header,
  },
})
export default class Belong extends Vue {
  private belongList: Array<{id: number, name: string}> = [];
  private deptsList: Array<{id: number, name: string}> = [];
  private belongMain: {id: number, name: string} = {id: 0, name: ''};
  private isChange: boolean = false;
  private addDept: number = 0;
  private connection: boolean = true;

  private selectDepts: Array<{value: number, text: string}> = [];

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: '個人メニュー', to: { name: 'personal-menu' }},
    { text: '所属編集', active: true},
  ];

  private get useLeave0(): boolean {
    return !this.isChange && this.belongList.length !== 0;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {

          this.getDepts();
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getDepts() {
    const token = this.$store.getters.getToken();
    return await libAxios.get('belong', token, {}) // とりあえず全部
    .then((param: {[key: string]: any}) => {
      for (const belong of param.belong.data) {
        if (belong.L_du_lv === 0) {
          this.belongMain = {id: belong.d_id, name: belong.d_name} ;
        } else {
          this.belongList.push({id: belong.d_id, name: belong.d_name});
        }
      }

      for (const dept of param.depts.data) {
        this.deptsList.push({id: dept.d_id, name: dept.d_name});

        // セレクトの追加
        if (dept.d_id !== this.belongMain.id && !this.belongList.some((item) => item.id === dept.d_id)) {
          this.selectDepts.push({value: dept.d_id, text: dept.d_name});
        }
      }
      this.connection = false;
    }).catch((e: Error) => {
      window.console.log(e);
      this.connection = false;
    });
  }

  private add(): void {
    let len = 0;
    for (const selected of this.selectDepts) {
      if (selected.value === this.addDept) {
        // belongに追加
        this.belongList.push({id: selected.value, name: selected.text});
        // selectからの削除
        this.selectDepts.splice(len, 1);
        this.addDept = 0;
        this.isChange = true;
        break;
      }
      len++;
    }
  }

  private leave(id: number): void {
    let len = 0;
    for (const belong of this.belongList) {
      if (belong.id === id) {
        this.selectDepts.push({value: belong.id, text: belong.name});
        this.belongList.splice(len, 1);
        this.isChange = true;
        break;
      }
      len++;
    }
  }

  private async save() {
    const deptIds = this.belongList.map((item) => item.id);
    const postParam = {
      ids: deptIds.join(','),
    };
    this.connection = true;
    const token = this.$store.getters.getToken();
    return await libAxios.put('belong', token, postParam)
    .then((param: {[key: string]: any}) => {
      this.connection = false;
      this.isChange = false;
    }).catch((e: Error) => {
      this.$router.push({name: 'error'});
    });
  }

  private async leave0() {
    this.connection = true;
    const token = this.$store.getters.getToken();
    return await libAxios.delete('belong', token, {})
    .then((param: {[key: string]: any}) => {
      this.$store.dispatch('logoutAction');
      this.$router.push({name: 'top'});
    }).catch((e: Error) => {
      this.$router.push({name: 'error'});
    });
  }
}
