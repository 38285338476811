























































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game14OneLabel extends Vue {
  @Prop() private currentBall!: number;
  @Prop() private isFirst!: boolean;

  private buttonColor(num: number): string {
    const scoreP1 = this.$store.getters['match/getPlayer1Score']();
    const scoreP2 = this.$store.getters['match/getPlayer2Score']();
    const raceToP1 = this.$store.getters['match/getPlayer1RaceTo']();
    const raceToP2 = this.$store.getters['match/getPlayer2RaceTo']();
    const diff1 = raceToP1 - scoreP1;
    const diff2 = raceToP2 - scoreP2;
    const isFirst = this.isFirst;

    if (num === this.currentBall) {
      return 'dark';
    } else if (num < this.currentBall) {
      return 'secondary';
    } else {
      const diff = ((isFirst) ? diff1 : diff2) + this.currentBall - num;
      if (diff < 0) {
        return 'secondary';
      } else if (diff === 0) {
        return 'danger';
      } else if (diff <= 5) {
        return 'warning';
      } else {
        return 'outline-info';
      }
    }
  }

  // 初期化
  private created(): void {
    // TODO
  }
}
