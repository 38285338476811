






















































import {Component, Emit, Prop, Watch, Vue} from 'vue-property-decorator';
import {EventStep}     from '@/model/class/EventStep';
import {EventGame}     from '@/model/class/EventGame';
import {EventRefereeParam} from '@/model/interface/EventRefereeParam';
import {EventPlayer}   from '@/model/class/EventPlayer';
import {PlaceParam}    from '@/model/interface/PlaceParam';
import PlaceTablePanel from '@/components/score/common/PlaceTablePanel.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    PlaceTablePanel,
  },
})
export default class EventGameConfig extends Vue {
  @Prop() private currentStep!: EventStep;
  @Prop() private currentGame!: EventGame;
  private player1Name: string = '';
  private player2Name: string = '';
  private player1RaceTo: number = 0;
  private player2RaceTo: number = 0;

  private stepDetail!: {key: string, value: number};
  private configMode: number = 0;
  private configType: number = 0;

  private referees: EventRefereeParam[] = [];
  private refereeOptions: Array<{value: number, text: string }> = [];
  private refereeSelected: number = 0;

  private deptID: number = 0;
  private deptOptions: Array<{value: number, text: string, numOfTable: number}> = [];
  private tableIndex: number = 0;
  private tableOptions: Array<{value: number, text: number}> = [];
  private canSelectTable: boolean = true;
  private offEvent: boolean = true;

  @Emit('updateGame') private _UpdateGame(stepId: number, gameID: number, param: {[key: string]: any}): void { return; }

  @Watch('refereeSelected')
  private onChangeReferee(newValue: number, oldValue: number): void {
    if (this.offEvent) {
      return;
    }
    const selectReferee = this.referees.filter((x) => x.usrId === newValue);
    if (selectReferee.length === 1) {
      this.tableIndex = selectReferee[0].tableNum;
    }
  }

  @Watch('deptID')
  private onDeptChange(newValue: number, oldValue: number): void {
    if (this.offEvent) {
      return;
    }
    this.tableOptions = [];
    let numOfTable = 0;
    for (const tmpDept of this.deptOptions) {
      if (tmpDept.value === newValue) {
        numOfTable = tmpDept.numOfTable;
        break;
      }
    }

    if (numOfTable === 0) {
      this.canSelectTable = false;
      return;
    }
    for (let i = 1; i <= numOfTable; i++) {
      this.tableOptions.push({value: i, text: i});
    }

    this.canSelectTable = true;
  }

  private created(): void {
    if (this.currentGame === null) {
      return;
    }
    // place
    this.deptOptions = [];
    let maxTbl = 0;
    const places = this.$store.getters['event/getPlaces']();
    for (const tmpDept of places) {
      this.deptOptions.push({value: tmpDept.id, text: tmpDept.name, numOfTable: tmpDept.tableNum});
      if (this.currentGame.deptId === tmpDept.id) {
        maxTbl = tmpDept.tableNum;
      }
    }
    this.deptOptions.push({value: 0, text: '未選択', numOfTable: 0});
    if (this.currentGame.deptId === 0 && this.currentGame.eventGameStatus === this.C_GAME_STATUS_PAIR) {
      this.deptID = this.deptOptions[0].value;
      this.tableIndex = 1;
      maxTbl = this.deptOptions[0].numOfTable;
    } else {
      this.deptID = this.currentGame.deptId;
      this.tableIndex = this.currentGame.deptTable;
    }
    this.tableOptions = [];
    // ステータス
    if (this.deptID === 0) {
      this.canSelectTable = false;
    } else {
      for (let i = 1; i <= maxTbl; i++) {
        this.tableOptions.push({value: i, text: i});
      }
      this.canSelectTable = true;
    }

    const player1 = this.$store.getters['event/getPlayer'](this.currentGame.eventPlayerId1);
    this.player1Name = player1.usrName;
    const player2 = this.$store.getters['event/getPlayer'](this.currentGame.eventPlayerId2);
    this.player2Name = player2.usrName;

    const config = this.$store.getters['event/getConfig'](this.currentStep.format);
    this.configMode = config.eventConfigMode;
    this.configType = config.eventConfigType;
    const jsonConfig = JSON.parse(config.format);
    const raceTo: {[key: string]: any} = jsonConfig.raceTo;
    this.stepDetail = jsonConfig.config;

    // score
    if (this.currentGame.raceTo1 !== 0) {
      this.player1RaceTo = this.currentGame.raceTo1;
    } else {
      let tmpClass = player1.usrClass;
      if (player1.usrSex === 2) {
        tmpClass = 'L' + tmpClass;
      }
      if ((tmpClass in raceTo)) {
        this.player1RaceTo = raceTo[tmpClass];
      }
    }

    if (this.currentGame.raceTo2 !== 0) {
      this.player2RaceTo = this.currentGame.raceTo2;
    } else {
      let tmpClass = player2.usrClass;
      if (player2.usrSex === 2) {
        tmpClass = 'L' + tmpClass;
      }
      if ((tmpClass in raceTo)) {
        this.player2RaceTo = raceTo[tmpClass];
      }
    }

    // referee
    this.referees = this.$store.getters['event/getReferees']();
    this.refereeOptions = this.referees.map((referee: EventRefereeParam) => {
      return {value: referee.usrId, text: referee.usrName};
    });
    this.refereeOptions.unshift({value: 0, text: 'スタンバイ'});
    this.refereeOptions.unshift({value: -1, text: '結果のみ'});
    this.refereeSelected = this.currentGame.refereeId;
    this.offEvent = false;
  }

  private saveGame(): void {
    // 結果のみの場合はステータスはplay
    const postParam = {
      eg_type: this.configType,
      eg_status: this.C_GAME_STATUS_READY,
      d_id: 0,
      d_name: '未選択',
      d_tbl: 0,
      is_edit: this._bitCalc(this.configMode, 3),
      config: JSON.stringify(this.stepDetail),
      referee_id: this.refereeSelected,
      race_to_1: this.player1RaceTo,
      race_to_2: this.player2RaceTo,
      edit_dt: this.currentGame.editDateTime,
    };
    if (this.refereeSelected === -1) {
      postParam.eg_status = this.C_GAME_STATUS_PLAY;
    }
    if (this.deptID !== 0) {
      postParam.d_id = this.deptID;
      const selectDept = this.deptOptions.filter((x) => x.value === this.deptID);
      postParam.d_name = selectDept[0].text;
      postParam.d_tbl = this.tableIndex;
    }
    this._UpdateGame(this.currentGame.eventStepId, this.currentGame.eventGameId, postParam);
  }
}
