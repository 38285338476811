



































import {Component, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue';
import ProfileData      from '@/components/common/ProfileData.vue';
import {Profile}        from '@/model/class/Profile';
import libAxios         from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  // mixins: [LibCommon],実装方法を残すためコメントアウト
  components: {
    Header,
    ProfileData,
  },
})
export default class ProfileEdit extends Vue {
  private profile: Profile = new Profile();
  private errorArray: string[] = [];
  private okProfile: boolean = false;
  private editDateTime: string = '';
  private connection: boolean = true;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: '個人メニュー', to: { name: 'personal-menu' }},
    { text: 'Profile編集', active: true},
  ];

  private get canSend(): boolean {
    return this.okProfile;
  }

  private checkSend(okProfile: boolean): void {
    this.okProfile = okProfile;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.getProfile();
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getProfile() {
    const token = this.$store.getters.getToken();
    return await libAxios.get('profile', token, {})
    .then((param: {[key: string]: any}) => {
      const birthdayY = param.u_bday.substr(0, 4);
      const birthdayD = (param.u_bday.substr(4, 2)).replace(/^0/, '');
      this.profile.name1        = param.u_name_1;
      this.profile.name2        = param.u_name_2;
      this.profile.kana1        = param.u_kana_1;
      this.profile.kana2        = param.u_kana_2;
      this.profile.pubName      = param.u_pub_n;
      this.profile.pubNameShort = param.u_pub_s;
      this.profile.pubClass     = param.u_pub_c;
      this.profile.priName      = param.u_pri_n;
      this.profile.priNameShort = param.u_pri_s;
      this.profile.priClass     = param.u_pri_c;
      this.profile.birthDayY    = parseInt(birthdayY, 10);
      this.profile.birthDayM    = parseInt(birthdayD, 10);
      this.profile.sex          = parseInt(param.u_sex, 10);
      this.profile.pref         = parseInt(param.u_pref, 10);
      this.editDateTime         = param.edit_dt;
      this.connection = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private get refs(): any {
    return this.$refs;
  }

  private async save() {
    this.connection = true;
    const postParam: {[key: string]: any} = {
      u_name_1: this.profile.name1,
      u_name_2: this.profile.name2,
      u_kana_1: this.profile.kana1,
      u_kana_2: this.profile.kana2,
      u_pub_n:  this.profile.pubName,
      u_pub_s:  this.profile.pubNameShort,
      u_pub_c:  this.profile.pubClass,
      u_pri_n:  this.profile.priName,
      u_pri_s:  this.profile.priNameShort,
      u_pri_c:  this.profile.priClass,
      u_bday:   this.profile.birthDayY + ('00' + this.profile.birthDayM).slice(-2),
      u_sex:    this.profile.sex,
      u_pref:   this.profile.pref,
      edit_dt:  this.editDateTime,
    };

    const token = this.$store.getters.getToken();
    return await libAxios.put('profile', token, postParam)
    .then((param: {[key: string]: any}) => {
      alert('更新しました');
      this.editDateTime = param.edit_dt;
      this.connection = false;
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        for (const err of Object.keys(tmpJson.validate)) {
          this.errorArray.push(err);
        }
        this.refs.profile_data.checkErrMsg();
      } else {
        this.$router.push({name: 'error'});
      }
      this.connection = false;
    });
  }
}
