







































































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventPlayer}     from '@/model/class/EventPlayer';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class EventPlayerEdit extends Vue {
  @Prop() private editPlayer!: {[key: string]: any};
  private isReferee: boolean = false;
  private classOptions: Array<{value: string, text: string}> = [
    { value: 'P', text: 'P' },
    { value: 'A', text: 'A' },
    { value: 'a', text: 'SA' },
    { value: 'B', text: 'B' },
    { value: 'b', text: 'SB' },
    { value: 'C', text: 'C' },
  ];

  @Emit('setEditPlayerParam') private setEditPlayer(key: string, val: any): void { return; }

  private get _UsrName(): string {
    return this.editPlayer.usrName;
  }

  private set _UsrName(value: string) {
    this.setEditPlayer('usrName', value);
  }

  private get _UsrNameShort(): string {
    return this.editPlayer.usrNameShort;
  }

  private set _UsrNameShort(value: string) {
    this.setEditPlayer('usrNameShort', value);
  }

  private get _DeptName(): string {
    return this.editPlayer.deptName;
  }

  private set _DeptName(value: string) {
    this.setEditPlayer('deptName', value);
  }

  private get _DeptNameShort(): string {
    return this.editPlayer.deptNameShort;
  }

  private set _DeptNameShort(value: string) {
    this.setEditPlayer('deptNameShort', value);
  }

  private get _UsrClass(): string {
    return this.editPlayer.usrClass;
  }

  private set _UsrClass(value: string) {
    this.setEditPlayer('usrClass', value);
  }

  private get _UsrSex(): number {
    return this.editPlayer.usrSex;
  }

  private set _UsrSex(value: number) {
    this.setEditPlayer('usrSex', value);
  }

  private get _CanReferee(): string {
    return this.editPlayer.canReferee;
  }

  private set _CanReferee(value: string) {
    this.setEditPlayer('canReferee', value);
  }

  private created(): void {
    // window.console.log(this.editPlayer);
  }
}
