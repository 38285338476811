










import {Component, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class EventDetail extends Vue {
  @Prop() private currentEvent!: {[key: string]: any};
  private imagePath: string = '';
  private imageCaption: string = '';
  private bodyHtml: string = '';

  private created(): void {
    if (this.currentEvent.e_status === this.C_EVENT_FIN) {
      this.imagePath = this.currentEvent.rst_img_path;
      this.imageCaption = this.currentEvent.rst_img_text;
      this.bodyHtml = this.currentEvent.rst_body;
    } else {
      this.imagePath = this.currentEvent.img_path;
      this.imageCaption = this.currentEvent.img_text;
      this.bodyHtml = this.currentEvent.body;
    }
    // entryでmsg有効の場合
  }
}
