import { render, staticRenderFns } from "./ScoreSimple.vue?vue&type=template&id=478e0415&scoped=true&"
import script from "./ScoreSimple.vue?vue&type=script&lang=ts&"
export * from "./ScoreSimple.vue?vue&type=script&lang=ts&"
import style0 from "./ScoreSimple.vue?vue&type=style&index=0&id=478e0415&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478e0415",
  null
  
)

export default component.exports