
















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {MatchParam}       from '@/model/interface/MatchParam';
import {MatchPlayerParam} from '@/model/interface/MatchPlayerParam';
import {PlayPointParam}   from '@/model/interface/PlayPointParam';
import DartsHeader        from '@/components/score/darts/DartsHeader.vue';
import DartsNamePlate     from '@/components/score/darts/DartsNamePlate.vue';
import DartsConfigRaceTo  from '@/components/score/darts/DartsConfigRaceTo.vue';
import Darts01Config      from '@/components/score/darts/Darts01Config.vue';
import DartsPointPlate    from '@/components/score/darts/DartsPointPlate.vue';
import GameStartPanel     from '@/components/score/match/GameStartPanel.vue';
import Darts01Play        from '@/components/score/darts/Darts01Play.vue';
import Game9BallPlay      from '@/components/score/match/Game9BallPlay.vue';
import StopWatch          from '@/components/common/StopWatch.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    DartsHeader,
    DartsNamePlate,
    DartsConfigRaceTo,
    Darts01Config,
    DartsPointPlate,
    GameStartPanel,
    Darts01Play,
    Game9BallPlay,
    StopWatch,
  },
})
export default class Darts01 extends Vue {
  private isLoading: boolean = true;
  private isSetting: boolean = true;
  private isReverse: boolean = false;
  private usrType!: number;
  private eventId!: number;
  private viewMode: number = 0;
  private isFirst: boolean = true;
  private baseTime: number = 0;


  private created(): void {
    try {
      this._checkLogin().
      then((result: boolean) => {
        this.usrType = this.$store.getters.getUsrType();
        // this.eventId = this.$store.getters['match/getEventId']();
        this._created();
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private _created(): void {
    // const suspend = this._getResumeData(this.CD_NUM_01);
    // if (suspend === '') {
    //   this.$router.push({name: 'error'}); // SYSTEM ERROR
    // }
    // const tmpGameId = this.$store.getters['darts/getGameId']();
    // if (tmpGameId !== 0) {
    //   if (tmpGameId !== suspend.gameId) {
    //     this.$router.push({name: 'error'}); // SYSTEM ERROR
    //   }
    // } else { // suspendからすべてを復活させる
    //   this.$store.dispatch('darts/resumeAction', suspend);
    // }

    // TODO 中断再開処理
    // const tmpStatus = this.$store.getters['darts/getGameStatus']();


    this.isLoading = false;
    this.$forceUpdate();
  }

  private toGame(): void {
    // scoreをセット
    const score1 = this.$store.getters['darts/getPlayer1RaceTo']();
    const score2 = this.$store.getters['darts/getPlayer2RaceTo']();
    this.$store.dispatch('darts/setScoreAction', {isFirst: true, point: score1});
    this.$store.dispatch('darts/setScoreAction', {isFirst: false, point: score2});
    // Status
    const tmpStatus: number = this.$store.getters['darts/getGameStatus']();
    if (tmpStatus < this.C_STATUS_READY) {
      this.$store.dispatch('darts/readyAction');
    } else {
      this.$store.dispatch('darts/toGameAction');
    }
    this.isSetting = false;
  }

  private gameStart(playerNum: number): void {
    /*
    this.matchData.firstPlayer = playerNum;
    this.matchData.gameNum = -1;
    this.matchData.gameParam = [];
  */
    if (playerNum === 1) {
      this.isFirst = true;
    } else {
      this.isFirst = false;
    }
    // ここで C_STATUS_PLAY
    const dateTime = new Date() ;
    this.baseTime = Math.floor( dateTime.getTime() / 1000 );

    const startParam = {
      startDateTime: this._formatDate(dateTime, ''),
      first: playerNum,
    };
    this.$store.dispatch('darts/startAction', startParam);
    this.viewMode = this.C_VIEW_PLAY;
    this.$forceUpdate();
  }

  get refs(): any {
    return this.$refs;
  }

  private undo(): void {
    this.refs.child.undo();
  }

  private actionType(cmd: string): void {
    if (cmd === 'change') {
      this.isFirst = !this.isFirst;
    } else if (cmd === 'fin') {
      this.$router.push({name: 'darts'});
    } else if (cmd === 'retry') {
      this.viewMode = this.C_VIEW_START;
      this.isSetting = true;
    }
  }

  private gameFin(isFin: boolean): void {
    this.isLoading = true;
    this.isSetting = true;
    try {
      if (this.usrType === this.C_MODE_GUEST) { // ゲストの場合はデータ破棄
        if (isFin) {
          this.$store.dispatch('match/destroyAction');
          this.$router.push({name: 'score'});
        } else {
          this.$store.dispatch('match/retryGuestAction');
          this._created();
        }
      } else {
        this.$store.dispatch('match/finAction', isFin)
        .then(() => {
          if (this.eventId !== 0) {
            this.$router.push({ name: 'event-match', params: {type: this.C_NAME_MATCH} });
          } else {
            if (isFin) {
              if (this.usrType === this.C_MODE_MEMBER) {
                this.$router.push({name: 'score-list', params: {type: this.C_NAME_9B}});
              } else {
                this.$router.push({name: 'score'});
              }
            } else {
              this.$store.dispatch('match/retryAction')
              .then(() => {
                this._created();
              }).catch((e) => {
                throw new Error(e.message);
              });
            }
          }
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      this.viewMode = this.C_VIEW_ERROR;
      this.isLoading = true;
    }
  }

  private reverse(): void {
    this.isReverse = !this.isReverse;
  }

  private setting(): void {
    this.isSetting = true;
  }

  private destroy(): void {
    if (window.confirm('ここまでのデータを破棄しますか？')) {
      this.$store.dispatch('darts/destroyAction');
      this.$router.push({name: 'darts'});
    }
  }
}
