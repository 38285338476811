








































































































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import Game9BallBall    from '@/components/score/match/Game9BallBall.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Game9BallBall,
  },
})
export default class Game9BallBreak extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  private workBalls: number[] = [];
  private emptyBalls: number[] = [];
  private config!: {[key: string]: number};
  private ballNum: boolean = true;

  @Emit('actionType') private breakPoint(breakType: string, balls: number[]): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get isNoIn(): boolean {
    return (this.workBalls.length === 0);
  }

  // 9ball & breakAce // 9in
  private get useWin(): boolean {
    if (this.config.breakAce === 1) {
      if (this.workBalls.indexOf(9) !== -1) {
        return true;
      }
    }
    return false;
  }

  // 9ball & breakAce
  private get useIllegal(): boolean {
    return (this.config.threePoint === 1);
  }

  private created(): void {
    this.config = this.$store.getters['match/getConfig']();
    this.workBalls = []; // 初期化

    const ninePos = this.$store.getters['match/getConfigParam']('ninePos');
    const scoreLv = this.$store.getters['match/getConfigParam']('scoreLv');
    if (ninePos === 3 && scoreLv === 1) {
      this.ballNum = false;
    }
  }

  private breakType(breakChar: string): void {
    this.breakPoint(breakChar, this.workBalls);
  }

  private simpleBreak(num: number): void {
    this.workBalls = [num];
    this.breakPoint('p', this.workBalls);
  }
}
