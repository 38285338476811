































































import {Component, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class MatchConfig extends Vue {
  private simpleVal = [
    {text: 'なし', value: 0},
    {text: 'あり', value: 1},
  ];

  private isUseVal = [
    {text: '使わない', value: 0},
    {text: '使う', value: 1},
  ];

  private ruckSetVal = [
    {text: 'セルフ', value: 1},
    {text: '相手', value: 2},
    {text: 'レフリー', value: 3},
  ];

  private config: {[key: string]: number} = this.$store.getters['match/getConfig']();

  private get isDisabled(): boolean {
    return (this.config.isEdit === 0);
  }

  private get _DoubleHit(): number {
    return this.config.doubleHit;
  }

  private set _DoubleHit(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'doubleHit', val: value});
  }

  private get _RuckSet(): number {
    return this.config.ruckSet;
  }

  private set _RuckSet(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'ruckSet', val: value});
  }

  private get _IsOpen(): number {
    return this.config.isOpen;
  }

  private set _IsOpen(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'isOpen', val: value});
  }

  private get _StopWatch(): number {
    return this.config.stopWatch;
  }

  private set _StopWatch(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'stopWatch', val: value});
  }
}
