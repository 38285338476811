










































































import {Component, Emit, Prop, Watch, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class RandomPoint extends Vue {
  @Prop() private minNum!: number;
  @Prop() private maxNum!: number;
  @Prop() private randomMode!: number;
  private minVal: number = 1;
  private maxVal: number = 100;
  private maxRow: number = 10;
  private point: number = 0;
  private isStart: boolean = false;
  private intervalId!: number | null;
  private notActiveBtn: boolean = true;
  private panelStatus: number = 1;
  private pointColor: string = 'outline-dark';
  private viewNum: string[] = [];
  private valNum: number[] = [];
  private variantBnt: string[] = [];
  private maxOptions: any[] = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '30', value: 30 },
    { text: '40', value: 40 },
    { text: '50', value: 50 },
    { text: '60', value: 60 },
    { text: '70', value: 70 },
    { text: '80', value: 80 },
    { text: '90', value: 90 },
    { text: '100', value: 100 },
  ];

  @Emit('setNumber') private setNumber(num: number, minNum: number, maxNum: number): void { return; }

  @Watch('maxVal')
  private onChangeMaxNum(newValue: number, oldValue: number): void {
    this.maxRow = Math.floor(newValue / 10);
    this.viewNum = [];
    for (let i = 0; i < newValue; i++) {
      this.viewNum[i] = String(i + 1);
    }
  }

  private created(): void {
    this.intervalId = null;
    this.point = 0;
    this.minVal = this.minNum;
    this.maxVal = this.maxNum;
    this.maxRow = Math.floor(this.maxNum / 10);
    this.viewNum = [];
    for (let i = 0; i < this.maxVal; i++) {
      this.viewNum[i] = String(i + 1);
      this.variantBnt[i] = 'secondary';
    }
  }

  private setMin(num: number): void {
    this.minVal = num;
  }

  private setMax(num: number): void {
    this.maxVal = num;
  }

  private choiceNum(num: number): void {
    this.point = this.valNum[num];
    this.notActiveBtn = true;
    this.panelStatus = 2;
    this.viewNum = [];
    for (let i = 0; i < this.maxVal; i++) {
      this.viewNum[i] = String(this.valNum[i]);
      if (i === num) {
        this.variantBnt[i] = 'danger';
      } else {
        this.variantBnt[i] = 'secondary';
      }
    }
  }

  private startStop(): void {
    if (!this.isStart) {
      this.isStart = true;
      this.intervalId = setInterval(() => {
        this.startRandom();
      }, 100);
    } else {
      this.isStart = false;
      this.stopRandom();
      this.panelStatus = 2;
    }
  }

  private startRandom(): void {
    const randomNum = Math.floor( Math.random() * (this.maxVal + 1 - this.minVal) ) + this.minVal;
    const rate = (randomNum - this.minVal) / (this.maxVal - this.minVal);
    if (rate > 0.8) {
      this.pointColor = 'outline-danger';
    } else if  (rate < 0.2) {
      this.pointColor = 'outline-primary';
    } else {
      this.pointColor = 'outline-dark';
    }
    this.point = randomNum;
  }

  private stopRandom(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = null;
  }

  private setNum() {
    this.setNumber(this.point, this.minVal, this.maxVal);
  }

  private randomPanel() {
    this.viewNum = [];
    for (let i = 0; i < this.maxVal; i++) {
      this.viewNum[i] = '??';
      this.variantBnt[i] = 'outline-dark';
    }

    this.valNum = [];
    for (let i = 0; i < this.maxVal; i++) {
      this.valNum[i] = i + 1;
    }
    this.valNum = this._shuffle(this.valNum);
    this.point = 0;
    this.notActiveBtn = false;
  }
}
