import {EventGameParam} from '@/model/interface/EventGameParam';

export class EventGame implements EventGameParam {
  public eventGameId!: number;
  public eventGameType!: number;
  public eventGameStatus!: number;
  public eventId!: number;
  public eventStepId!: number;
  public setNumber!: number;
  public roundNumber!: number;
  public gameNumber!: number;
  public deptId!: number;
  public deptName!: string;
  public deptTable!: number;
  public refereeId!: number;
  public isEdit!: boolean;
  public config!: string;
  public playTimeSpan: number = 0;
  public eventPlayerId1!: number;
  public usrId1!: number;
  public score1!: number;
  public raceTo1!: number;
  public eventPlayerId2!: number;
  public usrId2!: number;
  public score2!: number;
  public raceTo2!: number;
  public winner!: number;
  public useMatch!: boolean;
  public deleteFlag!: boolean;
  public editId!: number;
  public editDateTime!: string;
  public gameId!: number;
  public gameMode!: number;
  public eventGameName!: string;
  public startDateTime!: string;
  public endDateTime!: string;
  public turn1!: number;
  public average1!: number;
  public highRun1!: number;
  public turn2!: number;
  public average2!: number;
  public highRun2!: number;
  public first!: number;
  public result!: string;
  public param!: string;

  public setByApi(param: {[key: string]: any}): void {
    this.eventGameId     = param.eg_id;
    this.eventGameType   = param.eg_type;
    this.eventGameStatus = param.eg_status;
    this.eventId         = param.e_id;
    this.eventStepId     = param.es_id;
    this.setNumber       = param.set_num;
    this.roundNumber     = param.round_num;
    this.gameNumber      = param.game_num;
    this.deptId          = param.d_id;
    this.deptName        = param.d_name;
    this.deptTable       = param.d_tbl;
    this.refereeId       = param.referee_id;
    this.isEdit          = param.is_edit;
    this.config          = param.config;
    this.playTimeSpan    = param.play_ts;
    this.eventPlayerId1  = param.ep_id_1;
    this.usrId1          = param.u_id_1;
    this.score1          = param.score_1;
    this.raceTo1         = param.race_to_1;
    this.eventPlayerId2  = param.ep_id_2;
    this.usrId2          = param.u_id_2;
    this.score2          = param.score_2;
    this.raceTo2         = param.race_to_2;
    this.winner          = param.winner;
    this.useMatch        = param.use_match;
    this.deleteFlag      = param.del_flg;
    this.editId          = param.edit_id;
    this.editDateTime    = param.edit_dt;
    //
    this.gameId          = param.g_id;
    this.gameMode        = param.g_mode;
    this.eventGameName   = param.e_name;
    this.startDateTime   = param.start_dt;
    this.endDateTime     = param.end_dt;
    this.turn1           = param.turn_1;
    this.average1        = param.avg_1;
    this.highRun1        = param.highrun_1;
    this.turn2           = param.turn_2;
    this.average2        = param.avg_2;
    this.highRun2        = param.highrun_2;
    this.first           = param.first;
    this.result          = param.result;
    this.param           = param.param;
  }
}
