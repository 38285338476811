



















import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventGame}     from '@/model/class/EventGame';
import BootstrapVue    from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class RoundRobinDetail extends Vue {
  @Prop() private eventStepId!: number;
  @Prop() private editSetNum!: number;
  @Prop() private editOrderNum!: number;
  @Prop() private editGameNum!: number;
  private gameKey: string = '';
  private mainInfo: string = '';
  private subInfo: string = '';
  private buttonColor: string = 'primary';
  private useMatch: boolean = false;
  private game!: EventGame;

  @Emit('viewGame') private _ViewGame(key: string): void { return; }

  private viewGame(): void {
    this._ViewGame(this.gameKey);
  }

  private created(): void {
    if (this.editOrderNum === this.editGameNum) {
      // TODO
    } else {
      this.setData();
    }
  }

  private setData(): void {
    let isAsc = true;
    if (this.editOrderNum < this.editGameNum) {
      this.gameKey = this.eventStepId + '_' + this.editSetNum + '_' + this.editOrderNum + '_' + this.editGameNum;
    } else {
      this.gameKey = this.eventStepId + '_' + this.editSetNum + '_' + this.editGameNum + '_' + this.editOrderNum;
      isAsc = false;
    }

    this.game = this.$store.getters['event/getGame'](this.gameKey);
    if (this.game.useMatch) {
      this.useMatch = false;
    } else {
      this.useMatch = true;
    }

    if (this.game.eventGameStatus <= this.C_GAME_STATUS_PAIR) {
      this.buttonColor = 'outline-secondary';
      this.mainInfo = '準備中';
    } else if (this.game.eventGameStatus === this.C_GAME_STATUS_READY) {
      if (this.game.refereeId === 0) {
        this.buttonColor = 'outline-warning';
        this.mainInfo = 'スタンバイ';
      } else {
        this.buttonColor = 'warning';
        this.mainInfo = '呼出中';
        this.subInfo = this.game.editDateTime.substr(-8);
      }
    } else if (this.game.eventGameStatus === this.C_GAME_STATUS_PLAY) {
      if (this.game.refereeId === -1) {
        this.buttonColor = 'outline-success';
        this.mainInfo = '試合中';
        this.subInfo = this.game.editDateTime.substr(-8);
      } else {
        this.buttonColor = 'outline-success';
        this.mainInfo = this.game.score1 + ' - ' + this.game.score2;
        this.subInfo = this._formatTimeSpan(this.game.playTimeSpan);
      }
    } else if (this.game.eventGameStatus === this.C_GAME_STATUS_END) {
      if (isAsc) {
        this.mainInfo = this.game.score1 + ' - ' + this.game.score2;
        if (this.game.winner === 1) {
          this.buttonColor = 'danger';
        } else if (this.game.winner === 2) {
          this.buttonColor = 'primary';
        } else {
          this.buttonColor = 'secondary';
          this.subInfo = 'Draw';
        }
      } else {
        this.mainInfo = this.game.score2 + ' - ' + this.game.score1;
        if (this.game.winner === 1) {
          this.buttonColor = 'primary';
        } else if (this.game.winner === 2) {
          this.buttonColor = 'danger';
        } else {
          this.buttonColor = 'secondary';
          this.subInfo = 'Draw';
        }
      }
    } else if (this.game.eventGameStatus === this.C_GAME_STATUS_FIX) {
      if (isAsc) {
        this.mainInfo = this.game.score1 + ' - ' + this.game.score2;
        if (this.game.winner === 1) {
          this.buttonColor = 'danger';
          this.subInfo = 'Win';
        } else if (this.game.winner === 2) {
          this.buttonColor = 'primary';
          this.subInfo = 'Lose';
        } else {
          this.buttonColor = 'secondary';
          this.subInfo = 'Draw';
        }
      } else {
        this.mainInfo = this.game.score2 + ' - ' + this.game.score1;
        if (this.game.winner === 1) {
          this.buttonColor = 'primary';
          this.subInfo = 'Lose';
        } else if (this.game.winner === 2) {
          this.buttonColor = 'danger';
          this.subInfo = 'Win';
        } else {
          this.buttonColor = 'secondary';
          this.subInfo = 'Draw';
        }
      }
    }
  }
}
