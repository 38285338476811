














































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PointPlayerParam} from '@/model/interface/PointPlayerParam';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class PointTable extends Vue {
  @Prop() private playerData!: PointPlayerParam[];
  @Prop() private currentNum!: number;
  @Prop() private frameData!: Array<{breakNum: number, isDouble: boolean, isRunout: boolean}>;
  private inningLen: number[] = [];
  private playerLen!: number;

  private get playerCountClass(): string {
    if (this.playerLen > 5) {
      return 'player-count6';
    } else {
      return 'player-count' + this.playerLen.toString();
    }
  }

  private getPoint(index: number, inning: number, num: number): number|string {
    if ((inning - 1) === this.currentNum) {
      return '/';
    } else if ((inning - 1) < this.currentNum) {
      if (this.currentNum === 99 && (inning - 1) >= this.playerData[index].points.length) {
        return '';
      } else {
        return this.playerData[index].points[inning - 1][num];
      }
    } else {
      return '';
    }
  }

  private getScore(index: number, inning: number): number|string {
    if ((inning - 1) === this.currentNum) {
      return '-';
    } else if ((inning - 1) < this.currentNum) {
      if (this.currentNum === 99 && (inning - 1) >= this.playerData[index].scores.length) {
        return '';
      } else {
        return this.playerData[index].scores[inning - 1];
      }
    } else {
      return '';
    }
  }

  private getSumScore(index: number, inning: number): number|string {
    if ((inning - 1) === this.currentNum) {
      return '-';
    } else if ((inning - 1) < this.currentNum) {
      return this.playerData[index].sumPoint[inning - 1];
    } else {
      return '';
    }
  }

  private getIsHighlight(index: number, inning: number): string {
    let isDouble = false;
    let isRunout = false;
    if (this.frameData.length >= inning) {
      if (this.frameData[inning - 1].isRunout && this.playerData[index].sumPoint[inning - 1] > 0) {
        isRunout = true;
      }
      if (this.frameData[inning - 1].isDouble && this.playerData[index].sumPoint[inning - 1] > 0) {
        isDouble = true;
      }
    }
    if (isDouble && isRunout) {
      return 'point-high';
    } else if (isDouble) {
      return 'point-double';
    } else if (isRunout) {
      return 'point-runout';
    }

    return '';
  }

  private created(): void {
    const inning = this.$store.getters['multi/getConfigParam']('limitInning');
    for (let i = 1; i <= inning; i++) {
      this.inningLen.push(i);
    }
    this.playerLen = this.playerData.length;
  }
}
