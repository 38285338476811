




































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Header          from '@/components/common/Header.vue';
import Game9BallResult from '@/components/score/match/Game9BallResult.vue';
import Game10BallResult from '@/components/score/match/Game10BallResult.vue';
import Game14OneResult from '@/components/score/match/Game14OneResult.vue';
import libAxios        from '@/mixin/LibAxios';
import BootstrapVue    from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    Game9BallResult,
    Game10BallResult,
    Game14OneResult,
  },
})
export default class ScoreList extends Vue {
  private usrType!: number;
  private gameName: string = '';
  private gameNum: number = 0;
  private nextUrl!: {name: string};
  private searchParam: {[key: string]: any} = {};
  private gameList: any[] = [];
  private modalShow: boolean = false;
  private gameParam: {[key: string]: any} = {};
  private currentPage: number = 0;
  private rows: number = 0;
  private perPage: number = 10;
  private connection: boolean = true;

  private breadItems: Array<{[key: string]: any}> = [
    {text: 'Top', to: { name: 'top' }},
    {text: 'Score', to: { name: 'score' }},
  ];

  @Watch('currentPage')
  private onTextChanged(newNum: number, oldNum: number) {
    this.searchParam.game_type = this.gameNum;
    this.searchParam.page_num = newNum;
    this.searchParam.page_limit = 10;
    this.searchParam.order_column = 'g_id';
    this.searchParam.order_option = 'DESC';
    this.$store.dispatch('searchScoreAction', this.searchParam);
    this.getScoreList();
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          const pageNum = 1;
          this._getResumeData(0); // Gameではないので戻り値は無視
          this.usrType = this.$store.getters.getUsrType();
          this.gameName = this.$route.params.type;

          if (this.gameName === this.C_NAME_9B) {
            this.breadItems.push({ text: '9ball Score List', active: true});
            this.gameNum = this.C_NUM_9B;
            this.nextUrl = {name: 'score-9ball'};
          } else if (this.gameName === this.C_NAME_10B) {
            this.breadItems.push({ text: '10ball Score List', active: true});
            this.gameNum = this.C_NUM_10B;
            this.nextUrl = {name: 'score-10ball'};
          } else if (this.gameName === this.C_NAME_14_1) {
            this.breadItems.push({ text: '14-1 Score List', active: true});
            this.gameNum = this.C_NUM_14_1;
            this.nextUrl = {name: 'score-14one'};
          } else if (this.gameName === this.C_NAME_ROTE) {
            this.breadItems.push({ text: 'Rotation Score List', active: true});
            this.gameNum = this.C_NUM_ROTE;
            this.nextUrl = {name: 'score-rotation'};
          } else if (this.gameName === this.C_NAME_POINT) {
            this.gameNum = this.C_NUM_POINT;
            this.nextUrl = {name: 'score-point'};
          } else if (this.gameName === this.C_NAME_FREE_POINT) {
            this.gameNum = this.C_NUM_FREE_POINT;
            this.nextUrl = {name: 'score-free-point'};
          } else {
            throw new Error('引数エラー');
          }
          this.currentPage = pageNum;
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getScoreList() {
    this.connection = true;

    // 保存先から取得する
    const saveParam = this.$store.getters.getSearchScore();
    const search = JSON.parse(saveParam);
    const token = this.$store.getters.getToken();
    libAxios.get('game-data/match', token, search)
    .then((param) => {
      const tempList: any[] = [];
      const nowDT = this._formatDate(new Date(), 'Y-m-d');
      const usrID = this.$store.getters.getUsrId();
      for (const item of param.data) {
        const tmpItem: {[key: string]: any} = {};
        tmpItem.isMark = false;
        tmpItem.isGuest = false;
        if (item.u_id_1 === usrID) {
          tmpItem.plyerName = item.u_name_2;
          tmpItem.score1    = item.score_1;
          tmpItem.score2    = item.score_2;
          tmpItem.raceTo1   = item.race_to_1;
          tmpItem.raceTo2   = item.race_to_2;
          tmpItem.resultChr = (item.winner === 1) ? 'W' : '';
          if (item.checked === 0 || item.checked === 2) {
            tmpItem.isMark = true;
          }
          tmpItem.isGuest = (item.u_id_2 === 0);
          tmpItem.isFirst = true;
        } else {
          tmpItem.plyerName = item.u_name_1;
          tmpItem.score1    = item.score_2;
          tmpItem.score2    = item.score_1;
          tmpItem.raceTo1   = item.race_to_2;
          tmpItem.raceTo2   = item.race_to_1;
          tmpItem.resultChr    = (item.winner === 2) ? 'W' : '';
          if (item.checked === 0 || item.checked === 1) {
            tmpItem.isMark = true;
          }
          tmpItem.isGuest = (item.u_id_1 === 0);
          tmpItem.isFirst = false;
        }
        tmpItem.first = item.first;
        if (tmpItem.resultChr !== 'W' && item.winner !== 0) {
          tmpItem.resultChr = 'L';
        }
        let tmpViewDT = item.start_dt;
        if (tmpViewDT.substr(0, 10) === nowDT) {
          tmpItem.isToday = true;
          tmpViewDT = tmpViewDT.substr(11, 5);
        } else {
          tmpItem.isToday = false;
          tmpViewDT = tmpViewDT.substr(5, 5);
        }
        tmpItem.viewDT = tmpViewDT;
        tmpItem.startDateTime = item.start_dt;
        tmpItem.endDateTime = item.end_dt;

        tmpItem.place = item.d_name;
        if (item.d_id !== 0 && item.d_tbl !== 0) {
          tmpItem.place += ' ' + item.d_tbl + '番台';
        }
        tmpItem.title = item.e_name;
        tmpItem.isFix = (item.g_status === this.C_STATUS_FIX);
        tmpItem.id = item.g_id;
        tmpItem.eventID = item.e_id;
        if (!tmpItem.isFix && item.e_id === 0 && tmpItem.isMark) {
          tmpItem.isMark = true;
        } else {
          tmpItem.isMark = false;
        }
        tmpItem.param = item.param;
        tmpItem.result = item.result;
        tempList.push(tmpItem);
      }
      this.gameList = tempList;
      this.rows = param.cnt;
      this.connection = false;
      return;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private viewDetail(id: number) {
    for (const game of this.gameList) {
      if (id === game.id) {
        this.gameParam = game;
        this.modalShow = true;
        return;
      }
    }
    // TODO ERROR
  }

  private async delScore(id: number) {
    if (!window.confirm('削除してよいですか？\n削除したデータの復旧はできません。')) {
      return;
    }
    const token = this.$store.getters.getToken();
    return await libAxios.delete('game-data/match/' + id, token, {})
    .then(() => {
      this.getScoreList();
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private async fixScore(gameID: number) {
    this.connection = true;
    const postParam = {
      command: 'check',
    };

    const token = this.$store.getters.getToken();
    return await libAxios.post('game-data/match/' + gameID, token, postParam)
    .then(() => {
      // this.getMultiScoreList();
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
