








































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {PlayerParameter} from '@/model/interface/PlayerParameter';
import libAxios        from '@/mixin/LibAxios';
import BootstrapVue      from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class PlayerSearch extends Vue {
  @Prop() private usePublic!: boolean;
  private connection: boolean = false;
  private usrType!: number;
  private usrId: string|number = '';
  private usrName: string = '';

  @Emit('setPlayerSearched') private setPlayerSearched(player: PlayerParameter): void { return; }

  private created(): void {
    this.usrType = this.$store.getters.getUsrType();
  }

  private searchUsr(): void {
    if (this.usrId === '' || this.usrId < 1) {
      alert('1以上の数値を入力してください');
      return;
    }

    const departments = this.$store.getters.getDepartments();
    for (const department of departments) {
      const users = department.getUsers();
      for (const usr of users) {
        if (usr.getId() === this.usrId) {
          this.usrName = usr.getName();
          const tmpPlayer: PlayerParameter = {
            id: usr.getId(),
            name: usr.getName(),
            nameShort: usr.getNameShort(),
          };
          this.setPlayerSearched(tmpPlayer);
          return;
        }
      }
    }
    // TODO メンバーならネット検索
    if (this.usrType === this.C_MODE_GUEST) {
      alert('not found');
    } else {
      this.getSearchUsr();
    }
  }

  private async getSearchUsr() {
    this.connection = true;
    const token = this.$store.getters.getToken();
    return await libAxios.get('search-users', token, {u_id: this.usrId})
    .then((param: {[key: string]: any}) => {
      this.connection = false;
      if (param.u_id) {
        this.usrName = param.u_pri_n;
        const tmpPlayer: PlayerParameter = {
          id: param.u_id,
          name: (this.usePublic) ? param.u_pub_n : param.u_pri_n,
          nameShort: (this.usePublic) ? param.u_pub_s : param.u_pri_s,
        };
        this.setPlayerSearched(tmpPlayer);
      } else {
        alert('存在しないIDです');
      }
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }
}
