


















































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game10BallReport extends Vue {
  @Prop() private gameData!: Array<{[key: string]: any}>;
  private totalData: Array<{[key: string]: any}> = [];
  private usrData: Array<{[key: string]: any}> = [];
  private placeData: Array<{[key: string]: any}> = [];

  private created(): void {
    for (const rec of this.gameData) {
      const tmpData: {[key: string]: any} = {
        matchCount: rec.match_cnt,
        winCount: rec.win_cnt,
        loseCount: rec.match_cnt - rec.win_cnt,
        winSet: rec.win_set,
        loseSet: rec.lose_set,
        breakCount: rec.break_cnt,
        breakNoIn: rec.break_in,
        breakScratch: rec.break_sc,
      };

      const totalSet = rec.win_set + rec.lose_set;
      if (totalSet > 0) {
        const avgTimeSpan = Math.floor(rec.play_ts / totalSet);
        const avgMin = Math.floor(avgTimeSpan / 60);
        const avgSec = avgTimeSpan % 60;
        tmpData.playTime = avgMin + ':' + avgSec;
      } else {
        tmpData.playTime = '-';
      }

      const normalBreak = rec.break_cnt - rec.break_no - rec.break_sc;
      if (rec.break_cnt > 0 && normalBreak > 0) {
        tmpData.breakIn = (rec.break_in / normalBreak).toFixed(2);
        tmpData.breakAvg = Math.floor((normalBreak / rec.break_cnt) * 100);
        tmpData.Runout = rec.run_out;
        tmpData.RunoutAvg = Math.floor((rec.run_out / normalBreak) * 100);
      } else {
        tmpData.breakIn = '-';
        tmpData.breakAvg = '-';
        tmpData.Runout = '-';
        tmpData.RunoutAvg = '-';
      }

      if (rec.rt_type === 1 || rec.rt_type === 11) {
        if (rec.ym === '999999') {
          tmpData.name = 'TOTAL';
        } else {
          tmpData.name = rec.ym.substr(0, 4) + '-' + rec.ym.substr(4, 2);
        }
        this.totalData.push(tmpData);
      } else if (rec.rt_type === 12) {
        tmpData.name = rec.tgt_name;
        this.usrData.push(tmpData);
      } else if (rec.rt_type === 13) {
        tmpData.name = rec.tgt_name + rec.tgt_num + '番台';
        this.placeData.push(tmpData);
      }
    }
  }
}
