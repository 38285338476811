













































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {MatchParam}       from '@/model/interface/MatchParam';
import {MatchPlayerParam} from '@/model/interface/MatchPlayerParam';
import {MatchData14One}   from '@/model/interface/MatchData14One';
import {Point14One}       from '@/model/interface/Point14One';
import MatchHeader        from '@/components/score/match/MatchHeader.vue';
import NamePlate          from '@/components/score/match/NamePlate.vue';
import ConfigRaceTo       from '@/components/score/match/ConfigRaceTo.vue';
import Game14OneConfig    from '@/components/score/match/Game14OneConfig.vue';
import MatchConfig        from '@/components/score/match/MatchConfig.vue';
import Game14OneTable     from '@/components/score/match/Game14OneTable.vue';
import PointPlate         from '@/components/score/match/PointPlate.vue';
import GameStartPanel     from '@/components/score/match/GameStartPanel.vue';
import Game14OneBreak     from '@/components/score/match/Game14OneBreak.vue';
import YesNoPanel         from '@/components/score/match/YesNoPanel.vue';
import Game14OnePlayLv1   from '@/components/score/match/Game14OnePlayLv1.vue';
import Game14OnePlay      from '@/components/score/match/Game14OnePlay.vue';
import GameJudgePanel     from '@/components/score/match/GameJudgePanel.vue';
import StopWatch          from '@/components/common/StopWatch.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    MatchHeader,
    NamePlate,
    ConfigRaceTo,
    Game14OneConfig,
    MatchConfig,
    Game14OneTable,
    PointPlate,
    GameStartPanel,
    Game14OneBreak,
    YesNoPanel,
    Game14OnePlayLv1,
    Game14OnePlay,
    GameJudgePanel,
    StopWatch,
  },
})
export default class Game14One extends Vue {
  private isLoading: boolean = true;
  private isSetting: boolean = true;
  private isReverse: boolean = false;
  private usrType!: number;
  private eventId!: number;
  private match14one!: MatchData14One;
  private baseTime: number = 0;
  private baseTimeOrg: number = 0; // 再開時の元
  private endDateTime!: string;
  private playTimeSpan: number = 0;
  private currentBall: number = 0;
  private viewCount: number = 0;

  private get scoreLv(): number {
    return this.$store.getters['match/getConfigParam']('scoreLv');
  }

  private get useWatch(): number {
    return this.$store.getters['match/getConfigParam']('stopWatch');
  }

  private created(): void {
    try {
      this._checkLogin().
      then((result: boolean) => {
        this.usrType = this.$store.getters.getUsrType();
        this.eventId = this.$store.getters['match/getEventId']();
        this._created();
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private _created(): void {
    const suspend = this._getResumeData(this.C_NUM_14_1);
    if (suspend === '') {
      this.$router.push({name: 'error'}); // SYSTEM ERROR
    }

    const tmpGameId = this.$store.getters['match/getGameId']();
    if (tmpGameId !== 0) {
      if (tmpGameId !== suspend.gameId) {
        this.$router.push({name: 'error'}); // SYSTEM ERROR
      }
    } else { // suspendからすべてを復活させる
      this.$store.dispatch('match/resumeAction', suspend);
    }

    const tmpStatus = this.$store.getters['match/getGameStatus']();

    this.match14one = {
      viewMode: this.C_VIEW_START,
      isFirst: true, // Player1かどうか
      firstPlayer: 0,
      gameParam: [],
      player1foul: 0, // 使う
      player2foul: 0, // 使う
      ball: 0,
      inning: 0,
      scores1: [0],
      scores2: [0],
      viewScore1: [''],
      viewScore2: [''],
    };

    if (tmpStatus === this.C_STATUS_INIT) {
      let tmpConfig = Object.assign(this.C_CFG_14_1_PARAM, this.C_CFG_MATCH_COMMON);
      tmpConfig = this._getConfig(tmpConfig, this.C_NAME_14_1);
      // イベントなら
      if (this.eventId !== 0) {
        const eventConfig = this.$store.getters['match/getConfig']();
        for (const param of Object.keys(eventConfig)) {
          tmpConfig[param] = eventConfig[param];
        }
      }
      this.$store.dispatch('match/setConfigAction', tmpConfig);
    } else if (tmpStatus === this.C_STATUS_READY) {
      this.isSetting = false;
    } else if (tmpStatus === this.C_STATUS_PLAY) { // 途中開始
      this.match14one.firstPlayer = this.$store.getters['match/getFirst']();
      this.match14one.isFirst = (this.match14one.firstPlayer === 1);
      this.match14one.viewMode = this.C_VIEW_BREAK;

      // ここで C_STATUS_PLAY
      const dateTime = new Date() ;
      this.baseTime = Math.floor( dateTime.getTime() / 1000 ) ;
      const strParam = this.$store.getters['match/getMatchGameParam']();
      this.$store.dispatch('match/clearDataAction');

      if (strParam === '') {
        this.$router.push({name: 'error'}); // TODO break?
      }

      const tmpBuf = strParam.split(',');
      if (tmpBuf.length !== 2) {
        this.$router.push({name: 'error'}); // TODO break?
      }
      this.match14one.gameParam = tmpBuf[1].split(';');
      this.calcParam();
      this.isSetting = false;
    } else {
      // TODO
    }
    this.isLoading = false;
    this.$forceUpdate();
  }

  private calcParam(): void {
    for (const param of this.match14one.gameParam) {
      const action = param.charAt(0);
      const balls = parseInt(param.charAt(1), 16);
      this.execAction(action, balls, false);
    }
  }

  private toGame(): void {
    // Status
    const tmpStatus: number = this.$store.getters['match/getGameStatus']();
    if (tmpStatus < this.C_STATUS_READY) {
      this.$store.dispatch('match/readyAction');
    } else {
      this.$store.dispatch('match/toGameAction');
    }
    this.isSetting = false;
  }

  // break は一回だけ
  private gameStart(playerNum: number): void {
    this.currentBall = 0;
    this.match14one = {
      viewMode: this.C_VIEW_BREAK,
      isFirst: (playerNum === 1), // Player1?
      firstPlayer: playerNum,
      gameParam: [],
      player1foul: 0, // 使う
      player2foul: 0, // 使う
      ball: 0,
      inning: 0,
      scores1: [0],
      scores2: [0],
      viewScore1: [''],
      viewScore2: [''],
    };

    // ここで C_STATUS_PLAY
    const dateTime = new Date() ;
    this.baseTime = Math.floor( dateTime.getTime() / 1000 ) ;

    const startParam = {
      startDateTime: this._formatDate(dateTime, ''),
      first: playerNum,
    };
    this.$store.dispatch('match/startAction', startParam);
    this.$forceUpdate();
  }

  private undo(): void {
    this.isLoading = true;
    this.$store.dispatch('match/clearDataAction');
    if (this.match14one.gameParam.length === 0) {
      this.match14one.viewMode = this.C_VIEW_START;
    } else {
      this.currentBall = 0;
      this.match14one.viewMode = this.C_VIEW_BREAK;
      this.match14one.isFirst = (this.match14one.firstPlayer === 1);
      this.match14one.player1foul = 0;
      this.match14one.player2foul = 0;
      this.match14one.ball = 0;
      this.match14one.inning = 0;
      this.match14one.scores1 = [0],
      this.match14one.scores2 = [0],
      this.match14one.viewScore1 = [''],
      this.match14one.viewScore2 = [''],
      this.match14one.gameParam.pop();
      this.calcParam();
    }
    this.isLoading = false;
    this.$forceUpdate();
  }

  private postPlayData(): void {
    const maxLen = this.match14one.scores1.length; // ほかの配列も長さは一緒
    let strParam = '';
    if (this.match14one.firstPlayer === 1) {
      for (let i = 0; i < maxLen; i++) {
        strParam += this.match14one.scores1[i] + ':' +  this.match14one.scores2[i] + ':';
        strParam += this.match14one.viewScore1[i] + ':' +  this.match14one.viewScore2[i] + ';';
      }
    } else {
      for (let i = 0; i < maxLen; i++) {
        strParam += this.match14one.scores2[i] + ':' +  this.match14one.scores1[i] + ':';
        strParam += this.match14one.viewScore2[i] + ':' +  this.match14one.viewScore1[i] + ';';
      }
    }
    const saveParam: {[key: string]: any} = {
      endDateTime: this.endDateTime,
      playTimeSpan: this.playTimeSpan,
      param: strParam + ',' + this.match14one.gameParam.join(';'),
    };
    this.viewCount++;
    this.$store.dispatch('match/saveAction', saveParam);
  }

  private actionType(action: string, ball: number): void {
    this.execAction(action, ball, true);
  }

  private execAction(action: string, ball: number, isView: boolean): void {
    let point14One!: Point14One;

    switch (action) {
      case 's': // safety
        point14One = {change: true,  foul: false, point: 0};
        break;
      case 'r': // ブレイク foul 再ブレイク
        point14One = {change: false, foul: false, point: -2};
        break;
      case 'f': // ブレイク foul 現状スタート
        point14One = {change: true,  foul: false, point: -2};
        break;
      case 'g': // ブレイク スクラッチ
        point14One = {change: true,  foul: true, point: -1};
        break;
      case 'e': // ブレイク -1ファール
        point14One = {change: true,  foul: true,  point: -1};
        break;
      case 'p': // 得点
        point14One = {change: false, foul: false,  point: 0};
      case 'w': // 得点
        point14One = {change: false, foul: false,  point: 0};
        break;
      case 'M': // miss
        point14One = {change: true,  foul: false, point: 0};
        break;
      case 'F': // foul
        point14One = {change: true,  foul: true, point: -1};
        break;
      case 'G': // Scratch
        point14One = {change: true,  foul: true, point: -1};
        break;
      case 'S': // safety
        point14One = {change: true,  foul: false, point: 0};
        break;
       case 'P': // 得点
        point14One = {change: false, foul: false,  point: 0};
      case 'X': // 14-next
      case 'x': // 15in
      case 'W':
      case 'A':
      case 'B':
      case 'D':
        point14One = {change: false, foul: false, point: 0};
        break;
      default:
        // ERROR
        break;
    }

    const tmpInning = this.match14one.inning;
    if (ball > 0) {
      this.$store.dispatch(
        'match/addScoreAction',
        {isFirst: this.match14one.isFirst, addPoint: ball},
      );

      this.currentBall += ball;
      // この時点でファールはリセット
      if (this.match14one.isFirst) {
        this.match14one.scores1[tmpInning] += ball;
        let scoreDiff = this.match14one.scores1[tmpInning];
        if (tmpInning > 0) {
          scoreDiff -= this.match14one.scores1[tmpInning - 1];
        }
        this.match14one.viewScore1[tmpInning] = '+' + scoreDiff.toString();
        this.$store.dispatch('match/setHighRunAction', {isFirst: true, highRun: scoreDiff});
        this.match14one.player1foul = 0;
      } else {
        this.match14one.scores2[tmpInning] += ball;
        let scoreDiff = this.match14one.scores2[tmpInning];
        if (tmpInning > 0) {
          scoreDiff -= this.match14one.scores2[tmpInning - 1];
        }
        this.match14one.viewScore2[tmpInning] = '+' + scoreDiff.toString();
        this.$store.dispatch('match/setHighRunAction', {isFirst: false, highRun: scoreDiff});
        this.match14one.player2foul = 0;
      }
    }

    if (point14One.point !== 0) {
      this.$store.dispatch(
        'match/addScoreAction',
        {isFirst: this.match14one.isFirst, addPoint: point14One.point},
      );

      if (this.match14one.isFirst) {
        this.match14one.scores1[tmpInning] += point14One.point;
        this.match14one.viewScore1[tmpInning] += point14One.point.toString();
      } else {
        this.match14one.scores2[tmpInning] += point14One.point;
        this.match14one.viewScore2[tmpInning] += point14One.point.toString();
      }
    }

    if (point14One.foul) {
      if (this.match14one.isFirst) {
        this.match14one.player1foul++;
        if (this.match14one.player1foul >= 3) {
          this.$store.dispatch('match/addScoreAction', {isFirst: true, addPoint: -15});
          this.match14one.player1foul = 0;
          this.match14one.scores1[tmpInning] -= 15;
          this.match14one.viewScore1[tmpInning] += 'F';
        }
      } else {
        this.match14one.player2foul++;
        if (this.match14one.player2foul >= 3) {
          this.$store.dispatch('match/addScoreAction', {isFirst: false, addPoint: -15});
          this.match14one.player2foul = 0;
          this.match14one.scores2[tmpInning] -= 15;
          this.match14one.viewScore2[tmpInning] += 'F';
        }
      }
    } else {
      if (this.match14one.isFirst) {
        this.match14one.player1foul = 0;
      } else {
        this.match14one.player2foul = 0;
      }
    }

    if (action === 'S' || action === 's') {
      if (this.match14one.isFirst) {
        this.match14one.viewScore1[tmpInning] += 'S';
      } else {
        this.match14one.viewScore2[tmpInning] += 'S';
      }
    } else if (action === 'X' || action === 'x') {
      this.currentBall = 0;
    }

    // 時間を計測
    const dateTime = new Date() ;
    const nowDate = Math.floor( dateTime.getTime() / 1000 ) ;

    this.endDateTime = this._formatDate(dateTime, '');
    this.playTimeSpan = nowDate - this.baseTime;

    let isJudge = false;
    if (point14One.change) {
      if ((this.match14one.firstPlayer === 1 && !this.match14one.isFirst) ||
        (this.match14one.firstPlayer === 2 && this.match14one.isFirst)) {
          // 上限確認
        const limitType = this.$store.getters['match/getConfigParam']('limitType');
        if (limitType === 1) {
          const limitInning = this.$store.getters['match/getConfigParam']('limitInning') - 1;
          if (tmpInning >= limitInning) {
            isJudge = true;
          }
        } else if (limitType === 2) {
          const limitTime = this.$store.getters['match/getConfigParam']('limitTime') * 60;
          if (this.playTimeSpan > limitTime) {
            isJudge = true;
          }
        }

        if (!isJudge) {
          this.match14one.scores1.push(this.match14one.scores1[tmpInning]);
          this.match14one.scores2.push(this.match14one.scores2[tmpInning]);
          this.match14one.viewScore1.push('');
          this.match14one.viewScore2.push('');
          this.match14one.inning++;
        }
      }
      this.match14one.isFirst = !this.match14one.isFirst;
    }

    let tmpWinner = 0;
    let tmpView = this.C_VIEW_PLAY;
    if (isJudge) {
      const judgeType = this.$store.getters['match/getConfigParam']('judgeType');
      if (judgeType === 1) {
        tmpWinner = -1;
        tmpView = this.C_VIEW_FIN;
      } else if (judgeType === 2) {
        const score1 = this.$store.getters['match/getPlayer1Score']();
        const score2 = this.$store.getters['match/getPlayer2Score']();
        if (score1 > score2) {
          tmpWinner = 1;
        } else if (score1 > score2) {
          tmpWinner = 2;
        } else {
          tmpWinner = -1;
        }
        tmpView = this.C_VIEW_FIN;
      } else if (judgeType === 3) {
        tmpView = this.C_VIEW_JUDGE;
      }
    } else if (action === 'W' || action === 'w') {
      if (this.match14one.isFirst) {
        tmpWinner = 1;
      } else {
        tmpWinner = 2;
      }
      tmpView = this.C_VIEW_FIN;
    } else if (action === 'A' || action === 'B') {
      if ((this.match14one.isFirst && action === 'A') || (!this.match14one.isFirst && action === 'B')) {
        tmpWinner = 1;
      } else {
        tmpWinner = 2;
      }
      tmpView = this.C_VIEW_FIN;
    } else if (action === 'D') {
      tmpWinner = -1;
      tmpView = this.C_VIEW_FIN;
    } else if (action === 'r') {
      tmpView = this.C_VIEW_BREAK;
    }

    if (tmpWinner !== 0) {
      let p1Inning = tmpInning;
      let p2Inning = tmpInning;
      if (this.match14one.firstPlayer === 1) {
        p1Inning++;
        if (!this.match14one.isFirst) {
          p2Inning++;
        }
      } else {
        p2Inning++;
        if (this.match14one.isFirst) {
          p1Inning++;
        }
      }
      const winParam = {
        winner: tmpWinner,
        player1Inning: p1Inning,
        player2Inning: p2Inning,
      };
      this.$store.dispatch('match/setWinnerInningAction', winParam);
    }
    this.match14one.viewMode = tmpView;

    if (isView) {
      this.match14one.gameParam.push(action + ball.toString(16));
      this.postPlayData();
      this.$forceUpdate();
    }
  }

  // isFin score top
  private gameFin(isFin: boolean): void {
    this.isLoading = true;
    this.isSetting = true;
    try {
      if (this.usrType === this.C_MODE_GUEST) { // ゲストの場合はデータ破棄
        if (isFin) {
          this.$store.dispatch('match/destroyAction');
          this.$router.push({name: 'score'});
        } else {
          this.$store.dispatch('match/retryGuestAction');
          this._created();
        }
      } else {
        this.$store.dispatch('match/finAction', isFin)
        .then(() => {
          if (this.eventId !== 0) {
            this.$router.push({ name: 'event-match', params: {type: this.C_NAME_MATCH} });
          } else {
            if (isFin) {
              if (this.usrType === this.C_MODE_MEMBER) {
                this.$router.push({name: 'score-list', params: {type: this.C_NAME_14_1}});
              } else {
                this.$router.push({name: 'score'});
              }
            } else {
              this.$store.dispatch('match/retryAction')
              .then(() => {
                this._created();
              }).catch((e) => {
                throw new Error(e.message);
              });
            }
          }
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      this.match14one.viewMode = this.C_VIEW_ERROR;
      this.isLoading = true;
    }
  }

  private reverse(): void {
    this.isReverse = !this.isReverse;
  }

  private setting(): void {
    this.isSetting = true;
  }

  private destroy(): void {
    if (window.confirm('ここまでのデータを破棄しますか？')) {
      this.$store.dispatch('match/destroyAction');
      this.$router.push({name: 'score'});
    }
  }
}
