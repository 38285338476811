



























import {Component, Prop, Vue} from 'vue-property-decorator';
import AdminHeader  from '@/components/admin/common/AdminHeader.vue';
import DeptParam    from '@/components/admin/DeptParam.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    AdminHeader,
    DeptParam,
  },
})
export default class DeptDetail extends Vue {
  private isLoading: boolean = true; // 必須
  private deptId: number = 0;
  private deptParam!: {[key: string]: any};
  private editDateTime: string = '';
  private errParam: {[key: string]: any} = {};
  private connection: boolean = false;

  private breadItems: Array<{[key: string]: any}> = [
    { text: '管理Top', to: { name: 'admin-top' }},
    { text: '組織一覧', to: { name: 'admin-org-list' }},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        this.deptId = parseInt(this.$route.params.id, 10);
        this.getDeptData();
      }).catch((e: Error) => {
        // throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getDeptData() {
    const token = this.$store.getters.getToken();
    libAxios.get('dept-for-org/' + this.deptId + '/edit', token, {})
    .then((param: {[key: string]: any}) => {
      this.deptParam = param;
      this.editDateTime = param.edit_dt;
      this.breadItems.push({ text: param.o_name + '部門一覧', to: { name: 'admin-org-tree', params: {id: param.o_id} }});
      this.breadItems.push({ text: param.d_name + '編集', active: true});
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async saveData(sendParam: {[key: string]: any} ) {
    this.connection = true;
    this.errParam = {};
    sendParam.edit_dt = this.editDateTime;
    const token = this.$store.getters.getToken();
    return await libAxios.put('dept-for-org/' + this.deptId, token, sendParam)
    .then((param: {[key: string]: any}) => {
      this.editDateTime = param.edit_dt;
      alert('更新しました');
      this.connection = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
