












































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {PointPlayerParam} from '@/model/interface/PointPlayerParam';
import NumberUpDownButton from '@/components/score/common/NumberUpDownButton.vue';
import RandomPoint        from '@/components/score/common/RandomPoint.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    NumberUpDownButton,
    RandomPoint,
  },
})
export default class FreePointPanel extends Vue {
  @Prop() private playerData!: PointPlayerParam[];
  @Prop() private currentNum!: number;
  private point: number = 0;
  private scores: number[] = [];
  private playerLen!: number;
  private modalRandomPoint: boolean = false;
  private minNum: number = 1;
  private maxNum: number = 50;
  private randomMode: number = 1;

  @Emit('nextFrame') private nextFrame(times: number, index: number, point: number): void { return; }

  private get playerCountClass(): string {
    if (this.playerLen > 5) {
      return 'player-count6';
    } else {
      return 'player-count' + this.playerLen.toString();
    }
  }

  private useNextBtn(index: number): boolean {
    if (this.playerData[index].tmpScore === 0) {
      return false;
    } else {
      return true;
    }
  }

  private updatePoint(times: number, index: number): void {
    if (this.point === 0) {
      alert('Input Point');
      return;
    }
    this.nextFrame(times, index, this.point);
    this.point = 0;
  }

  private addPoint(addNum: number): void {
    this.point += addNum;
    if (this.point < 0) {
      this.point = 0;
    }
  }

  private setPoint(setNum: number, minNum: number, maxNum: number): void {
    this.point = setNum;
    this.minNum = minNum;
    this.maxNum = maxNum;
    this.modalRandomPoint = false;
  }

  private created(): void {
    this.playerLen = this.playerData.length;
  }

  private openRandom(num: number): void {
    this.randomMode = num;
    this.modalRandomPoint = true;
  }
}
