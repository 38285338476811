export class Profile {
  public name1: string = '';
  public name2: string = '';
  public kana1: string = '';
  public kana2: string = '';
  public pubName: string = '';
  public pubNameShort: string = '';
  public pubClass: string = '';
  public priName: string = '';
  public priNameShort: string = '';
  public priClass: string = '';
  public birthDayY: number = 0;
  public birthDayM: number = 0;
  public sex: number = 0;
  public pref: number = 0;
}
