export class User {
  private id!: number;
  private name!: string;
  private nameShort!: string;

  constructor(id: number, name: string, nameShort: string) {
    this.id = id;
    this.name = name;
    this.nameShort = nameShort;
  }

  public getId(): number {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public getNameShort(): string {
    return this.nameShort;
  }

  public init(): void {
    this.id = 0;
    this.name = '';
  }

  public copy(user: User): void {
    this.id = user.getId();
    this.name = user.getName();
    this.nameShort = user.getNameShort();
  }
}
