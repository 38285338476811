





























































































































































import {Component, Vue} from 'vue-property-decorator';
import {EventStep}      from '@/model/class/EventStep';
import {EventPlayer}     from '@/model/class/EventPlayer';
import Header           from '@/components/common/Header.vue';
import RoundRobin       from '@/components/event/table/RoundRobin.vue';
import Single10         from '@/components/event/table/Single10.vue';
import Single20         from '@/components/event/table/Single20.vue';
import Single30         from '@/components/event/table/Single30.vue';
import Single40         from '@/components/event/table/Single40.vue';
import Single50         from '@/components/event/table/Single50.vue';
import Double20         from '@/components/event/table/Double20.vue';
import Double21         from '@/components/event/table/Double21.vue';
import Double23         from '@/components/event/table/Double23.vue';
import Double30         from '@/components/event/table/Double30.vue';
import Double31         from '@/components/event/table/Double31.vue';
import Double32         from '@/components/event/table/Double32.vue';
import Double40         from '@/components/event/table/Double40.vue';
import Double41         from '@/components/event/table/Double41.vue';
import Double42         from '@/components/event/table/Double42.vue';
import Double43         from '@/components/event/table/Double43.vue';
import Double50         from '@/components/event/table/Double50.vue';
import Game9BallResult  from '@/components/score/match/Game9BallResult.vue';
import Game10BallResult from '@/components/score/match/Game10BallResult.vue';
import Game14OneResult  from '@/components/score/match/Game14OneResult.vue';
import libAxios         from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    RoundRobin,
    Single10,
    Single20,
    Single30,
    Single40,
    Single50,
    Double20,
    Double21,
    Double23,
    Double30,
    Double31,
    Double32,
    Double40,
    Double41,
    Double42,
    Double43,
    Double50,
    Game9BallResult,
    Game10BallResult,
    Game14OneResult,
  },
})
export default class EventInfo extends Vue {
  private usrType!: number;
  private isLoading: boolean = true;
  private eventData: {[key: string]: any} = {};
  private title: string = '';
  private body: string = '';
  private status: number = 0;
  private entryType: number = 0;
  private entryCount: number = 0;
  private waitCount: number = 0;
  private eventSteps: {[key: number]: EventStep} = {};
  private eventPlayers: EventPlayer[] = [];
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
  ];
  private eventId: string = '';
  private regularId: string = '';
  private regularTitle: string = '';
  private modalShow: boolean = false;
  private gameNum: number = 0;
  private gameParam: {[key: string]: any} = {};
  private noData: boolean = false;

  private entryStatuses: string[] = ['未登録', 'エントリー済', 'キャンセル待ち'];

  private created(): void {
    try {
      if (this.$route.path.substr(0, 14) === '/regular-event') {
        this.regularId = this.$route.params.id1;
        this.eventId = this.$route.params.id2;
        this.regularTitle = this.$route.params.str;
        this.breadItems.push({ text: '定例Event一覧', to: {name: 'regular-event'}});
        this.breadItems.push({
          text: this.regularTitle + '一覧',
          to : { name: 'regular-event-info', params: {id: this.regularId, str: this.regularTitle}},
        });
      } else {
        this.eventId = this.$route.params.id;
        this.breadItems.push({ text: 'Event一覧', to: { name: 'event' }});
      }

      this._checkLogin().
      then((isLogin: boolean) => {
        this._getResumeData(0); // Gameではないので戻り値は無視
        this.usrType = this.$store.getters.getUsrType();
        if (this.usrType === this.C_MODE_GUEST) {
          this.getEventForGuest(this.eventId);
        } else {
          this.getEvent(this.eventId);
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getEvent(eventId: string) {
    const token = this.$store.getters.getToken();
    return await libAxios.get('event-info/' + eventId, token, {})
    .then((response: {[key: string]: any}) => {
      if (!response) {
        this.isLoading = false;
        this.noData = true;
        return;
      }
      this.setEventData(response);
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async getEventForGuest(eventId: string) {
    return await libAxios.getNoToken('event-list/' + eventId, {})
    .then((response: {[key: string]: any}) => {
      if (!response) {
        this.isLoading = false;
        this.noData = true;
        return;
      }
      this.setEventData(response);
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private setEventData(response: {[key: string]: any}): void {
    this.$store.dispatch('event/setEventAction', response);
      // エントリーだけ別枠で確認
    if (response.entry) {
      this.entryType  = response.entry.entry_type;
      this.entryCount = response.entry.entry_count;
      this.waitCount  = response.entry.wait_count;
    }
    // イベント名を取得,
    this.title = this.$store.getters['event/getEventTitle']();
    this.body = this.$store.getters['event/getEventBody']();
    this.status = this.$store.getters['event/getEventStatus']();
    this.breadItems.push({ text: this.title + '詳細', active: true});
    this.eventSteps = this.$store.getters['event/getSteps']();
    const tmpPlayers = this.$store.getters['event/getPlayers']();

    const maxLen = this.$store.getters['event/getStepsLength']();
    if (maxLen > 0) {
      const tmpStep = this.eventSteps[0];
      if (this.status < this.C_EVENT_HOLD && tmpStep.style === 'S00') {
        for (const key in tmpPlayers) {
          if (tmpPlayers[key].eventStepId === tmpStep.eventStepId) {
            this.eventPlayers.push(tmpPlayers[key]);
          }
        }
      }
    }
    const game = this.$store.getters['event/getGames']();

  }

  private viewGame(key: string): void {
    const tmpGame = this.$store.getters['event/getGame'](key);
    if (tmpGame === null) {
      return;
    }
    this.gameNum = tmpGame.eventGameType;
    this.gameParam = tmpGame;
    this.modalShow = true;
  }

  private hideDetail() {
    this.modalShow = false;
  }
}
