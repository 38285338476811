




































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {EventStep}  from '@/model/class/EventStep';
import draggable    from 'vuedraggable';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue, draggable);

@Component({
  components: {
    draggable,
  },
})
export default class EventSetMove extends Vue {
  @Prop() private stepNum!: number;
  private eventStep!: EventStep;
  private maxSetNum!: number;
  private isFinal!: boolean;
  private isCancel: boolean = false;
  private isSkip: boolean = false;
  private setLimits: number[] = [];
  private movePlayers!: {[key: number]: Array<{id: number, name: string, pos: number}>};
  private options: { group: string, animation: number} = {
    group: 'myGroup',
    animation: 200,
  };

  @Emit('executeStep') private executeStep(stepId: number, param: {[key: string]: any}): void { return; }
  @Emit('cancel') private cancel(): void { return; }

  private created(): void {
    const tmpPlayers = this.$store.getters['event/getPlayers']();
    this.eventStep = this.$store.getters['event/getStep'](this.stepNum);

    this.maxSetNum = this.eventStep.setCount;
    this.isFinal = this.eventStep.isFinal;
    // movePlayers 初期化
    this.movePlayers = {};
    for (let i = 0; i < this.maxSetNum; i++) {
      this.movePlayers[i] = [];
      this.setLimits[i] = this.eventStep.setLimit;
    }


    if (this.stepNum === 0) {
      // 申し込みなんでPASSも削除もなし
    } else {
      this.isCancel = true;
      if (this.isFinal) {
        this.movePlayers[99] = []; // 削除のみ
      } else {
        this.movePlayers[99] = [];
        this.movePlayers[100] = [];
        this.isSkip = true;
      }
    }

    Object.keys(tmpPlayers).map((str: string) => {
      const key = parseInt(str, 10);
      if (tmpPlayers[key].eventStepId === this.eventStep.eventStepId) {
        const setNum = tmpPlayers[key].setNumber;
        if (tmpPlayers[key].setOrder < 0) {
          this.movePlayers[setNum].push({
            id: tmpPlayers[key].eventPlayerId,
            name: tmpPlayers[key].usrNameShort + '(' + tmpPlayers[key].usrClass + ')' + tmpPlayers[key].deptNameShort,
            pos: tmpPlayers[key].setOrder,
          });
        } else {
          this.setLimits[setNum]--;
        }
      }
    });

    for (let i = 0; i < this.maxSetNum; i++) {
      this.movePlayers[i].sort((a, b) => {
        if (a.pos > b.pos) {
          return 1;
        } else {
          return -1;
        }
      });
    }
  }

  // STEP0の機能
  private plusSet(): void {
    this.movePlayers[this.maxSetNum] = [];
    this.maxSetNum++;
    this.$forceUpdate();
  }

  private onEnd(setNum: number): void {
    this.$forceUpdate();
  }

  // Step0の場合とわける。
  private saveOrder(): void {
    const orderData: string[] = [];
    for (let i = 0; i < this.maxSetNum; i++) {
      const setData: any[] = this.movePlayers[i].map((value) => value.id);
      const tmpLen = setData.length;
      if (this.stepNum !== 0 && tmpLen > this.setLimits[i]) {
        alert((i + 1) + '組がMAX人数を超えています');
        return;
      }
      orderData.push(i + '_' + setData.join(','));
    }

    if (this.stepNum === 0) {
      // 申し込みなんでPASSも削除もなし
    } else {
      const setData99: any[] = this.movePlayers[99].map((value) => value.id);
      orderData.push('99_' + setData99.join(','));
      if (!this.isFinal) {
        const setData100: any[] = this.movePlayers[100].map((value) => value.id);
        orderData.push('100_' + setData100.join(','));
      }
    }

    const postParam = {
      command: 'move',
      ids: orderData.join(';'),
    };
    this.executeStep(this.eventStep.eventStepId , postParam);
  }
}
