import {DartsParam}       from '@/model/interface/DartsParam';
import {MatchPlayerParam} from '@/model/interface/MatchPlayerParam';
import {PlaceParam}       from '@/model/interface/PlaceParam';
import libAxios           from '@/mixin/LibAxios';

export default {
  namespaced: true,
  state: {
    gameId: 0,
    gameType: 0,
    gameStatus: 0,
    gameMode: 0,
    place: {id: 0, name: '', tableNum: 0},
    player1: {id: 0, name: '', race_to: 0},
    player2: {id: 0, name: '', race_to: 0},
    config: {},
    startDateTime: '',
    first: 0,
    winner: 0,
    maxRound: 20,
    scoreP1: 0,
    scoreP2: 0,
    param: '',
    timeSpan: 0,
    scorp: 0,
    checked: 0,
  } as DartsParam,
  getters: {
    getToken: (state: DartsParam, getters: any, RootState: any) => (): string => {
      return RootState.token;
    },
    getGameId: (state: DartsParam, getters: any) => (): number => {
      return state.gameId;
    },
    getGameType: (state: DartsParam, getters: any) => (): number => {
      return state.gameType;
    },
    getGameStatus: (state: DartsParam, getters: any) => (): number => {
      return state.gameStatus;
    },
    getGameMode: (state: DartsParam, getters: any) => (): number => {
      return state.gameMode;
    },
    getPlace: (state: DartsParam, getters: any) => (): PlaceParam => {
      return state.place;
    },
    getPlayer1: (state: DartsParam, getters: any) => (): MatchPlayerParam => {
      return state.player1;
    },
    getPlayer2: (state: DartsParam, getters: any) => (): MatchPlayerParam => {
      return state.player2;
    },
    getWinner: (state: DartsParam, getters: any) => (): number => {
      return state.winner;
    },
    getConfig: (state: DartsParam, getters: any) => (): {[key: string]: number} => {
      return state.config;
    },
    getConfigParam: (state: DartsParam, getters: any) => (key: string): number => {
      return state.config[key];
    },
    getStartDateTime: (state: DartsParam, getters: any) => (): string => {
      return state.startDateTime;
    },
    getFirst: (state: DartsParam, getters: any) => (): number => {
      return state.first;
    },
    getMaxRound: (state: DartsParam, getters: any) => (): number => {
      return state.maxRound;
    },
    getPlayer1Score: (state: DartsParam, getters: any) => (): number => {
      return state.scoreP1;
    },
    getPlayer2Score: (state: DartsParam, getters: any) => (): number => {
      return state.scoreP2;
    },
    getParam: (state: DartsParam, getters: any) => (): string => {
      return state.param;
    },
    getTimeSpan: (state: DartsParam, getters: any) => (): number => {
      return state.timeSpan;
    },
    // 詳細
    getPlayer1Name: (state: DartsParam, getters: any) => (): string => {
      return state.player1.name;
    },
    getPlayer1RaceTo: (state: DartsParam, getters: any) => (): number => {
      return state.player1.race_to;
    },
    getPlayer2Name: (state: DartsParam, getters: any) => (): string => {
      return state.player2.name;
    },
    getPlayer2RaceTo: (state: DartsParam, getters: any) => (): number => {
      return state.player2.race_to;
    },
  },
  mutations: {
    setGameId(state: DartsParam, payload: number) {
      state.gameId = payload;
    },
    setConfig(state: DartsParam, payload: {[key: string]: number}) {
      state.config = payload;
    },
    setConfigParam(state: DartsParam, payload: {key: string, val: number}) {
      state.config[payload.key] = payload.val;
    },
    addPlayer1RaceTo(state: DartsParam, payload: number) {
      const tmpRaceTo = state.player1.race_to + payload;
      if (tmpRaceTo > 0) {
        state.player1.race_to = tmpRaceTo;
      }
    },
    setMaxRound(state: DartsParam, payload: number) {
      state.maxRound = payload;
    },
    setPlayer1Score(state: DartsParam, payload: number) {
      state.scoreP1 = payload;
    },
    setPlayer2Score(state: DartsParam, payload: number) {
      state.scoreP2 = payload;
    },
    setParam(state: DartsParam, payload: string) {
      state.param = payload;
    },
    addPlayer2RaceTo(state: DartsParam, payload: number) {
      const tmpRaceTo = state.player2.race_to + payload;
      if (tmpRaceTo > 0) {
        state.player2.race_to = tmpRaceTo;
      }
    },
    setPlayer1RaceTo(state: DartsParam, payload: number) {
      state.player1.race_to = payload;
    },
    setPlayer2RaceTo(state: DartsParam, payload: number) {
      state.player2.race_to = payload;
    },
    clearData(state: DartsParam) {
      state.gameId = 0;
      state.gameType = 0;
      state.gameStatus = 0;
      state.gameMode = 0;
      state.place = {id: 0, name: '', tableNum: 0};
      state.player1 = {id: 0, name: '', race_to: 0};
      state.player2 = {id: 0, name: '', race_to: 0};
      state.config = {};
      state.startDateTime = '';
      state.first = 0;
      state.maxRound = 20;
      state.scoreP1 = 0;
      state.scoreP2 = 0;
      state.param = '';
      state.scorp = 0;
      state.checked = 0;
    },
    retryData(state: DartsParam) {
      state.gameId = 0;
      state.gameStatus = 0;
      state.gameMode = 0;
      state.startDateTime = '';
      state.first = 0;
    },
    setResumeData(state: DartsParam, payload: {[key: string]: any}) {
      state.gameId     = payload.gameId;
      state.gameType   = payload.gameType;
      state.gameStatus = payload.gameStatus;
      state.gameMode   = payload.gameMode;
      state.place      = payload.place;
      state.player1    = payload.player1;
      state.player2    = payload.player2;
      state.config     = payload.config;
      state.startDateTime = payload.startDateTime;
      state.first      = payload.first;
      state.maxRound   = payload.maxRound;
      state.scoreP1    = payload.scoreP1;
      state.scoreP2    = payload.scoreP2;
      state.param      = payload.param;
      state.scorp      = payload.scorp;
      state.checked    = payload.checked;
    },
    initData(state: DartsParam, payload: {[key: string]: any}) {
      state.gameType   = payload.gameType;
      state.gameStatus = 0; // C_STATUS_INIT
      state.place      = payload.place;
      state.player1    = payload.player1;
      state.player2    = payload.player2;
      state.checked    = payload.checked;
    },
    initGuest(state: DartsParam, payload: {[key: string]: any}) {
      state.gameType   = payload.gameType;
      state.gameStatus = 0; // C_STATUS_INIT
      state.player1    = payload.player1;
      state.player2    = payload.player2;
    },
    readyData(state: DartsParam) {
      state.gameStatus = 2; // C_STATUS_READY
    },
    startData(state: DartsParam, payload: {[key: string]: any}) {
      state.gameStatus = 3; // C_STATUS_PLAY
      state.startDateTime = payload.startDateTime;
      state.first = payload.first;
    },
    endData(state: DartsParam) {
      state.gameStatus = 6; // C_STATUS_END
    },
  },
  actions: {
    async initAction(context: any, param: {
      gameType: number,
      place: PlaceParam,
      player1: MatchPlayerParam,
      player2: MatchPlayerParam,
      checked: number,
    }) {
      context.commit('clearData');
      context.commit('setGameId', 0);
      context.commit('initData', param);
      /*
      // 初期化
      window.localStorage.removeItem('currentGame');
      context.commit('clearData');
      // context.commit('clearPostData');
      const token = context.getters.getToken();
      const postParam = {
        g_type:   param.gameType,
        d_id:     param.place.id,
        d_name:   param.place.name,
        d_tbl:    param.place.tableNum,
        u_id_1:   param.player1.id,
        u_name_1: param.player1.name,
        u_id_2:   param.player2.id,
        u_name_2: param.player2.name,
        checked:  param.checked,
      };
      return await libAxios.post('game-data/match', token, postParam)
      .then((data: {[key: string]: any}) => {
        context.commit('setGameId', data.g_id);
        context.commit('initData', param);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
      */
    },
    async retryAction(context: any) {
      const token = context.getters.getToken();
      const postParam = {
        g_type:   context.state.gameType,
        d_id:     context.state.place.id,
        d_name:   context.state.place.name,
        d_tbl:    context.state.place.tableNum,
        u_id_1:   context.state.player1.id,
        u_name_1: context.state.player1.name,
        u_id_2:   context.state.player2.id,
        u_name_2: context.state.player2.name,
        checked:  context.state.checked,
      };
      return await libAxios.post('game-data/match', token, postParam)
      .then((data: {[key: string]: any}) => {
        context.commit('setGameId', data.g_id);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
        return true;
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    initGuestAction(context: any, param: {
      gameType: number,
      player1: MatchPlayerParam,
      player2: MatchPlayerParam,
    }) {
      context.commit('clearData');
      // context.commit('clearPostData');
      context.commit('initGuest', param);
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
    },
    retryGuestAction(context: any) {
      // context.commit('clearPostData');
      context.commit('retryData');
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
    },
    // postしない
    readyAction(context: any) {
      context.commit('readyData');
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
    },
    toGameAction(context: any) {
      const token = context.getters.getToken();
      const postParam = {
        g_status:  context.state.gameStatus,
        race_to_1: context.state.player1.race_to,
        race_to_2: context.state.player2.race_to,
        config:    JSON.stringify(context.state.config),
        scorp:     context.state.config.isOpen,
      };
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      if (context.state.gameId !== 0) {
        libAxios.asyncPut('game-data/match/' + context.state.gameId, token, postParam);
      }
    },
    startAction(context: any, param: {
      startDateTime: string,
      first: number,
    }) {
      // 同期は不要
      context.commit('startData', param);
      const token = context.getters.getToken();
      const postParam = {
        g_status:  context.state.gameStatus,
        start_dt:  param.startDateTime,
        race_to_1: context.state.player1.race_to,
        race_to_2: context.state.player2.race_to,
        config:    JSON.stringify(context.state.config),
        scorp:     context.state.config.isOpen,
        first:     param.first,
      };
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      if (context.state.gameId !== 0) {
        libAxios.asyncPut('game-data/match/' + context.state.gameId, token, postParam);
      }
    },
    async finAction(context: any, isFin: boolean) {
      const token = context.getters.getToken();
      const postParam = {
        g_status:  6,
      };
      return await libAxios.put('game-data/match/' + context.state.gameId, token, postParam)
      .then(() => {
        window.localStorage.removeItem('currentGame');
        // context.commit('clearPostData');
        if (isFin) {
          context.commit('clearData');
        } else {
          context.commit('retryData');
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },

    // TODO END
    // 個別
    setConfigAction(context: any, configData: {[key: string]: number}) {
      context.commit('setConfig', configData);
    },
    setConfigParamAction(context: any, configParam: {key: string, val: number}) {
      context.commit('setConfigParam', configParam);
    },
    setMaxRoundAction(context: any, mxRound: number) {
      context.commit('setMaxRound', mxRound);
    },
    // 共通
    addRaceToAction(context: any, param: {isFirst: boolean, addPoint: number}) {
      if (param.isFirst) {
        context.commit('addPlayer1RaceTo', param.addPoint);
      } else {
        context.commit('addPlayer2RaceTo', param.addPoint);
      }
    },
    setRaceToAction(context: any, param: {isFirst: boolean, point: number}) {
      if (param.isFirst) {
        context.commit('setPlayer1RaceTo', param.point);
      } else {
        context.commit('setPlayer2RaceTo', param.point);
      }
    },
    addScoreAction(context: any, param: {isFirst: boolean, addPoint: number}) {
      if (param.isFirst) {
        context.commit('addPlayer1Score', param.addPoint);
      } else {
        context.commit('addPlayer2Score', param.addPoint);
      }
    },
    setScoreAction(context: any, param: {isFirst: boolean, point: number}) {
      if (param.isFirst) {
        context.commit('setPlayer1Score', param.point);
      } else {
        context.commit('setPlayer2Score', param.point);
      }
    },
    addResultAction(context: any, param: {isFirst: boolean, addPoint: number, isRunOut: boolean}) {
      if (param.isFirst) {
        context.commit('addPlayer1Score', param.addPoint);
        if (param.isRunOut) {
          context.commit('addGameResult', 'A');
        } else {
          context.commit('addGameResult', 'a');
        }
      } else {
        context.commit('addPlayer2Score', param.addPoint);
        if (param.isRunOut) {
          context.commit('addGameResult', 'B');
        } else {
          context.commit('addGameResult', 'b');
        }
      }
    },
    setWinnerAction(context: any, winner: number) {
      context.commit('setGameWinner', winner);
    },
    setWinnerInningAction(context: any, param: {winner: number, player1Inning: number, player2Inning: number}) {
      context.commit('setGameWinner', param.winner);
      context.commit('setTurnAvr', {p1Inning: param.player1Inning, p2Inning: param.player2Inning});
    },
    destroyAction(context: any) {
      context.commit('clearData');
      // TODO NET
      window.localStorage.removeItem('currentGame');
    },
    clearDataAction(context: any) {
      // context.commit('clearPostData');
    },
    resumeAction(context: any, resumeData: {[key: string]: any}) {
      context.commit('setResumeData', resumeData);
    },
  },
};
