
































































































































































































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import Game10BallBall   from '@/components/score/match/Game10BallBall.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Darts01Play extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  private shot1st: string = '--';
  private shot2nd: string = '--';
  private shot3rd: string = '--';
  private bk1st: string = 'outline-dark';
  private bk2nd: string = 'outline-dark';
  private bk3rd: string = 'outline-dark';
  private shotScore: number[] = [0, 0, 0];
  private shotScale: number[] = [0, 0, 0];
  private shotSum: number = 0;
  private currentScore: number = -1;
  private roundsData1: Array<{score: number[], scale: number[], sum: number, val: number}> = [];
  private roundsData2: Array<{score: number[], scale: number[], sum: number, val: number}> = [];
  private pos: number = 0;

  @Emit('actionType') private toParent(cmd: string): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private bntColor(num: number, scale: number): string {
    const point = num * scale;
    if (this.currentScore === point) {
      return 'success';
    } else if (this.currentScore > point) {
      if (scale === 1) {
        return 'primary';
      } else if (scale === 2) {
        return 'warning';
      } else if (scale === 3) {
        return 'danger';
      }
    } else {
      if (scale === 1) {
        return 'outline-primary';
      } else if (scale === 2) {
        return 'outline-warning';
      } else if (scale === 3) {
        return 'outline-danger';
      }
    }
    return '';
  }

  private created(): void {
    if (this.isFirst) {
      this.currentScore = this.$store.getters['darts/getPlayer1RaceTo']();
    } else {
      this.currentScore = this.$store.getters['darts/getPlayer2RaceTo']();
    }
  }

  private undo(): void {
    if (this.roundsData1.length === 0 && this.roundsData2.length === 0) {
      this.toParent('restart');
      return;
    }
    this.viewInit();
    if (this.isFirst) {
      this.roundsData2.pop();
    } else {
      this.roundsData1.pop();
    }
    let tmpScore2 = this.$store.getters['darts/getPlayer2RaceTo']();
    for (const roundData of this.roundsData2) {
      if (roundData.val === 1) {
        tmpScore2 -= roundData.sum;
      }
    }
    this.$store.dispatch('darts/setScoreAction', {isFirst: false, point: tmpScore2});

    let tmpScore1 = this.$store.getters['darts/getPlayer1RaceTo']();
    for (const roundData of this.roundsData1) {
      if (roundData.val === 1) {
        tmpScore1 -= roundData.sum;
      }
    }
    this.$store.dispatch('darts/setScoreAction', {isFirst: true, point: tmpScore1});

    if (this.isFirst) {
      this.currentScore = tmpScore2; // プレイヤーが変わるので1なら2
    } else {
      this.currentScore = tmpScore1;
    }
    this.viewInit();
    this.toParent('change');
  }

  private fin(): void {
    this.toParent('fin');
  }

  private retry(): void {
    this.viewInit();
    this.toParent('retry');
  }

  private viewInit() {
    this.shotScore = [0, 0, 0];
    this.shotScale = [0, 0, 0];
    this.shotSum = 0;
    this.shot1st = this.shot2nd = this.shot3rd = '--';
    this.bk1st = this.bk2nd = this.bk3rd = 'outline-dark';
    this.pos = 0;
  }

  private changePlayer() {
    // true なら現在１
    if (this.isFirst) {
      this.roundsData1.push({score: this.shotScore, scale: this.shotScale, sum: this.shotSum, val: 1});
      this.currentScore = this.$store.getters['darts/getPlayer2RaceTo']();
    } else {
      this.roundsData2.push({score: this.shotScore, scale: this.shotScale, sum: this.shotSum, val: 1});
      this.currentScore = this.$store.getters['darts/getPlayer1RaceTo']();
    }
    this.viewInit();
    this.toParent('change');
  }

  private calcShotSum() {
    let total = 0;
    for (let i = 0; i < 3; i++) {
      total += this.shotScore[i] * this.shotScale[i];
    }
    this.shotSum = total;

    // トータルチェック
    if (this.isFirst) {
      this.currentScore = this.$store.getters['darts/getPlayer1RaceTo']();
      for (const roundData of this.roundsData1) {
        if (roundData.val === 1) {
          this.currentScore -= roundData.sum;
        }
      }
      this.currentScore -= this.shotSum;
      this.$store.dispatch('darts/setScoreAction', {isFirst: true, point: this.currentScore});
    } else {
      this.currentScore = this.$store.getters['darts/getPlayer2RaceTo']();
      for (const roundData of this.roundsData2) {
        if (roundData.val === 1) {
          this.currentScore -= roundData.sum;
        }
      }
      this.currentScore -= this.shotSum;
      this.$store.dispatch('darts/setScoreAction', {isFirst: false, point: this.currentScore});
    }
  }

  private shoot(point: number, scale: number): void {
    if (this.pos > 2) {
      return;
    }
    this.shotScore[this.pos] = point;
    this.shotScale[this.pos] = scale;
    this.shotScoreNum(this.pos);
    this.pos++;
    while (this.pos < 3 && this.shotScale[this.pos] !== 0) {
      this.pos++;
    }
  }

  private shotScoreNum(num: number): void {
    let val = '';
    if (this.shotScale[num] === 0) {
      val = '--';
    } else {
      if (this.shotScore[num] === 0) {
        val = 'OB';
      } else {
        if (this.shotScore[num] === 25) {
          val = String(50);
        } else {
          val = String(this.shotScore[num]);
        }
      }
    }

    let bk = 'outline-dark';
    switch (this.shotScale[num]) {
      case 1:
        if (this.shotScore[num] === 0) {
          // 色なし
        } else {
          bk = 'primary';
        }
        break;
      case 2:
        bk = 'warning';
        break;
      case 3:
        bk = 'danger';
        break;
    }

    if (num === 0) {
      this.shot1st = val;
      this.bk1st = bk;
    } else if (num === 1) {
      this.shot2nd = val;
      this.bk2nd = bk;
    } else if (num === 2) {
      this.shot3rd = val;
      this.bk3rd = bk;
    }
    this.calcShotSum();
  }

  private clear(num: number) {
    this.shotScore[num] = 0;
    this.shotScale[num] = 0;
    this.pos = num;
    switch (num) {
      case 0:
        this.shot1st = '--';
        this.bk1st = 'outline-dark';
        break;
      case 1:
        this.shot2nd = '--';
        this.bk2nd = 'outline-dark';
        break;
      case 2:
        this.shot3rd = '--';
        this.bk3rd = 'outline-dark';
        break;
    }
    this.calcShotSum();
  }
}
