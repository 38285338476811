

































import {Component, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class BowlardConfig extends Vue {
  private config!: {[key: string]: number};
  private simpleVal = [
    {text: 'なし', value: 0},
    {text: 'あり', value: 1},
  ];

  private gameTypeVal = [
    {text: 'Type1', value: 1},
    {text: 'Type2', value: 2},
    {text: 'Type3', value: 3},
    {text: 'Type4', value: 4},
  ];

  private get _IsOpen(): number {
    return this.config.isOpen;
  }

  private set _IsOpen(value: number) {
    this.$store.dispatch('solo/setConfigParamAction', {key: 'isOpen', val: value});
  }

  private get _GameType(): number {
    return this.config.gameType;
  }

  private set _GameType(value: number) {
    this.$store.dispatch('solo/setConfigParamAction', {key: 'gameType', val: value});
  }

  private created(): void {
    this.config = this.$store.getters['solo/getConfig']();
  }
}
