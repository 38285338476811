




































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {PlaceParam}       from '@/model/interface/PlaceParam';
import {PlayerParameter}  from '@/model/interface/PlayerParameter';
import {MultiPlayerParam} from '@/model/interface/MultiPlayerParam';
import PlaceTablePanel    from '@/components/score/common/PlaceTablePanel.vue';
import PointPlayer        from '@/components/score/multi/PointPlayer.vue';
import DeptPlayerPanel    from '@/components/score/common/DeptPlayerPanel.vue';
import PointConfig        from '@/components/score/multi/PointConfig.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    PlaceTablePanel,
    PointPlayer,
    PointConfig,
    DeptPlayerPanel,
  },
})
export default class PointSetting extends Vue {
  private usrType!: number;
  private placeTab: number = 0;
  private place: PlaceParam = {id: 0, name: '未選択', tableNum: 0};
  private playerTab: number = 0;
  private PlayerParam: PlayerParameter = {id: 0, name: '', nameShort: ''};
  private players!: MultiPlayerParam[];
  private playerLen!: number;
  private modalShow: boolean = false;
  private playerIsEdit: boolean  = false;
  private playerEditNum: number = -1;

  @Emit('startGame') private startGame(players: MultiPlayerParam[], place: PlaceParam): void { return; }

  private created(): void {
    this.usrType = this.$store.getters.getUsrType();
    this.players = this.$store.getters['multi/getPlayers']();
    this.playerLen = this.players.length;
  }

  private setPlace(placeTab: number, place: PlaceParam): void {
    this.placeTab = placeTab;
    this.place = place;
  }

  private openModalSetPlayer(num: number): void {
    this.playerIsEdit = false;
    this.playerEditNum = num;
    this.modalShow = true;
  }

  private updown(num: number, moveType: number): void {
    if (moveType === 0) {
      this.players.splice(num, 1);
      this.playerLen = this.players.length;
    } else if (moveType === 1) {
      this.players.splice(num, 2, this.players[num + 1], this.players[num]);
    } else if (moveType === -1) {
      this.players.splice(num - 1, 2, this.players[num], this.players[num - 1]);
    }
    this.$forceUpdate();
  }

  private addDefaultPlayer(): void {
    this.players.push( JSON.parse(this.C_PARAM_POINT_PLAYER) );
    this.playerLen = this.players.length;
    this.$forceUpdate();
  }

  private setPlayer(playerTab: number, player: PlayerParameter): void {
    this.playerTab = playerTab;
    this.PlayerParam = player;
    this.playerIsEdit = true;
  }

  private addPlayer(): void {
    if (this.playerIsEdit) {
      if (this.playerTab === 0) {
        if (this.PlayerParam.id === 0) {
          return;
        }
        this.players[this.playerEditNum].isFix = false;
      } else if (this.playerTab === 1) {
        if (this.PlayerParam.id === 0) {
          return;
        }
        this.players[this.playerEditNum].isFix = false;
      } else if (this.playerTab === 2) {
        this.PlayerParam = JSON.parse(this.C_PARAM_POINT_PLAYER);
        this.players[this.playerEditNum].isFix = false;
      }
      this.players[this.playerEditNum].id = this.PlayerParam.id;
      this.players[this.playerEditNum].name = this.PlayerParam.name;
    }
  }

  private start(isRandom: boolean): void {
    // place check
    if (this.usrType !== this.C_MODE_GUEST) {
      if (this.placeTab === 0) {
        if (this.place.id === 0 || this.place.tableNum === 0) {
          alert('場所とテーブル番号を入力してください');
          return;
        }
      }
    }
    let totalPoint = 0;
    for (const player of this.players) {
      if (player.name === '') {
        alert('名前を入力してください');
        return;
      }
      totalPoint += player.point;
    }
    if (totalPoint !== 0) {
      alert('+-があっていません');
      return;
    }

    // イニングチェック
    const inningLen = this.$store.getters['multi/getConfigParam']('limitInning');
    if (inningLen < 5 || 10 < inningLen) {
      alert('Inning制限は5～10の間で入力してください');
      return;
    }

    if (isRandom) {
      this.players = this._shuffle(this.players);
    }

    this.startGame(this.players, this.place);
  }
}
