



















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game14OneConfig extends Vue {
  private simpleVal = [
    {text: 'なし', value: 0},
    {text: 'あり', value: 1},
  ];
  private scoreLvVal = [
    {text: 'Lv1', value: 1},
    {text: 'Lv2', value: 2},
  ];

  private limitTypeVal = [
    {text: 'なし', value: 0},
    {text: 'Inning', value: 1},
    {text: 'Time', value: 2},
  ];

  private judgeTypeVal = [
    {text: 'Drow', value: 1},
    {text: 'Judge', value: 2},
    {text: 'Manual', value: 3},
  ];

  private selectUsrVal = [
    {text: 'なし', value: 0},
    {text: 'P1', value: 1},
    {text: 'P2', value: 2},
    {text: '両方', value: 3},
  ];

  private config: {[key: string]: number} = this.$store.getters['match/getConfig']();

  private get isDisabled(): boolean {
    return (this.config.isEdit === 0);
  }

  private get _ScoreLv(): number {
    return this.config.scoreLv;
  }

  private set _ScoreLv(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'scoreLv', val: value});
  }

  private get _CallShot(): number {
    return this.config.callShot;
  }

  private set _CallShot(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'callShot', val: value});
  }

  private get _LimitType(): number {
    return this.config.limitType;
  }

  private set _LimitType(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'limitType', val: value});
  }

  private get _JudgeType(): number {
    return this.config.judgeType;
  }

  private set _JudgeType(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'judgeType', val: value});
  }

  private get _LimitInning(): number {
    return this.config.limitInning;
  }

  private set _LimitInning(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'limitInning', val: value});
  }

  private get _LimitTime(): number {
    return this.config.limitTime;
  }

  private set _LimitTime(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'limitTime', val: value});
  }

  private get _BreakFree(): number {
    return this.config.breakFree;
  }

  private set _BreakFree(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'breakFree', val: value});
  }

  private get _FoulFree(): number {
    return this.config.foulFree;
  }

  private set _FoulFree(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'foulFree', val: value});
  }
}
