import { render, staticRenderFns } from "./PointTable.vue?vue&type=template&id=19435859&scoped=true&"
import script from "./PointTable.vue?vue&type=script&lang=ts&"
export * from "./PointTable.vue?vue&type=script&lang=ts&"
import style0 from "./PointTable.vue?vue&type=style&index=0&id=19435859&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19435859",
  null
  
)

export default component.exports