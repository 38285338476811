
























































import {Component, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue';
import libAxios        from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class RegularEvent extends Vue {
  private connection: boolean = false;
  private usrType!: number;
  private regularData: Array<{[key: string]: any}> = [];
  private modalRegularInfo: boolean = false;
  private currentTitle: string = '';
  private currentBody: string = '';
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: '定例イベント一覧', active: true},
  ];

  private created(): void {
    try {
      const serachParam: {[key: string]: any} = {};
      this._checkLogin().
      then((isLogin: boolean) => {
        this._getResumeData(0); // Gameではないので戻り値は無視
        this.usrType = this.$store.getters.getUsrType();
        if (this.usrType === this.C_MODE_GUEST) {
          this.getRegularEventsForGuest(serachParam);
        } else {
          this.getRegularEvents(serachParam);
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });

    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getRegularEvents(serachParam: {[key: string]: any}) {
    this.connection = true;
    const token = this.$store.getters.getToken();
    return await libAxios.get('regular-info', token, serachParam)
    .then((param: {[key: string]: any}) => {
      this.regularData = param.data;
      this.connection = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async getRegularEventsForGuest(serachParam: {[key: string]: any}) {
    this.connection = true;
    return await libAxios.getNoToken('regular-list', serachParam)
    .then((param: {[key: string]: any}) => {
      this.regularData = param.data;
      this.connection = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private showDetail(index: number) {
    this.currentTitle = this.regularData[index].re_name + ' 説明';
    this.currentBody = this.regularData[index].re_list_lead;
    this.modalRegularInfo = true;
  }
}
