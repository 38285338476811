








































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Header        from '@/components/common/Header.vue';
import MessageDetail from '@/components/info/MessageDetail.vue';
import libAxios      from '@/mixin/LibAxios';
import BootstrapVue  from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    MessageDetail,
  },
})
export default class Message extends Vue {
  private connection: boolean = false;
  private usrType!: number;
  private messageList: Array<{[key: string]: any}> = [];
  private currentMsg: {[key: string]: any} = {};
  private currentNum: number = -1;
  private modalMsgInfo: boolean = false;
  private currentTitle: string = '';
  private targetID: number = 0;

   private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.usrType = this.$store.getters.getUsrType();
          // this.$route.path
          if (this.$route.params.id) {
            const path = this.$route.path;
            if (this.$route.path.substr(0, 14) === '/message/event') {
              this.breadItems.push({ text: 'イベント一覧', to: { name: 'event'}});
              this.targetID = parseInt(this.$route.params.id, 10);
              const searchParam = {
                ref_type: 1,
                ref_id: this.targetID,
              };
              this.getMessageList(searchParam);
            }
          } else {
            this.breadItems.push({ text: 'メッセージ一覧', active: true});
            this.getMessageList({});
          }
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getMessageList(searchParam: {[key: string]: any}) {
    this.connection = true;
    const token = this.$store.getters.getToken();
    libAxios.get('message', token, searchParam)
    .then((param: {[key: string]: any}) => {
      this.messageList = param.data;
      // 自動で開く
      if (param.cnt === 1 && this.targetID !== 0) {
        this.breadItems.push({ text: this.messageList[0].title + '参加確認', active: true});
        if (this.messageList[0].u_id) {
          this.showMsg(0);
        } else {
          this.openMsg(0);
        }
      }
      this.connection = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private showMsg(num: number) {
    this.currentMsg = this.messageList[num];
    this.currentTitle = this.currentMsg.title;
    this.modalMsgInfo = true;
  }

  private async openMsg(num: number) {
    this.currentNum = num;
    const postParam = {
      mr_type: this.messageList[num].ref_type,
      ref_id: this.messageList[num].ref_id,
      m_id: this.messageList[num].m_id,
    };

    const token = this.$store.getters.getToken();
    return await libAxios.post('reply', token, postParam)
    .then((param: {[key: string]: any}) => {
      Object.assign(this.messageList[num], param);
      this.showMsg(num);
    }).catch((e: Error) => {
      this.$router.push({name: 'error'});
    });
  }

  private refreshView(data: {[key: string]: any}, isRefresh: boolean): void {
    this.modalMsgInfo = false;
    if (isRefresh) {
      this.getMessageList({});
      return;
    }
    Object.assign(this.messageList[this.currentNum], data);
  }
}
