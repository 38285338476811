import {EventConfigParam}   from '@/model/interface/EventConfigParam';

export class EventConfig implements EventConfigParam {
  public eventConfigId!: number;
  public eventConfigType!: number;
  public eventConfigStatus!: number;
  public eventConfigMode!: number;
  public usrId!: number;
  public title!: string;
  public format!: string;
  public editId!: number;
  public editDateTime!: string;

  public setByApi(param: {[key: string]: any}): void {
    this.eventConfigId     = param.ec_id;
    this.eventConfigType   = param.ec_type;
    this.eventConfigStatus = param.ec_status;
    this.eventConfigMode   = param.ec_mode;
    this.usrId             = param.ec_u_id;
    this.title             = param.ec_title;
    this.format            = param.ec_format;
    this.editId            = param.edit_id;
    this.editDateTime      = param.edit_dt;
  }
}
