

























































import {Component, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue'; // @ is an alias to /src
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class PasswordForget extends Vue {
  private loginId: string = '';
  private mail: string = '';
  private errMail: string = '';
  private isSend: boolean = false;

  private connection: boolean = false;
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: 'パスワード再発行依頼', active: true},
  ];

  private get canSend(): boolean {
    if (this.loginId !== '' && this.mail !== '') {
      return true;
    }
    return false;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          // ログインしているのでトップに飛ばす
          this.$router.push({name: 'top'});
        } else {
          // なにもしない
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async sendMail() {
    this.connection = true;
    this.errMail = '';
    const postParam = {
      login_id: this.loginId,
      mail: this.mail,
    };
    return await libAxios.postNoToken('password-forget', postParam)
    .then((param: {[key: string]: any}) => {
      this.isSend = true;
      this.connection = false;
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        for (const err of Object.keys(tmpJson.validate)) {
          switch (err) {
            case 'mail':
              this.errMail = tmpJson.validate[err];
              break;
          }
        }
        this.connection = false;
      } else {
        // this.$router.push({name: 'error'});
      }
    });
  }
}
