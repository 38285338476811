






























































































import {Component, Vue} from 'vue-property-decorator';
import Header from '@/components/common/Header.vue'; // @ is an alias to /src
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class Contact extends Vue {
  private subjectType: number = 0;
  private subject: string = '';
  private contactName: string = '';
  private contactMail: string = '';
  private genre: string = '';
  private body: string = '';
  private isLogin: boolean = false;
  private isSend: boolean = false;
  private connection: boolean = false;
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: '問い合わせ', active: true},
  ];

  private get subjectTypeState(): boolean {
    return (this.subjectType !== 0);
  }

  private get subjectState(): boolean {
    return (this.subject !== '');
  }

  private get bodyState(): boolean {
    return (this.body !== '');
  }

  private get canSend(): boolean {
    return (this.subjectTypeState && this.subjectState && this.bodyState);
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        this.isLogin = isLogin;
        // 全開送信時刻を取得
        const diff = (new Date()).getTime() - this.$store.getters.getContactTime();
        if (diff < (30 * 60 * 1000)) {
          alert('連続で送信できません。\nしばらく時間をおいてください。');
          this.isSend = true;
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async sendMsg() {
    this.connection = true;
    const postParam: {[key: string]: any} = {
      type:    this.subjectType,
      subject: this.subject,
      name:    this.contactName,
      mail:    this.contactMail,
      genre:   this.genre,
      body:    this.body,
    };

    const token = this.$store.getters.getToken();
    return await libAxios.post('contact', token, postParam)
    .then((data: {[key: string]: any}) => {
      this.$store.dispatch('contactTimeAction', (new Date()).getTime());
      alert('送信しました');
      this.connection = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
