import {EventStepParam}   from '@/model/interface/EventStepParam';

export class EventStep implements EventStepParam {
  public eventStepId!: number;
  public eventStepType!: number;
  public eventStepStatus!: number;
  public eventStepMode!: number;
  public eventId!: number;
  public eventStepNum!: number;
  public isFinal!: boolean;
  public style!: string;
  public scorp!: number;
  public name!: string;
  public entryCount!: number;
  public overCount!: number;
  public setCount!: number;
  public setLimit!: number;
  public winNum!: number;
  public format!: number;
  public comment!: string;
  public deleteFlag!: boolean;
  public editId!: number;
  public editDateTime!: string;

  public setByApi(param: {[key: string]: any}): void {
    this.eventStepId     = param.es_id;
    this.eventStepType   = param.es_type;
    this.eventStepStatus = param.es_status;
    this.eventStepMode   = param.es_mode;
    this.eventId         = param.e_id;
    this.eventStepNum    = param.es_num;
    this.isFinal         = param.is_final;
    this.style           = param.style;
    this.scorp           = param.scorp;
    this.name            = param.name;
    this.entryCount      = param.entry_cnt;
    this.overCount       = param.over_cnt;
    this.setCount        = param.set_cnt;
    this.setLimit        = param.set_lim;
    this.winNum          = param.win_num;
    this.format          = param.format;
    this.comment         = param.comment;
    this.deleteFlag      = param.del_flg;
    this.editId          = param.edit_id;
    this.editDateTime    = param.edit_dt;
  }
}
