import { render, staticRenderFns } from "./PointSetting.vue?vue&type=template&id=14b298cf&scoped=true&"
import script from "./PointSetting.vue?vue&type=script&lang=ts&"
export * from "./PointSetting.vue?vue&type=script&lang=ts&"
import style0 from "./PointSetting.vue?vue&type=style&index=0&id=14b298cf&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b298cf",
  null
  
)

export default component.exports