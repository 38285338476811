















































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {Profile}   from '@/model/class/Profile';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class ProfileData extends Vue {
  @Prop() private profile!: Profile;
  @Prop() private errorArray!: string[];
  private name1: string = '';
  private name2: string = '';
  private kana1: string = '';
  private kana2: string = '';
  private pubName: string = '';
  private pubNameShort: string = '';
  private pubClass: string = '';
  private priName: string = '';
  private priNameShort: string = '';
  private priClass: string = '';
  private birthDayY: number = 0;
  private birthDayM: number = 0;
  private sex: number = 0;
  private pref: number = 0;

  private errorName1: boolean = false;
  private errorName2: boolean = false;
  private errorKana1: boolean = false;
  private errorKana2: boolean = false;
  private errorPubName: boolean = false;
  private errorPubNameShort: boolean = false;
  private errorPriName: boolean = false;
  private errorPriNameShort: boolean = false;

  private pubClasses = [
    { value: 'P', text: 'P' },
    { value: 'a', text: 'SA' },
    { value: 'A', text: 'A' },
    { value: 'B', text: 'B' },
    { value: 'C', text: 'C' },
  ];

  private priClasses = [
    { value: 'P', text: 'P' },
    { value: 'a', text: 'SA' },
    { value: 'A', text: 'A' },
    { value: 'b', text: 'SB' },
    { value: 'B', text: 'B' },
    { value: 'C', text: 'C' },
    { value: 'D', text: 'D' },
  ];

  @Emit('checkSend') private _CheckSend(okProfile: boolean): void { return; }

  private get kana1State(): boolean {
    return this.kana1.length > 0 ? true : false;
  }

  private get pubNameState(): boolean {
    return this.pubName.length > 0 ? true : false;
  }

  private get pubNameShortState(): boolean {
    return this.pubNameShort.length > 0 ? true : false;
  }

  private get pubClassState(): boolean {
    return this.pubClass.length > 0 ? true : false;
  }

  private get priNameState(): boolean {
    return this.priName.length > 0 ? true : false;
  }

  private get priNameShortState(): boolean {
    return this.priNameShort.length > 0 ? true : false;
  }

  private get priClassState(): boolean {
    return this.priClass.length > 0 ? true : false;
  }

  private get birthDayYState(): boolean {
    return this.birthDayY > 0 ? true : false;
  }

  private get birthDayMState(): boolean {
    return this.birthDayM > 0 ? true : false;
  }

  private get sexState(): boolean {
    return this.sex > 0 ? true : false;
  }

  private get prefState(): boolean {
    return this.pref > 0 ? true : false;
  }

  private created(): void {
    this.name1        = this.profile.name1;
    this.name2        = this.profile.name2;
    this.kana1        = this.profile.kana1;
    this.kana2        = this.profile.kana2;
    this.pubName      = this.profile.pubName;
    this.pubNameShort = this.profile.pubNameShort;
    this.pubClass     = this.profile.pubClass;
    this.priName      = this.profile.priName;
    this.priNameShort = this.profile.priNameShort;
    this.priClass     = this.profile.priClass;
    this.birthDayY    = this.profile.birthDayY;
    this.birthDayM    = this.profile.birthDayM;
    this.sex          = this.profile.sex;
    this.pref         = this.profile.pref;
    this.changeData();
  }

  private changeData(): void {
    this.profile.name1        = this.name1;
    this.profile.name2        = this.name2;
    this.profile.kana1        = this.kana1;
    this.profile.kana2        = this.kana2;
    this.profile.pubName      = this.pubName;
    this.profile.pubNameShort = this.pubNameShort;
    this.profile.pubClass     = this.pubClass;
    this.profile.priName      = this.priName;
    this.profile.priNameShort = this.priNameShort;
    this.profile.priClass     = this.priClass;
    this.profile.birthDayY    = this.birthDayY;
    this.profile.birthDayM    = this.birthDayM;
    this.profile.sex          = this.sex;
    this.profile.pref         = this.pref;

    const okProfile = this.kana1State
                  && this.pubNameState
                  && this.pubNameShortState
                  && this.pubClassState
                  && this.priNameState
                  && this.priNameShortState
                  && this.priClassState
                  && this.birthDayYState
                  && this.birthDayMState
                  && this.sexState
                  && this.prefState;
    this._CheckSend(okProfile);
  }

  private checkErrMsg(): void {
    this.errorName1 = this.errorArray.includes('u_name_1');
    this.errorName2 = this.errorArray.includes('u_name_2');
    this.errorKana1 = this.errorArray.includes('u_kana_1');
    this.errorKana2 = this.errorArray.includes('u_kana_2');
    this.errorPubName = this.errorArray.includes('u_pub_n');
    this.errorPubNameShort = this.errorArray.includes('u_pub_s');
    this.errorPriName = this.errorArray.includes('u_pri_n');
    this.errorPriNameShort = this.errorArray.includes('u_pri_s');
  }
}
