





































import {Component, Vue} from 'vue-property-decorator';
import Header       from '@/components/common/Header.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class DeptStatus extends Vue {
  private error: string = '';
  private usrType: number = this.$store.getters.getUsrType();
  private rolesFlg: {canEditInfo: boolean, canEditMsg: boolean, canEditEvent: boolean, canEditAdmin: boolean}
    = this.$store.getters.getRolesFlg();
  private deptList: any[] = [];
  private connection: boolean = true;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: '店舗ステータス', active: true},
  ];

  private created(): void {
    try {
      if (this.usrType === 0) {
        this.$router.push({name: 'top'});
      }
      this.getDeptStatus();
    } catch (e) {
      window.console.log(e);
      this.$router.push({name: 'error'});
    }
  }

  private async getDeptStatus() {
    this.connection = true;
    this.deptList = [];
    const token = this.$store.getters.getToken();
    return await libAxios.get('dept-status', token, {})
    .then((param: {[key: string]: any}) => {
      Object.keys(param).map((str: string) => {
        const key = parseInt(str, 10);
        this.deptList.push(param[key]);
        this.connection = false;
      });
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }
}
