import {MultiParam}       from '@/model/interface/MultiParam';
import {MultiPlayerParam} from '@/model/interface/MultiPlayerParam';
import {PlaceParam}       from '@/model/interface/PlaceParam';
import {EventParameter}   from '@/model/interface/EventParameter';
import libAxios        from '@/mixin/LibAxios';

export default {
  namespaced: true,
  state: {
    gameId: 0,
    gamePreId: 0,
    gameType: 0,
    gameStatus: 0,
    gameMode: 0,
    players: [],
    event: {id: 0, gameId: 0, name: 'practice'},
    place: {id: 0, name: '', tableNum: 0},
    config: {},
    startDateTime: '',
    multiGame: {
      gm_status: 0,
      eg_id: 0,
      end_dt: '',
      play_ts: 0,
      param: '',
      inning: 0,
    },
    scorp: 0,
  } as MultiParam,
  getters: {
    getToken: (state: MultiParam, getters: any, RootState: any) => (): string => {
      return RootState.token;
    },
    getGameId: (state: MultiParam, getters: any) => (): number => {
      return state.gameId;
    },
    getGamePreId: (state: MultiParam, getters: any) => (): number => {
      return state.gamePreId;
    },
    getGameType: (state: MultiParam, getters: any) => (): number => {
      return state.gameType;
    },
    getGameStatus: (state: MultiParam, getters: any) => (): number => {
      return state.gameStatus;
    },
    getGameMode: (state: MultiParam, getters: any) => (): number => {
      return state.gameMode;
    },
    getPlayers: (state: MultiParam, getters: any) => (): MultiPlayerParam[] => {
      return state.players;
    },
    getEvent: (state: MultiParam, getters: any) => (): EventParameter => {
      return state.event;
    },
    getEventId: (state: MultiParam, getters: any) => (): number => {
      return state.event.id;
    },
    getPlace: (state: MultiParam, getters: any) => (): PlaceParam => {
      return state.place;
    },
    getConfig: (state: MultiParam, getters: any) => (): {[key: string]: number} => {
      return state.config;
    },
    getConfigParam: (state: MultiParam, getters: any) => (key: string): number => {
      return state.config[key];
    },
    getStartDateTime: (state: MultiParam, getters: any) => (): string => {
      return state.startDateTime;
    },
    // 個別
    getTimeSpan: (state: MultiParam, getters: any) => (): number => {
      return state.multiGame.play_ts;
    },
    getMultiGameParam: (state: MultiParam, getters: any) => (): string => {
      return state.multiGame.param;
    },
  },
  mutations: {
    setGameId(state: MultiParam, payload: number) {
      state.gameId = payload;
    },
    setGamePreId(state: MultiParam, payload: number) {
      state.gamePreId = payload;
    },
    setPlayers(state: MultiParam, payload: MultiPlayerParam[]) {
      state.players = payload;
    },
    setConfig(state: MultiParam, payload: {[key: string]: number}) {
      state.config = payload;
    },
    setConfigParam(state: MultiParam, payload: {key: string, val: number}) {
      state.config[payload.key] = payload.val;
    },
    clearData(state: MultiParam) {
      state.gameId = 0;
      state.gamePreId = 0;
      state.gameType = 0;
      state.gameStatus = 0;
      state.gameMode = 0;
      state.players = [];
      state.event = {id: 0, gameId: 0, name: 'practice'};
      state.place = {id: 0, name: '', tableNum: 0};
      state.config = {};
      state.startDateTime = '';
      state.scorp = 0;
    },
    clearPostData(state: MultiParam) {
      state.multiGame = {
        gm_status: 0,
        eg_id: 0,
        end_dt: '',
        play_ts: 0,
        param: '',
        inning: 0,
      };
    },
    setResumeData(state: MultiParam, payload: {[key: string]: any}) {
      state.gameId     = payload.gameId;
      state.gamePreId  = payload.gamePreId;
      state.gameType   = payload.gameType;
      state.gameStatus = payload.gameStatus;
      state.gameMode   = payload.gameMode;
      state.players    = payload.players;
      state.event      = payload.event;
      state.place      = payload.place;
      state.config     = payload.config;
      state.startDateTime = payload.startDateTime;
      state.multiGame  = payload.multiGame;
      state.scorp      = payload.scorp;
    },
    initStart(state: MultiParam, payload: {[key: string]: any}) {
      state.gamePreId     = state.gameId;
      state.gameType      = payload.gameType;
      state.gameStatus    = 3; // C_STATUS_PLAY スタートから３
      state.place         = payload.place;
      state.players       = payload.players;
      state.startDateTime = payload.startDateTime;
      state.multiGame  = {
        gm_status: 3,
        eg_id: 0,
        end_dt: '',
        play_ts: 0,
        param: payload.param,
        inning: 0,
      };
    },
    continueStart(state: MultiParam, payload: {[key: string]: any}) {
      state.gameStatus    = 3; // C_STATUS_PLAY スタートから３
      state.players       = payload.players;
      state.startDateTime = payload.startDateTime;
      state.multiGame  = {
        gm_status: 3,
        eg_id: 0,
        end_dt: '',
        play_ts: 0,
        param: payload.param,
        inning: 0,
      };
    },
    startGuest(state: MultiParam, payload: {[key: string]: any}) {
      state.gameType      = payload.gameType;
      state.gameStatus    = 3; // C_STATUS_PLAY スタートから３
      state.players       = payload.players;
      state.startDateTime = payload.startDateTime;
      state.multiGame  = {
        gm_status: 3,
        eg_id: 0,
        end_dt: '',
        play_ts: 0,
        param: payload.param,
        inning: 0,
      };
    },
    reStart(state: MultiParam, payload: MultiPlayerParam[]) {
      state.gamePreId = state.gameId;
      state.gameStatus = 0;
      state.players = payload;
      state.startDateTime = '';
      state.multiGame  = {
        gm_status: 0,
        eg_id: state.event.gameId,
        end_dt: '',
        play_ts: 0,
        param: '',
        inning: 0,
      };
    },
    saveData(state: MultiParam, payload: {[key: string]: any}) {
      state.multiGame.gm_status = 3;
      state.multiGame.end_dt = payload.endDateTime;
      state.multiGame.play_ts = payload.playTimeSpan;
      state.multiGame.param = payload.param;
      state.multiGame.inning = payload.inning;
    },
  },
  actions: {
    initAction(context: any) {
      context.commit('clearData');
      context.commit('clearPostData');
      window.localStorage.removeItem('currentGame');
    },
    reStartAction(context: any, players: MultiPlayerParam[]) {
      context.commit('reStart', players);
      window.localStorage.removeItem('currentGame');
    },
    async startAction(context: any, param: {
        gamePreId: number,
        gameType: number,
        place: PlaceParam,
        players: MultiPlayerParam[],
        names: string,
        startDateTime: string,
        param: string,
      }) {
      // 初期化
      window.localStorage.removeItem('currentGame');
      const token = context.getters.getToken();
      const postParam = {
        gm_pre_id: param.gamePreId,
        gm_type:   param.gameType,
        eg_id:     context.state.event.gameId,
        d_id:      param.place.id,
        d_name:    param.place.name,
        d_tbl:     param.place.tableNum,
        start_dt:  param.startDateTime,
        names:     param.names,
        param:     param.param,
        config:    JSON.stringify(context.state.config),
        scorp:     context.state.config.isOpen,
      };
      return await libAxios.post('game-data/multi', token, postParam)
      .then((data: {[key: string]: any}) => {
        context.commit('setGameId', data.gm_id);
        context.commit('initStart', param);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async continueAction(context: any, param: {
      players: MultiPlayerParam[],
      names: string,
      startDateTime: string,
      param: string,
    }) {
      // 初期化
      window.localStorage.removeItem('currentGame');
      const token = context.getters.getToken();
      const postParam = {
        gm_pre_id: context.state.gamePreId,
        gm_type:   context.state.gameType,
        eg_id:     context.state.event.gameId,
        d_id:      context.state.place.id,
        d_name:    context.state.place.name,
        d_tbl:     context.state.place.tableNum,
        start_dt:  param.startDateTime,
        names:     param.names,
        param:     param.param,
        config:    JSON.stringify(context.state.config),
        scorp:     context.state.config.isOpen,
      };
      return await libAxios.post('game-data/multi', token, postParam)
      .then((data: {[key: string]: any}) => {
        context.commit('continueStart', param);
        context.commit('setGameId', data.gm_id);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    startGuestAction(context: any, param: {
      gameType: number,
      players: MultiPlayerParam[],
      startDateTime: string,
      param: string,
    }) {
      context.commit('startGuest', param);
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
    },
    saveAction(context: any, param: {
      endDateTime: string,
      playTimeSpan: number,
      param: string,
      inning: number,
    }) {
      // 同期は不要
      context.commit('saveData', param);
      const token = context.getters.getToken();
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      if (context.state.gameId !== 0) {
        libAxios.asyncPut('game-data/multi/' + context.state.gameId, token, context.state.multiGame);
      }
    },
    async finAction(context: any, finParam: {
      isFin: boolean,
      param: string,
      inning: number,
    }) {
      const token = context.getters.getToken();
      const postParam = {
        gm_status: 6,
        eg_id: context.state.event.gameId,
        inning: finParam.inning,
        param: finParam.param,
      };
      return await libAxios.put('game-data/multi/' + context.state.gameId, token, postParam)
      .then(() => {
        window.localStorage.removeItem('currentGame');
        context.commit('clearPostData');
        if (finParam.isFin) {
          context.commit('clearData');
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    setStatusAction(context: any, gameStatus: number) {
      context.commit('setMultiStatus', gameStatus);
    },
    setConfigAction(context: any, configData: {[key: string]: number}) {
      context.commit('setConfig', configData);
    },
    setConfigParamAction(context: any, configParam: {key: string, val: number}) {
      context.commit('setConfigParam', configParam);
    },
    setPlayersAction(context: any, players: MultiPlayerParam[]) {
      context.commit('setPlayers', players);
    },
    destroyAction(context: any) {
      context.commit('clearData');
      // TODO NET
      window.localStorage.removeItem('currentGame');
    },
    resumeAction(context: any, resumeData: {[key: string]: any}) {
      context.commit('setResumeData', resumeData);
    },
  },
};
