
































































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import Game14OneLabel   from '@/components/score/match/Game14OneLabel.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Game14OneLabel,
  },
})
export default class Game14OnePlay extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  @Prop() private currentBall!: number;

  @Emit('actionType') private playPoint(breakType: string, ball: number): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get isWin(): boolean {
    const score1 = this.$store.getters['match/getPlayer1Score']();
    const score2 = this.$store.getters['match/getPlayer2Score']();
    if (this.isFirst) {
      const diff1 = this.$store.getters['match/getPlayer1RaceTo']() - score1;
      if ( diff1 <= 0)  {
        return true;
      }
    } else {
      const diff2 = this.$store.getters['match/getPlayer2RaceTo']() - score2;
      if ( diff2 <= 0)  {
        return true;
      }
    }
    return false;
  }

  private isDisabled(num: number): boolean {
    return ((15 - this.currentBall - num) < 0);
  }

  private pointType(num: number): void {
    this.playPoint('P', num);
    this.$forceUpdate();
  }

  private playType(breakChar: string): void {
    this.playPoint(breakChar, 0);
  }
}
