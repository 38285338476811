

























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam}   from '@/model/interface/MatchDataParam';
import BootstrapVue      from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class YesNoPanel extends Vue {
  @Prop() private matchData!: MatchDataParam;
  @Prop() private isReverse!: boolean;

  @Emit('actionType') private selectPoint(selectType: string, balls: number[]): void { return; }

  private get _IsReverse(): boolean {
    if (this.matchData.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get yesWord(): string {
    if (this.matchData.viewMode === this.C_VIEW_ILLEGAL) {
      return 'play';
    } else if (this.matchData.viewMode === this.C_VIEW_OPTION) {
      return 'play';
    } else {
      return 'yes';
    }
  }

  private get noWord(): string {
    if (this.matchData.viewMode === this.C_VIEW_ILLEGAL) {
      return 'pass';
    } else if (this.matchData.viewMode === this.C_VIEW_OPTION) {
      return 'pass';
    } else {
      return 'no';
    }
  }

  private selectYesNo(isYes: boolean): void {
    let selectType: string;
    if (this.matchData.viewMode === this.C_VIEW_ILLEGAL) {
      selectType = (isYes) ? 'X' : 'Y' ;
    } else {
      selectType = (isYes) ? 'J' : 'K' ;
    }
    this.selectPoint(selectType, []);
  }
}
