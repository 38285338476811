import {SoloParam}     from '@/model/interface/SoloParam';
import {SoloGameParam} from '@/model/interface/SoloGameParam';
import {PlaceParam}    from '@/model/interface/PlaceParam';
import {EventParameter}   from '@/model/interface/EventParameter';
import libAxios        from '@/mixin/LibAxios';

export default {
  namespaced: true,
  state: {
    gameId: 0,
    gameType: 0,
    gameStatus: 0,
    gameMode: 0,
    event: {id: 0, gameId: 0, name: 'practice'},
    place: {id: 0, name: '', tableNum: 0},
    player: {id: 0, name: ''},
    config: {},
    startDateTime: '',
    soloGame: {
      gs_status: 0,
      end_dt: '',
      play_ts: 0,
      score: 0,
      param: '',
    },
    scorp: 0,
    checked: 0,
  } as SoloParam,
  getters: {
    getToken: (state: SoloParam, getters: any, RootState: any) => (): string => {
      return RootState.token;
    },
    getGameId: (state: SoloParam, getters: any) => (): number => {
      return state.gameId;
    },
    getGameType: (state: SoloParam, getters: any) => (): number => {
      return state.gameType;
    },
    getGameStatus: (state: SoloParam, getters: any) => (): number => {
      return state.gameStatus;
    },
    getEvent: (state: SoloParam, getters: any) => (): EventParameter => {
      return state.event;
    },
    getEventId: (state: SoloParam, getters: any) => (): number => {
      return state.event.id;
    },
    getPlace: (state: SoloParam, getters: any) => (): PlaceParam => {
      return state.place;
    },
    getPlayer: (state: SoloParam, getters: any) => (): {id: number, name: string} => {
      return state.player;
    },
    getConfig: (state: SoloParam, getters: any) => (): {[key: string]: number} => {
      return state.config;
    },
    getConfigParam: (state: SoloParam, getters: any) => (key: string): number => {
      return state.config[key];
    },
    getStartDateTime: (state: SoloParam, getters: any) => (): string => {
      return state.startDateTime;
    },
    // 個別
    getTimeSpan: (state: SoloParam, getters: any) => (): number => {
      return state.soloGame.play_ts;
    },
    getSoloGameParam: (state: SoloParam, getters: any) => (): string => {
      return state.soloGame.param;
    },
  },
  mutations: {
    setGameId(state: SoloParam, payload: number) {
      state.gameId = payload;
    },
    setPlayer(state: SoloParam, payload: {id: number, name: string}) {
      state.player = payload;
    },
    setConfig(state: SoloParam, payload: {[key: string]: number}) {
      state.config = payload;
    },
    setConfigParam(state: SoloParam, payload: {key: string, val: number}) {
      state.config[payload.key] = payload.val;
    },
    setScore(state: SoloParam, payload: number) {
      state.soloGame.score = payload;
    },
    clearData(state: SoloParam) {
      state.gameId = 0;
      state.gameType = 0;
      state.gameStatus = 0;
      state.gameMode = 0;
      state.place = {id: 0, name: '', tableNum: 0};
      state.player = {id: 0, name: ''},
      state.config = {};
      state.startDateTime = '';
      state.scorp = 0;
      state.checked = 0;
    },
    clearPostData(state: SoloParam) {
      state.soloGame = {
        gs_status: 0,
        end_dt: '',
        play_ts: 0,
        score: 0,
        param: '',
      };
    },
    retryData(state: SoloParam) {
      state.gameId = 0;
      state.gameStatus = 0;
      state.gameMode = 0;
      state.startDateTime = '';
    },
    setResumeData(state: SoloParam, payload: {[key: string]: any}) {
      state.gameId     = payload.gameId;
      state.gameType   = payload.gameType;
      state.gameStatus = payload.gameStatus;
      state.gameMode   = payload.gameMode;
      state.place      = payload.place;
      state.player     = payload.player;
      state.config     = payload.config;
      state.startDateTime = payload.startDateTime;
      state.soloGame   = payload.soloGame;
      state.scorp      = payload.scorp;
      state.checked    = payload.checked;
    },
    initData(state: SoloParam, payload: {[key: string]: any}) {
      state.gameType   = payload.gameType;
      state.gameStatus = 0; // C_STATUS_INIT
      state.place      = payload.place;
      state.player     = payload.player;
      state.checked    = payload.checked;
    },
    initGuest(state: SoloParam, payload: number) {
      state.gameType    = payload;
      state.gameStatus  = 0; // C_STATUS_INIT
      state.player.name = 'Guest';
    },
    startData(state: SoloParam, payload: {[key: string]: any}) {
      state.gameStatus = 3; // C_STATUS_PLAY
      state.startDateTime = payload.startDateTime;
      state.soloGame  = {
        gs_status: 3,
        end_dt: '',
        play_ts: 0,
        score: 0,
        param: '',
      };
    },
    saveData(state: SoloParam, payload: {[key: string]: any}) {
      state.soloGame.end_dt = payload.endDateTime;
      state.soloGame.play_ts = payload.playTimeSpan;
      state.soloGame.param = payload.param;
    },
  },
  actions: {
    async initAction(context: any, param: {
        gameType: number,
        place: PlaceParam,
        player: {id: number, name: string},
        checked: number,
      }) {
      window.localStorage.removeItem('currentGame'); // initの場合は保存しないで消す
      context.commit('clearData');
      context.commit('clearPostData');
      const token = context.getters.getToken();
      const postParam = {
        gs_type:   param.gameType,
        d_id:     param.place.id,
        d_name:   param.place.name,
        d_tbl:    param.place.tableNum,
        u_id:     param.player.id,
        checked:  param.checked,
      };
      return await libAxios.post('game-data/solo', token, postParam)
      .then((data: {[key: string]: any}) => {
        context.commit('setGameId', data.gs_id);
        context.commit('initData', param);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
        return true;
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async retryAction(context: any) {
      const token = context.getters.getToken();
      const postParam = {
        gs_type: context.state.gameType,
        d_id:    context.state.place.id,
        d_name:  context.state.place.name,
        d_tbl:   context.state.place.tableNum,
        u_id:    context.state.player.id,
        checked: context.state.checked,
      };
      return await libAxios.post('game-data/solo', token, postParam)
      .then((data: {[key: string]: any}) => {
        context.commit('setGameId', data.gs_id);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
        return true;
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    initGuestAction(context: any, gameType: number) {
      context.commit('clearData');
      context.commit('clearPostData');
      context.commit('initGuest', gameType);
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
    },
    retryGuestAction(context: any) {
      context.commit('clearPostData');
      context.commit('retryData');
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
    },
    // resume
    startAction(context: any, param: {
      startDateTime: string,
    }) {
      // 同期は不要
      context.commit('startData', param);
      const token = context.getters.getToken();
      const postParam = {
        gs_status:  context.state.gameStatus,
        start_dt:  param.startDateTime,
        config:    JSON.stringify(context.state.config),
        scorp:     context.state.config.isOpen,
      };
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      if (context.state.gameId !== 0) {
        libAxios.asyncPut('game-data/solo/' + context.state.gameId, token, postParam);
      }
    },
    saveAction(context: any, param: {
      endDateTime: string,
      playTimeSpan: number,
      param: string,
    }) {
      // 同期は不要
      context.commit('saveData', param);
      const token = context.getters.getToken();
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      if (context.state.gameId !== 0) {
        libAxios.asyncPut('game-data/solo/' + context.state.gameId, token, context.state.soloGame);
      }
    },
    async finAction(context: any, isFin: boolean) {
      const token = context.getters.getToken();
      const postParam = {
        gs_status:  6,
      };
      return await libAxios.put('game-data/solo/' + context.state.gameId, token, postParam)
      .then(() => {
        window.localStorage.removeItem('currentGame');
        context.commit('clearPostData');
        if (isFin) {
          context.commit('clearData');
        } else {
          context.commit('retryData');
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },

    setScoreAction(context: any, score: number) {
      context.commit('setScore', score);
    },
    setConfigAction(context: any, configData: {[key: string]: number}) {
      context.commit('setConfig', configData);
    },
    setConfigParamAction(context: any, configParam: {key: string, val: number}) {
      context.commit('setConfigParam', configParam);
    },
    destroyAction(context: any) {
      context.commit('clearData');
      // TODO NET
      window.localStorage.removeItem('currentGame');
    },
    clearDataAction(context: any) {
      context.commit('clearPostData');
    },
    resumeAction(context: any, resumeData: {[key: string]: any}) {
      context.commit('setResumeData', resumeData);
    },
  },
};
