












import {Component, Prop, Vue} from 'vue-property-decorator';
import {MatchData14One} from '@/model/interface/MatchData14One';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game14OneTable extends Vue {
  @Prop() private match14one!: MatchData14One;
  @Prop() private inning!: number;
  @Prop() private isFirst!: number;
  @Prop() private viewCount!: number;

  private player1Name: string = this.$store.getters['match/getPlayer1Name']();
  private player2Name: string = this.$store.getters['match/getPlayer2Name']();

  private get scoreTable(): string {
    let viewP1 = '<tr><td rowspan="2">' + this.player1Name + '</td>';
    let scoreP1 = '<tr>';
    const startDummy = this.inning + 1;
    const viewCount = this.viewCount; // 描画のために
    for (let i = 0; i <= this.inning; i++) {
      viewP1 += '<td>';
      viewP1 += (this.match14one.viewScore1[i] === '') ? '-' : this.match14one.viewScore1[i];
      viewP1 += '</td>';
      scoreP1 += '<td>' + this.match14one.scores1[i] + '</td>';
    }
    for (let j = startDummy; j < 5; j++) {
      viewP1 += '<td>&nbsp;</td>';
      scoreP1 += '<td>&nbsp;</td>';
    }

    let viewP2 = '<tr><td rowspan="2">' + this.player2Name + '</td>';
    let scoreP2 = '<tr>';
    for (let i = 0; i <= this.inning; i++) {
      viewP2 += '<td>';
      viewP2 += (this.match14one.viewScore2[i] === '') ? '-' : this.match14one.viewScore2[i];
      viewP2 += '</td>';
      scoreP2 += '<td>' + this.match14one.scores2[i] + '</td>';
    }
    for (let j = startDummy; j < 5; j++) {
      viewP2 += '<td>&nbsp;</td>';
      scoreP2 += '<td>&nbsp;</td>';
    }

    if (this.match14one.firstPlayer === 1) {
      return viewP1 + '</tr>' + scoreP1 + '</tr>' + viewP2 + '</tr>' + scoreP2 + '</tr>';
    } else {
      return viewP2 + '</tr>' + scoreP2 + '</tr>' + viewP1 + '</tr>' + scoreP1 + '</tr>';
    }
  }

  private get _Inning(): number {
    let retNum = this.inning + 1;
    if (retNum < 5) {
      retNum = 5;
    }
    return retNum;
  }
}
