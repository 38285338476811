












































































































































































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventStep}     from '@/model/class/EventStep';
import {EventGame}     from '@/model/class/EventGame';
import {EventPlayer}   from '@/model/class/EventPlayer';
import EventGameConfig from '@/components/event-admin/EventGameConfig.vue';
import EventGameResult from '@/components/event-admin/EventGameResult.vue';
import RoundRobin      from '@/components/event-admin/table/RoundRobin.vue';
import Single10        from '@/components/event-admin/table/Single10.vue';
import Single20        from '@/components/event-admin/table/Single20.vue';
import Single30        from '@/components/event-admin/table/Single30.vue';
import Single40        from '@/components/event-admin/table/Single40.vue';
import Single50        from '@/components/event-admin/table/Single50.vue';
import Double20        from '@/components/event-admin/table/Double20.vue';
import Double21        from '@/components/event-admin/table/Double21.vue';
import Double23        from '@/components/event-admin/table/Double23.vue';
import Double30        from '@/components/event-admin/table/Double30.vue';
import Double31        from '@/components/event-admin/table/Double31.vue';
import Double32        from '@/components/event-admin/table/Double32.vue';
import Double40        from '@/components/event-admin/table/Double40.vue';
import Double41        from '@/components/event-admin/table/Double41.vue';
import Double42        from '@/components/event-admin/table/Double42.vue';
import Double43        from '@/components/event-admin/table/Double43.vue';
import Double50        from '@/components/event-admin/table/Double50.vue';
import BootstrapVue    from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    EventGameConfig,
    EventGameResult,
    RoundRobin,
    Single10,
    Single20,
    Single30,
    Single40,
    Single50,
    Double20,
    Double21,
    Double23,
    Double30,
    Double31,
    Double32,
    Double40,
    Double41,
    Double42,
    Double43,
    Double50,
  },
})
export default class EventGameEdit extends Vue {
  @Prop() private currentStep!: EventStep;
  @Prop() private editSetNum!: number;
  private currentGame: EventGame|null = null;
  private isLoading: boolean = false;
  private editSetNums: number[] = [];
  private editGameKey: string = '';
  // private stepGames: {[num: number]: {[key: string]: EventGame}} = {};
  // モーダル
  private modalGameSetting: boolean = false;
  private modalGameResult: boolean = false;
  private isAllFin: boolean = true;

  @Emit('executeStep') private executeStep(stepId: number, param: {[key: string]: any}): void { return; }

  private created(): void {
    this.editSetNums = [];
    if (this.editSetNum === -1) {
      for (let i = 0; i < this.currentStep.setCount; i++) {
        this.editSetNums.push(i);
      }
    } else {
      this.editSetNums.push(this.editSetNum);
    }

    if (this.currentStep.eventStepStatus >= this.C_STEP_FIX) {
      this.isAllFin = false;
    } else {
      const tmpGames = this.$store.getters['event/getGames']();
      for (const tmpKey in tmpGames) {
        if (tmpGames[tmpKey].eventStepId === this.currentStep.eventStepId) {
          if (tmpGames[tmpKey].eventGameStatus !== this.C_GAME_STATUS_FIX) {
            this.isAllFin = false;
            break;
          }
        }
      }
    }
  }

  private reViewStep(): void {
    const postParam = {
      command: 'review',
      ids: {},
    };
    this.executeStep(this.currentStep.eventStepId, postParam);
  }

  private finStep(): void {
    const postParam = {
      command: 'fin',
      ids: {},
    };
    this.executeStep(this.currentStep.eventStepId, postParam);
  }

 // game
  private showGame(stepId: number, gameID: number): void {
    this.isLoading = true;
    this.$store.dispatch('event/showGameAction', {stepId, gameID})
    .then(() => {
      this.modalGameSetting = false;
      this.isLoading = false;
    }).catch((e) => {
      window.console.log(e);
    });
  }

  // game
  private updateGame(stepId: number, gameID: number, param: {[key: string]: any}): void {
    this.isLoading = true;
    this.$store.dispatch('event/updateGameAction', {stepId, gameID, param})
    .then(() => {
      this.modalGameSetting = false;
      this.modalGameResult = false;
      this.isLoading = false;
    }).catch((e) => {
      window.console.log(e);
    });
  }

  // game
  private executeGame(stepId: number, gameID: number, param: {[key: string]: any}): void {
    this.isLoading = true;
    this.$store.dispatch('event/executeGameAction', {stepId, gameID, param})
    .then(() => {
      this.modalGameSetting = false;
      this.modalGameResult = false;
      this.isLoading = false;
    }).catch((e) => {
      window.console.log(e);
    });
  }

  private setBye() {
    if (this.currentGame === null) {
      return;
    }

    const postParam = {
      eg_status: this.C_GAME_STATUS_END,
      score_1: 0,
      score_2: 0,
      winner: 3,
      edit_dt: this.currentGame.editDateTime,
    };

    if (this.currentGame.eventPlayerId1 === 0 && this.currentGame.eventPlayerId2 !== 0) {
      postParam.winner = 4;
    }
    this.updateGame(this.currentGame.eventStepId, this.currentGame.eventGameId, postParam);
  }

  private editGame(key: string): void {
    this.currentGame = this.$store.getters['event/getGame'](key);
    if (this.currentGame === null) {
      return;
    }

    switch (this.currentGame.eventGameStatus) {
      case this.C_GAME_STATUS_INIT:
        if (confirm('不戦試合を確定しますか？')) {
            this.setBye();
            // window.console.log(this.currentGame);
            // this.showGame(this.currentGame.eventStepId, this.currentGame.eventGameId);
            return;
          }
        break;
      case this.C_GAME_STATUS_PAIR:
        this.modalGameSetting = true;
        break;
      case this.C_GAME_STATUS_READY:
        // ステータス次第
        if (this.currentGame.refereeId !== 0) {
          if (confirm('更新しますか？\n Yes:データ更新 No: 設定変更')) {
            this.showGame(this.currentGame.eventStepId, this.currentGame.eventGameId);
            return;
          }
        }
        this.modalGameSetting = true;
        break;
      case this.C_GAME_STATUS_PLAY:
        // 結果のみか否か
        if (this.currentGame.refereeId === -1) {
          if (confirm('結果を入力しますか？\n Yes:結果入力 No: 設定変更')) {
            this.modalGameResult = true;
          } else {
            this.modalGameSetting = true;
          }
        } else {
          if (confirm('更新しますか？')) {
            this.showGame(this.currentGame.eventStepId, this.currentGame.eventGameId);
            return;
          }
        }
        break;
      case this.C_GAME_STATUS_END:
        if (confirm('結果を確定しますか？\n Yes:結果確定 No: 結果再入力')) {
          const param: {[key: string]: any} = {
            command: 'fix',
            edit_dt:  this.currentGame.editDateTime,
          };
          this.executeGame(this.currentGame.eventStepId, this.currentGame.eventGameId, param);
        } else {
          this.modalGameResult = true;
        }
        break;
      default:
        break;
    }
  }
}
