

























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {PlaceParam}     from '@/model/interface/PlaceParam';
import PlaceTableSelect from '@/components/score/common/PlaceTableSelect.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    PlaceTableSelect,
  },
})
export default class PlaceTablePanel extends Vue {
  @Prop() private defaultTable!: number;
  private usrType!: number;
  private tabIndex: number = 0;
  private placeSelected: PlaceParam = {id: 0, name: '未選択', tableNum: 0};
  private placeSearch: PlaceParam   = {id: 0, name: '未選択', tableNum: 0};

  @Emit('setPlace') private setPlace(placeTab: number, place: PlaceParam): void { return; }

  private created(): void {
    this.usrType = this.$store.getters.getUsrType();
  }

  private setPlaceSelected(place: PlaceParam): void {
    this.placeSelected = place;
    this.setPlace(this.tabIndex, place);
  }
}
