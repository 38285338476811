





































import {Component, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue'; // @ is an alias to /src
// import LibCommon from '@/mixin/LibCommon.ts'; 実装方法を残すためコメントアウト
import libAxios        from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  // mixins: [LibCommon],実装方法を残すためコメントアウト
  components: {
    Header,
  },
})
export default class Password extends Vue {
  private token: string = '';
  private pwd1: string = '';
  private pwd2: string = '';
  private connection: boolean = false;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: 'パスワード再設定', active: true},
  ];

  private get pwd1State(): boolean {
    return this.pwd1.length > 3 ? true : false;
  }

  private get pwd2State(): boolean {
    return (this.pwd2.length > 3 && this.pwd1 === this.pwd2) ? true : false;
  }

  private get canSend(): boolean {
    return (this.pwd1State && this.pwd2State);
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.$router.push({name: 'top'});
        }
        this.token = this.$route.params.token;
        if (this.token.length !== 32) {
          alert('不正なURLです。\nトップ画面へ移動します。');
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        this.$router.push({name: 'error'});
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async resetPassword() {
    const postParam: {[key: string]: any} = {
      token: this.token,
      password: this.pwd1,
    };

    this.connection = true;
    const token = this.$store.getters.getToken();
    return await libAxios.postNoToken('password-reset', postParam)
    .then((data: {[key: string]: any}) => {
      alert('更新しました。\nログイン画面へ移動します。');
      this.$router.push({name: 'login'});
    }).catch((e: Error) => {
      // throw new Error(e.message);
    });
  }
}
