



































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import Game14OneBall    from '@/components/score/match/Game14OneBall.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Game14OneBall,
  },
})
export default class Game14OnePlayLv1 extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  @Prop() private currentBall!: number;
  private workBall: number = 0;

  @Emit('actionType') private playPoint(breakType: string, ball: number): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get isWin(): boolean {
    const score1 = this.$store.getters['match/getPlayer1Score']();
    const score2 = this.$store.getters['match/getPlayer2Score']();
    const diff = this.workBall - this.currentBall;
    if (diff > 0) {
      if (this.isFirst) {
        const diff1 = this.$store.getters['match/getPlayer1RaceTo']() - score1 - diff;
        if ( diff1 <= 0)  {
          return true;
        }
      } else {
        const diff2 = this.$store.getters['match/getPlayer2RaceTo']() - score2 - diff;
        if ( diff2 <= 0)  {
          return true;
        }
      }
    }
    return false;
  }

  private setBall(ball: number): void {
    this.workBall = ball;
  }

  private playType(breakChar: string): void {
    this.playPoint(breakChar, this.workBall - this.currentBall);
    if (breakChar === 'X' || breakChar === 'x') {
      this.workBall = 0;
    }
  }
}
