import {Component, Vue} from 'vue-property-decorator';

declare module 'vue/types/vue' {
  interface Vue {
    C_MODE_GUEST: number;
    C_MODE_MEMBER: number;
    C_MODE_STAFF: number;

    C_ROLE_GUEST: number;
    C_ROLE_MEMBER: number;
    C_ROLE_STAFF: number;
    C_ROLE_MANAGER: number;
    C_ROLE_ADMIN: number;
    C_ROLE_SYSTEM: number;

    C_REF_ORG: number;
    C_REF_DEPT: number;

    C_USR_CLASS: {[key: string]: string};

    C_GAME_NAMES: {[key: number]: string};

    C_NUM_9B: number;
    C_NUM_10B: number;
    C_NUM_14_1: number;
    C_NUM_ROTE: number;

    C_NUM_POINT: number;
    C_NUM_FREE_POINT: number;

    C_NUM_BOWLARD: number;

    C_NAME_9B: string;
    C_NAME_10B: string;
    C_NAME_14_1: string;
    C_NAME_ROTE: string;

    C_NAME_POINT: string;
    C_NAME_FREE_POINT: string;

    C_NAME_BOWLARD: string;

    C_NAME_MATCH: string;
    C_NAME_SOLO: string;
    C_NAME_MULTI: string;

    C_PARAM_POINT_PLAYER: string;

    C_RACE_TO: {[key: number]: number};

    C_CFG_MATCH_COMMON: {[key: string]: number};
    C_CFG_9B_PARAM: {[key: string]: number};
    C_CFG_10B_PARAM: {[key: string]: number};
    C_CFG_14_1_PARAM: {[key: string]: number};
    C_CFG_ROTE_PARAM: {[key: string]: number};
    C_CFG_POINT_PARAM: {[key: string]: number};
    C_CFG_FREE_PARAM: {[key: string]: number};
    C_CFG_BOWLARD_PARAM: {[key: string]: number};

    C_STATUS_INIT: number;
    C_STATUS_CONFIG: number;
    C_STATUS_READY: number;
    C_STATUS_PLAY: number;
    C_STATUS_STOP: number;
    C_STATUS_RESUME: number;
    C_STATUS_END: number;
    C_STATUS_FIX: number;

    C_VIEW_START: number;
    C_VIEW_BREAK: number;
    C_VIEW_PLAY: number;
    C_VIEW_ILLEGAL: number;
    C_VIEW_OPTION: number;
    C_VIEW_JUDGE: number;
    C_VIEW_ERROR: number;
    C_VIEW_FIN: number;
    C_VIEW_END: number;

    C_SCORE_SCALE_S: {min: number, mid: number, max: number};
    C_SCORE_SCALE_L: {min: number, mid: number, max: number};

    C_WINNER_TBD: number;
    C_WINNER_P1: number;
    C_WINNER_P2: number;
    C_WINNER_NONE: number;

    // 管理系
    C_EVENT_TEMP: number;
    C_EVENT_DRAFT: number;
    C_EVENT_HIDE: number;
    C_EVENT_OVER: number;
    C_EVENT_VALID: number;
    C_EVENT_OPEN: number;
    C_EVENT_ENTRY: number;
    C_EVENT_READY: number;
    C_EVENT_HOLD: number;
    C_EVENT_CUP: number; // clean up
    C_EVENT_FIN: number;
    C_EVENT_STATUS_NAMES: {[key: number]: string};

    C_COMMON_TEMP: number;
    C_COMMON_DRAFT: number;
    C_COMMON_VALID: number;
    C_COMMON_CLOSE: number;

    C_EVENT_CYCLE: {[key: number]: string};

    C_STEP0_OPEN: number;
    C_STEP0_FULL: number;
    C_STEP0_OVER: number;
    C_STEP0_CLOSE: number;
    C_STEP0_FETCH: number;
    C_STEP_END: number;

    C_STEP_INIT: number;
    C_STEP_CLOSE: number;
    C_STEP_SET: number;
    C_STEP_FIX: number;

    C_GAME_STATUS_INIT: number;
    C_GAME_STATUS_PAIR: number;
    C_GAME_STATUS_READY: number;
    C_GAME_STATUS_PLAY: number;
    C_GAME_STATUS_STOP: number;
    C_GAME_STATUS_RESUME: number;
    C_GAME_STATUS_END: number;
    C_GAME_STATUS_FIX: number;

    C_PLAYER_STATUS_INI: number;
    C_PLAYER_STATUS_PLAY: number;
    C_PLAYER_STATUS_PLAY_: number;
    C_PLAYER_STATUS_LOSE: number;
    C_PLAYER_STATUS_WIN: number;
    C_PLAYER_STATUS_WIN_: number;
    C_PLAYER_STATUS_NEXT: number;

    C_TNM_ORDER: {[key: number]: number[]};
    C_TNM_ORDER_D: {[key: number]: number[]};

    // システム関係
    // 表示範囲
    C_SCORP_OPEN: number;
    C_SCORP_MEMBER: number;
    C_SCORP_PLAYER: number;
    C_SCORP_HIDE: number;
    C_SCORPS: Array<{value: number, text: string}>;
    C_SCORP_STATUS: {[key: number]: string};

    C_CATEGORIES: Array<{text: string, value: number}>;
    C_WEEK_DATA: Array<{text: string, value: number}>;
    C_HOUR_DATA: Array<{value: string, text: string}>;
    C_PREFECTURE: Array<{value: number, text: string}>;

    //  DARTS
    CD_NUM_01: number;
    CD_NAME_O1: string;
  }
}

@Component
export default class ConstCommon extends Vue {
  public C_MODE_GUEST: number  = 0;
  public C_MODE_MEMBER: number = 1;
  public C_MODE_STAFF: number  = 2;

  public C_ROLE_GUEST: number   = 0;
  public C_ROLE_MEMBER: number  = 1; // メンバー
  public C_ROLE_STAFF: number   = 2; // 関係者
  public C_ROLE_MANAGER: number = 3; // 部門管理者
  public C_ROLE_ADMIN: number   = 4; // 組織管理者
  public C_ROLE_SYSTEM: number  = 9;

  public C_REF_ORG: number = 1;
  public C_REF_DEPT: number = 2;

  public C_USR_CLASS: {[key: string]: string} = {
    P: 'P', A: 'A', a: 'SA', B: 'B', b: 'sb',  C: 'C',  D: 'D',
  };

  public C_GAME_NAMES: {[key: number]: string} = {
    1: '9ball',
    2: '10ball',
    3: '14-1',
    4: 'Rotation',
    11: 'Point Game',
    12: 'Free Point',
    21: 'ボーラード',
  };

  public C_NUM_9B: number   = 1;
  public C_NUM_10B: number  = 2;
  public C_NUM_14_1: number = 3;
  public C_NUM_ROTE: number = 4;

  public C_NUM_POINT: number      = 11;
  public C_NUM_FREE_POINT: number = 12;

  public C_NUM_BOWLARD: number = 21;

  public C_NAME_9B: string = '9ball';
  public C_NAME_10B: string = '10ball';
  public C_NAME_14_1: string = '14_1';
  public C_NAME_ROTE: string = 'rotation';

  public C_NAME_POINT: string = 'point_game';
  public C_NAME_FREE_POINT: string = 'free_point';

  public C_NAME_BOWLARD: string = 'bowlard';

  public C_NAME_MATCH: string = 'match';
  public C_NAME_SOLO: string = 'solo';
  public C_NAME_MULTI: string = 'multi';

  public C_PARAM_POINT_PLAYER: string = JSON.stringify({
    id: 0,
    name: 'Guest',
    eventId: 0,
    isFix: false,
    handicap: '',
    etc: '',
    point: 0,
  });

  public C_RACE_TO: {[key: number]: number} = {
    1: 5, // 9ball
    2: 5, // 10ball
    3: 50, // 14-1ball
    4: 180, // Rote
    101: 501, // Rote
  };

  public C_CFG_MATCH_COMMON: {[key: string]: number} = {
    doubleHit: 0, // 1-2
    ruckSet: 1,  // 1-3
    isOpen: 1, // 0-1
    stopWatch: 0, // 0-1
    isEdit: 1, // 0-1
  };

  public C_CFG_9B_PARAM: {[key: string]: number} = {
    breakOrder: 1, // 1-2
    pushOut: 1,  // 0-1
    threePoint: 1, // 0-1
    breakAce: 0, // 0-1
    threeFoul: 1,
    callShot: 0,
    scoreLv: 1,
    ninePos: 1,
    breakBox: 0,
  };

  public C_CFG_10B_PARAM: {[key: string]: number} = {
    breakOrder: 1, // 1-2
    pushOut: 1,  // 0-1
    threeFoul: 1,
    callShot: 1,
    tenPos: 1,
    scoreLv: 1,
    breakBox: 0,
    continuePlay: 0,
  };

  public C_CFG_14_1_PARAM: {[key: string]: number} = {
    callShot: 1,
    scoreLv: 1, // 1-3
    limitType: 0,
    limitInning: 15,
    limitTime: 60,
    judgeType: 1,
    breakFree: 0,
    foulFree: 0,
  };

  public C_CFG_ROTE_PARAM: {[key: string]: number} = {
    pushOut: 1,  // 0-1
    threeFoul: 1,
    callShot: 1,
    scoreLv: 1,
    braekPos: 1,
  };

  public C_CFG_POINT_PARAM: {[key: string]: number} = {
    useDouble: 1,  // 0-1
    nextRandom: 1,
    limitInning: 0,
    isOpen: 1, // 0-1
    inputMethod: 1,
  };

  public C_CFG_FREE_PARAM: {[key: string]: number} = {
    limitInning: 10,
    isOpen: 1, // 0-1
  };

  public C_CFG_BOWLARD_PARAM: {[key: string]: number} = {
    isOpen: 1, // 0-1
    gameType: 1,
  };

  public C_STATUS_INIT: number   = 0;
  public C_STATUS_CONFIG: number = 1;
  public C_STATUS_READY: number  = 2;
  public C_STATUS_PLAY: number   = 3;
  public C_STATUS_STOP: number   = 4;
  public C_STATUS_RESUME: number = 5;
  public C_STATUS_END: number = 6;
  public C_STATUS_FIX: number = 9;

  public C_VIEW_START: number   = 0;
  public C_VIEW_BREAK: number   = 1;
  public C_VIEW_PLAY: number    = 2;
  public C_VIEW_ILLEGAL: number = 3;
  public C_VIEW_OPTION: number  = 4;
  public C_VIEW_JUDGE: number   = 5;
  public C_VIEW_ERROR: number   = 7;
  public C_VIEW_FIN: number     = 8;
  public C_VIEW_END: number     = 9;

  public C_SCORE_SCALE_S: {min: number, mid: number, max: number} = {
    min: 1,
    mid: 0,
    max: 3,
  };

  public C_SCORE_SCALE_L: {min: number, mid: number, max: number} = {
    min: 1,
    mid: 5,
    max: 10,
  };

  public C_WINNER_TBD: number = 0;
  public C_WINNER_P1: number = 1;
  public C_WINNER_P2: number = 2;
  public C_WINNER_NONE: number = -1;

  // 管理者
  public C_EVENT_TEMP   = 0; // システムがつくったテンプレート状態
  public C_EVENT_DRAFT  = 1; // 下書
  public C_EVENT_HIDE   = 2; // 非表示
  public C_EVENT_OVER   = 3; // 期限切れ
  public C_EVENT_VALID  = 4; // 準備OK → スケジュールにのる
  public C_EVENT_OPEN   = 5; // 公開   → ページへリンクがつく。NEWS連動
  public C_EVENT_ENTRY  = 6; // エントリー開始
  public C_EVENT_READY  = 7; // 準備中
  public C_EVENT_HOLD   = 8; // 開催中
  public C_EVENT_CUP    = 9; // clean up
  public C_EVENT_FIN    = 10; // イベント終了後

  public C_EVENT_STATUS_NAMES: {[key: number]: string} = {
    0: 'テンプレ',
    1: '下書き',
    2: '非表示',
    3: '期限切れ',
    4: '有効',
    5: '公開中',
    6: '受付中',
    7: '準備中',
    8: '開催中',
    9: '集計中',
    10: '終了',
  };

  public C_COMMON_TEMP  = 0; //
  public C_COMMON_DRAFT = 1; // 下書
  // public C_COMMON_ = 2; // 申請
  // public C_COMMON_ = 3; // 却下
  public C_COMMON_VALID = 4; // 有効
  public C_COMMON_CLOSE = 9; // 非公開

  public C_EVENT_CYCLE = {
    1: 'Weekly',
    2: 'Monthly(曜日)',
    3: 'Monthly(日付)',
    9: '不定期',
  };

  public C_STEP0_OPEN  = 0; // 募集終了
  public C_STEP0_FULL  = 1; // 人数いっぱい
  public C_STEP0_OVER  = 2; // キャンセル待ちをいっぱい
  public C_STEP0_CLOSE = 3; // 募集終了
  public C_STEP0_FETCH = 4; // 取り込み完了
  public C_STEP_END    = 8; // 確定

  public C_STEP_INIT = 0;
  public C_STEP_CLOSE = 1; // 取り込み終了
  public C_STEP_SET = 2;   // 枠移動終了
  public C_STEP_FIX = 4;   // 確定

  public C_GAME_STATUS_INIT   = 0;
  public C_GAME_STATUS_PAIR   = 1; // 対戦相手がきまった
  public C_GAME_STATUS_READY  = 2; // 準備完了
  public C_GAME_STATUS_PLAY   = 3; // 試合開始
  public C_GAME_STATUS_STOP   = 4; // 中断
  public C_GAME_STATUS_RESUME = 5; // 再開
  public C_GAME_STATUS_END    = 6; // 試合終了
  public C_GAME_STATUS_FIX    = 9; // 確定

  public C_PLAYER_STATUS_INI   = 0; // 初期
  public C_PLAYER_STATUS_PLAY  = 1; // PLAY中
  public C_PLAYER_STATUS_PLAY_ = 2; // 負け側PLAY中
  public C_PLAYER_STATUS_LOSE  = 3; // 負け
  public C_PLAYER_STATUS_WIN   = 4; // 勝ち
  public C_PLAYER_STATUS_WIN_  = 5; // 勝ち(負け側)
  public C_PLAYER_STATUS_NEXT  = 8; // 強制

  public C_TNM_ORDER: {[key: number]: number[]} = {
    1: [0, 1],
    2: [0, 3, 2, 1],
    3: [0, 7, 4, 3, 2, 5, 6, 1],
    4: [0, 15, 8, 7, 4, 11, 12, 3, 2, 13, 10, 5, 6, 9, 14, 1],
    5: [0, 31, 16, 15, 8, 23, 24, 7, 4, 27, 20, 11, 12, 19, 28, 3,
        2, 29, 18, 13, 10, 21, 26, 5, 6, 25, 22, 9, 14, 17, 30, 1],
  };

  public C_TNM_ORDER_D: {[key: number]: number[]} = {
    2: [0, 3, 1, 2],
    3: [0, 7, 3, 4, 1, 6, 2, 5],
    4: [0, 15, 7, 8, 3, 12, 4, 11, 1, 14, 6, 9, 2, 13, 5, 10],
    5: [ 0, 31, 15, 16, 7, 24, 8, 23, 3, 28, 12, 19, 4, 27, 11, 20,
         1, 30, 14, 17, 6, 25, 9, 22, 2, 29, 13, 18, 5, 26, 10, 21],
  };

  // システム関連
  public C_SCORP_HIDE   = 0;
  public C_SCORP_PLAYER = 1;
  public C_SCORP_MEMBER = 2;
  public C_SCORP_OPEN   = 9;

  public C_SCORPS: Array<{value: number, text: string}> = [
    {value: this.C_SCORP_HIDE, text: '非公開'},
    {value: this.C_SCORP_PLAYER, text: '参加者のみ'},
    {value: this.C_SCORP_MEMBER, text: 'メンバーのみ'},
    {value: this.C_SCORP_OPEN, text: '公開'},
  ];

  public C_SCORP_STATUS: {[key: number]: string} = {
    0: '公開',
    1: 'メンバーのみ',
    2: '参加者のみ',
    8: 'オーナーのみ',
    9: '非公開',
  };

  public C_CATEGORIES: Array<{text: string, value: number}> = [
    { text: '未設定',   value: 0},
    { text: 'ニュース', value: 1},
    { text: 'Link',     value: 2},
    { text: 'お知らせ', value: 3},
    { text: '試合',     value: 4},
    { text: '商品',     value: 5},
    { text: 'イベント', value: 6},
  ];

  public C_WEEK_DATA: Array<{text: string, value: number}> = [
    { text: '月', value: 1},
    { text: '火', value: 2},
    { text: '水', value: 3},
    { text: '木', value: 4},
    { text: '金', value: 5},
    { text: '土', value: 6},
    { text: '日', value: 7},
  ];

  public C_HOUR_DATA: Array<{value: string, text: string}> = [
    {value: '00:00', text: '00:00'},
    {value: '01:00', text: '01:00'},
    {value: '02:00', text: '02:00'},
    {value: '03:00', text: '03:00'},
    {value: '04:00', text: '04:00'},
    {value: '05:00', text: '05:00'},
    {value: '06:00', text: '06:00'},
    {value: '07:00', text: '07:00'},
    {value: '08:00', text: '08:00'},
    {value: '09:00', text: '09:00'},
    {value: '10:00', text: '10:00'},
    {value: '11:00', text: '11:00'},
    {value: '12:00', text: '12:00'},
    {value: '13:00', text: '13:00'},
    {value: '14:00', text: '14:00'},
    {value: '15:00', text: '15:00'},
    {value: '16:00', text: '16:00'},
    {value: '17:00', text: '17:00'},
    {value: '18:00', text: '18:00'},
    {value: '19:00', text: '19:00'},
    {value: '20:00', text: '20:00'},
    {value: '21:00', text: '21:00'},
    {value: '22:00', text: '22:00'},
    {value: '23:00', text: '23:00'},
  ];

  public C_PREFECTURE: Array<{value: number, text: string}> = [
    {value: 0, text: '未選択'},
    {value: 1, text: '北海道'},
    {value: 2, text: '青森県'},
    {value: 3, text: '岩手県'},
    {value: 4, text: '宮城県'},
    {value: 5, text: '秋田県'},
    {value: 6, text: '山形県'},
    {value: 7, text: '福島県'},
    {value: 8, text: '茨城県'},
    {value: 9, text: '栃木県'},
    {value: 10, text: '群馬県'},
    {value: 11, text: '埼玉県'},
    {value: 12, text: '千葉県'},
    {value: 13, text: '東京都'},
    {value: 14, text: '神奈川県'},
    {value: 15, text: '新潟県'},
    {value: 16, text: '富山県'},
    {value: 17, text: '石川県'},
    {value: 18, text: '福井県'},
    {value: 19, text: '山梨県'},
    {value: 20, text: '長野県'},
    {value: 21, text: '岐阜県'},
    {value: 22, text: '静岡県'},
    {value: 23, text: '愛知県'},
    {value: 24, text: '三重県'},
    {value: 25, text: '滋賀県'},
    {value: 26, text: '京都府'},
    {value: 27, text: '大阪府'},
    {value: 28, text: '兵庫県'},
    {value: 29, text: '奈良県'},
    {value: 30, text: '和歌山県'},
    {value: 31, text: '鳥取県'},
    {value: 32, text: '島根県'},
    {value: 33, text: '岡山県'},
    {value: 34, text: '広島県'},
    {value: 35, text: '山口県'},
    {value: 36, text: '徳島県'},
    {value: 37, text: '香川県'},
    {value: 38, text: '愛媛県'},
    {value: 39, text: '高知県'},
    {value: 40, text: '福岡県'},
    {value: 41, text: '佐賀県'},
    {value: 42, text: '長崎県'},
    {value: 43, text: '熊本県'},
    {value: 44, text: '大分県'},
    {value: 45, text: '宮崎県'},
    {value: 46, text: '鹿児島県'},
    {value: 47, text: '沖縄県'},
  ];

  public CD_NUM_01   = 101;
  public CD_NAME_O1 = 'zero_one';
}
