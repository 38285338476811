










































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class BowlardTable extends Vue {
  @Prop() private scoreParam!: string;
  private boxChar: string[] = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '-', '-'];
  private frameScore: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  private created(): void {
    const boxScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const sroceBuffer = this.scoreParam.split(',');
    const maxLen = sroceBuffer.length;
    let isFirst = true;
    let cellNum = 0;
    for (let i = 0; i < maxLen ; i++) {
      if (sroceBuffer[i] === 'a') {
        if (isFirst) {
          this.boxChar[cellNum] = 'X';
          boxScore[cellNum] = 10;

          if (cellNum < 18) {
            cellNum++;
            this.boxChar[cellNum] = '-';
            boxScore[cellNum] = 0;
          }
        } else {
          this.boxChar[cellNum] = '/';
          boxScore[cellNum] = 10 - boxScore[i - 1];
        }
        isFirst = true;
      } else if (sroceBuffer[i] === '-1') {
        this.boxChar[cellNum] = 'G';
        boxScore[cellNum] = 0;
        isFirst = !isFirst;
      } else {
        this.boxChar[cellNum] = sroceBuffer[i];
        boxScore[cellNum] = parseInt(sroceBuffer[i], 10);
        isFirst = !isFirst;
      }
      cellNum++;
    }

    let frameNum = 0;
    for (let i = 0; i <= 9; i++) {
      frameNum = i * 2;

      if (i !== 0) {
        this.frameScore[i] = this.frameScore[i - 1];
      } else {
        this.frameScore[i] = 0;
      }
      this.frameScore[i] += boxScore[frameNum];
      this.frameScore[i] += boxScore[frameNum + 1];

      if (i === 9) {
        this.frameScore[i] += boxScore[frameNum + 2];
      }

      if (this.boxChar[frameNum - 1] === '/') {
        this.frameScore[i] += boxScore[frameNum];
        this.frameScore[i - 1] += boxScore[frameNum];
      } else if (this.boxChar[frameNum - 2] === 'X') {
        this.frameScore[i] += boxScore[frameNum];
        this.frameScore[i] += boxScore[frameNum + 1];
        this.frameScore[i - 1] += boxScore[frameNum];
        this.frameScore[i - 1] += boxScore[frameNum + 1];
        if ((i > 1) && (this.boxChar[frameNum - 4] === 'X')) {
          this.frameScore[i]     += boxScore[frameNum];
          this.frameScore[i - 1] += boxScore[frameNum];
          this.frameScore[i - 2] += boxScore[frameNum];
        }
      }
    }
  }
}
