










































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import GameRotationBall from '@/components/score/match/GameRotationBall.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    GameRotationBall,
  },
})
export default class Game14OnePlayLv1 extends Vue {
  @Prop() private balls!: number[];
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  @Prop() private canPush!: boolean;

  private workBalls: number[] = [];

  @Emit('setBalls') private setBalls(num: number[]): void { return; }
  @Emit('actionType') private playPoint(playType: string, balls: number[]): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get isWin(): boolean {
    const sum = this.workBalls.reduce((prev, current) => prev += current, 0);

    if (sum > 0) {
      if (this.isFirst) {
        const score1: number = this.$store.getters['match/getPlayer1Score']();
        const raceTo1: number = this.$store.getters['match/getPlayer1RaceTo']();
        if ( (score1 + sum - raceTo1) >= 0)  {
          this.$store.dispatch('match/setWinnerAction', 1);
          return true;
        }
      } else {
        const score2: number = this.$store.getters['match/getPlayer2Score']();
        const raceTo2: number = this.$store.getters['match/getPlayer2RaceTo']();
        if ( (score2 + sum - raceTo2) >= 0)  {
          this.$store.dispatch('match/setWinnerAction', 2);
          return true;
        }
      }
    }
    this.$store.dispatch('match/setWinnerAction', 0);
    return false;
  }

  private get isNoIn(): boolean {
    return (this.workBalls.length === 0);
  }

  private get isAllIn(): boolean {
    let ballLen = this.workBalls.length;
    for (let i = 0; i < 15; i++) {
      if (this.balls[i] !== 0) {
        ballLen++;
      }
    }
    return (ballLen === 15);
  }

  private playType(playChar: string): void {
    const sum = this.workBalls.reduce((prev, current) => prev += current, 0);
    const plus = (this.isFirst) ? 1 : 2;
    const tmpBalls: number[] = this.balls;
    for (const tmpBall of this.workBalls) {
      tmpBalls[tmpBall - 1] = plus;
    }
    if (sum > 0) {
      this.$store.dispatch('match/addScoreAction', {isFirst: this.isFirst, addPoint: sum});
    }
    this.setBalls(tmpBalls);
    this.playPoint(playChar, this.workBalls);
    this.workBalls = [];
  }
}
