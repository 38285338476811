


































































































































































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import TournamentBox   from '@/components/event/table/TournamentBox.vue';
import TournamentWin   from '@/components/event/table/TournamentWin.vue';
import TournamentLine   from '@/components/event/table/TournamentLine.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    TournamentBox,
    TournamentWin,
    TournamentLine,
  },
})
export default class Double21 extends Vue {
  @Prop() private eventStep!: any;
  @Emit('viewGame') private _ViewGame(key: string): void { return; }

  private created(): void {
    // TODO
  }

  private viewGame(key: string): void {
    this._ViewGame(key);
  }
}
