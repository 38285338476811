























import {Component, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class NamePlate extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  @Prop() private viewMode!: number;

  private player1Name!: string;
  private player2Name!: string;

  private get btnColorP1(): string {
    if (this.viewMode === this.C_VIEW_BREAK && this.isFirst) {
      return 'panel-yellow';
    } else {
      return 'panel-green';
    }
  }

  private get btnColorP2(): string {
   if (this.viewMode === this.C_VIEW_BREAK && !this.isFirst) {
      return 'panel-yellow';
    } else {
      return 'panel-green';
    }
  }

  private created(): void {
    this.player1Name = this.$store.getters['darts/getPlayer1Name']();
    this.player2Name = this.$store.getters['darts/getPlayer2Name']();
  }
}
