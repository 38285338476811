





























import {Component, Prop, Vue} from 'vue-property-decorator';
import AdminHeader  from '@/components/admin/common/AdminHeader.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    AdminHeader,
  },
})
export default class RoleList extends Vue {
  private connection: boolean = true;
  private usrType: number = this.$store.getters.getUsrType();
  private usrRole: number = this.$store.getters.getUsrRole();
  private rolesFlg: {canEditInfo: boolean, canEditMsg: boolean, canEditEvent: boolean, canEditAdmin: boolean}
    = this.$store.getters.getRolesFlg();

  private targetType: string = '';
  private targetId: string = '';
  private roleList: any[] = [];
  private breadItems: Array<{[key: string]: any}> = [
    { text: '組織管理TOP', to: { name: 'admin-top' }},
    { text: 'ロール一覧', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          if (!this.rolesFlg.canEditAdmin) {
            this.$router.push({name: 'error'});
          }
          this.targetType = this.$route.params.type;
          this.targetId = this.$route.params.id;
          this.getRoleList();
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getRoleList() {
    this.connection = true;
    const token = this.$store.getters.getToken();
    libAxios.get('role-data/' + this.targetType + '/' + this.targetId, token, {})
    .then((param: {[key: string]: any}) => {
      this.roleList = param.data;
      this.connection = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
