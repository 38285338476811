










































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam}  from '@/model/interface/MatchDataParam';
import Game14OneBall     from '@/components/score/match/Game14OneBall.vue';
import BootstrapVue      from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Game14OneBall,
  },
})
export default class Game14OneBreak extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  private currentBall: number = 0;
  private workBall: number = 0;

  @Emit('actionType') private breakPoint(breakType: string, ball: number): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get isNoIn(): boolean {
    return (this.workBall === 0);
  }

  private get isWin(): boolean {
    const score1 = this.$store.getters['match/getPlayer1Score']();
    const score2 = this.$store.getters['match/getPlayer2Score']();
    if (this.workBall > 0) {
      if (this.isFirst) {
        const diff1 = this.$store.getters['match/getPlayer1RaceTo']() - score1 - this.workBall;
        if ( diff1 <= 0)  {
          return true;
        }
      } else {
        const diff2 = this.$store.getters['match/getPlayer2RaceTo']() - score2 - this.workBall;
        if ( diff2 <= 0)  {
          return true;
        }
      }
    }
    return false;
  }

  private setBall(ball: number): void {
    this.workBall = ball;
  }

  private breakType(breakChar: string): void {
    if (breakChar === 'p' || breakChar === 'x') {
      this.breakPoint(breakChar, this.workBall);
    } else {
      this.breakPoint(breakChar, 0);
    }
  }
}
