import {EventEntryParam}   from '@/model/interface/EventEntryParam';

export class EventEntry implements EventEntryParam {
  public replyId!: number;
  public msgId!: number;
  public replyStatus!: number;
  public replyNum!: number;
  public sysNum!: number;
  public usrId!: number;
  public usrName!: string;
  public usrNameShort!: string;
  public usrClass!: string;
  public priUsrName!: string;
  public priUsrNameShort!: string;
  public priUsrClass!: string;
  public usrSex!: number;
  public usrBday!: string;
  public usrPref!: number;
  public deptId!: number;
  public deptName!: string;
  public deptNameShort!: string;

  public setByApi(param: {[key: string]: any}): void {
    this.replyId         = param.mr_id;
    this.msgId           = param.m_id;
    this.replyStatus     = param.mr_status;
    this.replyNum        = param.reply_num;
    this.sysNum          = param.sys_num;
    this.usrId           = param.u_id;
    this.usrName         = param.u_pub_n;
    this.usrNameShort    = param.u_pub_s;
    this.usrClass        = param.u_pub_c;
    this.priUsrName      = param.u_pri_n;
    this.priUsrNameShort = param.u_pri_s;
    this.priUsrClass     = param.u_pri_c;
    this.usrSex          = param.u_sex;
    this.usrBday         = param.u_bday;
    this.usrPref         = param.u_pref;
    this.deptId          = param.d_id;
    this.deptName        = param.d_name;
    this.deptNameShort   = param.d_name_s;
  }
}
