



















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam}   from '@/model/interface/MatchDataParam';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class GameStartPanel extends Vue {
  @Prop() private isReverse!: boolean;

  @Emit('gameStart') private gameStart(firstPlayer: number): void { return; }

  private selectPlayer1(): void {
    this.gameStart(1);
  }

  private selectPlayer2(): void {
    this.gameStart(2);
  }
}
