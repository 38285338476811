


























import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import libAxios       from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class ImageUpload extends Vue {
  @Prop() private imageType!: string;
  @Prop() private imageKey!: string;
  @Prop() private imageId!: string;
  private imageSrc: string = '';
  private uploadImageFile!: any;

  @Emit('setImageUrl') private _SetImageUrl(imageUrl: string): void { return; }

  private onFileChange(event: any) {
    const files = event.target.files;

    if (files.length > 0) {
      this.uploadImageFile = files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (event.target != null) {
          this.imageSrc = e.target.result;
        }
      };
      reader.readAsDataURL(this.uploadImageFile);
    }
  }

  private uploadImage() {
    const data = new FormData();
    data.append('image_type', this.imageType);
    data.append('image_id',   this.imageId);
    data.append('image_key',  this.imageKey);
    data.append('image_file', this.uploadImageFile);
    const token = this.$store.getters.getToken();
    libAxios.post('image-upload', token, data)
    .then((response) => {
      this._SetImageUrl(response);
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private deleteImage() {
    this.imageSrc = '';
  }
}
