import { render, staticRenderFns } from "./Game14OneReport.vue?vue&type=template&id=07e533db&scoped=true&"
import script from "./Game14OneReport.vue?vue&type=script&lang=ts&"
export * from "./Game14OneReport.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07e533db",
  null
  
)

export default component.exports