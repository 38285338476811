



















































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import Game9BallBall   from '@/components/score/match/Game9BallBall.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Game9BallBall,
  },
})
export default class Game9BallPlay extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  @Prop() private initBalls!: number[];
  @Prop() private canPush!: boolean;

  private workBalls: number[] = [];

  @Emit('actionType') private playPoint(actionChar: string, balls: number[]): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get isNoIn(): boolean {
    return (this.workBalls.length === 0);
  }

  private get isPoint(): boolean {
    return (this.workBalls.indexOf(9) === -1);
  }

  private get usePush(): boolean {
    const pushOut = this.$store.getters['match/getConfigParam']('pushOut');
    return (pushOut === 1 && this.canPush);
  }

  private get useOption(): boolean {
    const callShot = this.$store.getters['match/getConfigParam']('callShot');
    return (callShot === 1);
  }

  private created(): void {
    // TODO
  }

  private playType(actionChar: string): void {
    this.playPoint(actionChar, this.workBalls);
    this.workBalls = [];
    this.$forceUpdate();
  }
}
