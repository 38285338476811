
























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game10BallConfig extends Vue {
  private breakOrderVal = [
    {text: '勝者', value: 1},
    {text: '交互', value: 2},
    {text: '敗者', value: 3},
    {text: '固定', value: 4},
  ];
  private simpleVal = [
    {text: 'なし', value: 0},
    {text: 'あり', value: 1},
  ];
  private positionVal = [
    {text: 'Normal', value: 1},
    {text: 'Rundom', value: 3},
  ];
  private scoreLvVal = [
    {text: 'Lv1', value: 1},
    {text: 'Lv2', value: 2},
  ];

  private config!: {[key: string]: number};

  private get isDisabled(): boolean {
    return (this.config.isEdit === 0);
  }

  private created(): void {
    this.config = this.$store.getters['match/getConfig']();
  }

  private get _BreakOrder(): number {
    return this.config.breakOrder;
  }

  private set _BreakOrder(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'breakOrder', val: value});
  }

  private get _PushOut(): number {
    return this.config.pushOut;
  }

  private set _PushOut(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'pushOut', val: value});
  }

  private get _3Foul(): number {
    return this.config.threeFoul;
  }

  private set _3Foul(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'threeFoul', val: value});
  }

  private get _CallShot(): number {
    return this.config.callShot;
  }

  private set _CallShot(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'callShot', val: value});
  }

  private get _ScoreLv(): number {
    return this.config.scoreLv;
  }

  private set _ScoreLv(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'scoreLv', val: value});
  }

  private get _10Pos(): number {
    return this.config.tenPos;
  }

  private set _10Pos(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'tenPos', val: value});
  }

  private get _BreakBox(): number {
    return this.config.breakBox;
  }

  private set _BreakBox(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'breakBox', val: value});
  }

  private get _ContinuePlay(): number {
    return this.config.continuePlay;
  }

  private set _ContinuePlay(value: number) {
    this.$store.dispatch('match/setConfigParamAction', {key: 'continuePlay', val: value});
  }
}
