import { render, staticRenderFns } from "./Game14One.vue?vue&type=template&id=5d7fcba1&scoped=true&"
import script from "./Game14One.vue?vue&type=script&lang=ts&"
export * from "./Game14One.vue?vue&type=script&lang=ts&"
import style0 from "./Game14One.vue?vue&type=style&index=0&id=5d7fcba1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d7fcba1",
  null
  
)

export default component.exports