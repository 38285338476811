


































































































































































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventGame}     from '@/model/class/EventGame';
import TournamentBox   from '@/components/event-admin/table/TournamentBox.vue';
import TournamentWin   from '@/components/event-admin/table/TournamentWin.vue';
import TournamentLine   from '@/components/event-admin/table/TournamentLine.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    TournamentBox,
    TournamentWin,
    TournamentLine,
  },
})
export default class Double21 extends Vue {
  @Prop() private eventStepId!: number;
  @Prop() private editSetNums!: number[];
  @Emit('editGame') private _EditGame(key: string): void { return; }

  private created(): void {
    // window.console.log(this.eventStepId);
  }

  private editGame(key: string): void {
    this._EditGame(key);
  }
}
