








import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game14OneResult extends Vue {
  @Prop() private gameParam!: {[key: string]: any};
  @Prop() private isEvent!: boolean;
  private maxLen: number = 0;
  private tableHtml: string = '';
  private param: string = '';

  // 初期化
  private created(): void {
    this.param = this.gameParam.param;
    const params = this.param.split(',');
    const scores = params[0].split(';');
    const line = ['<tr><td>&nbsp;</td>', '<tr>', '<tr>', '<tr>', '<tr>'];
    let player1;
    let player2;

    if (this.isEvent) {
      const tmpPlayer1 = this.$store.getters['event/getPlayer'](this.gameParam.eventPlayerId1);
      player1 = tmpPlayer1.usrName;
      const tmpPlayer2 = this.$store.getters['event/getPlayer'](this.gameParam.eventPlayerId2);
      player2 = tmpPlayer2.usrName;
    } else {
      player1 = 'YOU';
      player2 = this.gameParam.plyerName;
    }
    if ((this.gameParam.isFirst && this.gameParam.first === 1) ||
        (!this.gameParam.isFirst && this.gameParam.first === 2)) {
      line[3] = '<td rowspan="2">' + player1 + '</td>';
      line[4] = '<td rowspan="2">' + player2 + '</td>';
    } else {
      line[3] = '<td rowspan="2">' + player2 + '</td>';
      line[4] = '<td rowspan="2">' + player1 + '</td>';
    }
    let inning = 1;
    for (const score of scores) {
      const buf = score.split(':');
      if (buf.length !== 4) {
        continue;
      }

      line[0] += '<td>' + inning + '</td>';
      for (let i = 0; i < 4; i++) {
        line[i + 1] += '<td>' + buf[i] + '</td>';
      }
      inning++;
    }
    for (let i = 0; i < 5; i++) {
        line[i] += '</tr>';
    }
    this.tableHtml = line[0] + line[3] + line[1] + line[4] + line[2];
  }
}
