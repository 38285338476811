


















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Header       from '@/components/common/Header.vue';
import ProfileData  from '@/components/common/ProfileData.vue';
import {Profile}    from '@/model/class/Profile';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    ProfileData,
  },
})
export default class ProfileCreate extends Vue {
  private isLoading: boolean = true;
  private profile: Profile = new Profile();
  private errorArray: string[] = [];
  private okProfile: boolean = false;
  private pwd1: string = '';
  private pwd2: string = '';
  private dept: string = '';
  private error: string = '';
  // 登録用パラメータ
  private tokenMail: string = '';
  private tokenNo: string = '';
  private tokenPk: number = 0;
  private tokenKey: number = 0;
  private errorCode: number = -1;
  private depts: Array<{value: string, text: string}> = [];
  private loginId!: number;
  private connection: boolean = false;
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: 'Entry Step2', active: true},
  ];

  private get pwd1State(): boolean {
    return this.pwd1.length > 3 ? true : false;
  }

  private get pwd2State(): boolean {
    return (this.pwd2.length > 3 && this.pwd1 === this.pwd2) ? true : false;
  }

  private get deptState(): boolean {
    return this.dept !== '';
  }

  private get canSend(): boolean {
    return this.okProfile && this.pwd2State && this.deptState;
  }

  private checkSend(okProfile: boolean): void {
    this.okProfile = okProfile;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.$router.push({name: 'top'});
        } else {
          this.getTokenKey();
        }
      }).catch((e: Error) => {
        this.$router.push({name: 'error'});
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getTokenKey() {
    this.tokenMail = this.$route.params.token;
    this.tokenNo   = this.$route.params.no;

    const postParam = {
      token_mail: this.tokenMail,
      token_no: this.tokenNo,
    };
    return await libAxios.postNoToken('entry-create', postParam)
    .then((param: {[key: string]: any}) => {
      for (const dept of param.depts.data) {
        this.depts.push({value: dept.d_id, text: dept.d_name});
      }
      this.tokenPk = param.pk;
      this.tokenKey = param.key;
      this.errorCode = 0;
      this.isLoading = false;
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.code) { // エラーコードがあった場合はその画面を出す
        this.errorCode = tmpJson.code;
      } else {
        this.errorCode = 404;
      }
      this.isLoading = false;
    });
  }

  private get refs(): any {
    return this.$refs;
  }

  private async save() {
    this.connection = true;
    const postParam: {[key: string]: any} = {
      pk:       this.tokenPk,
      key:      this.tokenKey,
      u_name_1: this.profile.name1,
      u_name_2: this.profile.name2,
      u_kana_1: this.profile.kana1,
      u_kana_2: this.profile.kana2,
      u_pub_n:  this.profile.pubName,
      u_pub_s:  this.profile.pubNameShort,
      u_pub_c:  this.profile.pubClass,
      u_pri_n:  this.profile.priName,
      u_pri_s:  this.profile.priNameShort,
      u_pri_c:  this.profile.priClass,
      u_bday:   this.profile.birthDayY + ('00' + this.profile.birthDayM).slice(-2),
      u_sex:    this.profile.sex,
      u_pref:   this.profile.pref,
      pwd:      this.pwd1,
      dept:     this.dept,
    };
    return await libAxios.postNoToken('entry-store', postParam)
    .then((param: {[key: string]: any}) => {
      this.loginId = param.pk;
      this.errorCode = -2;
      this.connection = false;
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        for (const err of Object.keys(tmpJson.validate)) {
          this.errorArray.push(err);
        }
        this.refs.profile_data.checkErrMsg();
      } else if (tmpJson.code === 1002) {
        alert('入力されたメールアドレスで5回以上失敗したため処理を中止します\n別のメールアドレスを使用するか管理者にお問い合わせください');
        this.$router.push({name: 'top'});
      } else {
        this.$router.push({name: 'error'});
      }
      this.connection = false;
    });
  }
}
