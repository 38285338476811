





























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class DartsPointPlate extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  @Prop() private isTwoColor!: boolean;

  private get scorePlayer1(): number | string {
    const winner = this.$store.getters['darts/getWinner']();
    const score = this.$store.getters['darts/getPlayer1Score']();

    if (winner === this.C_WINNER_TBD) {
      if (score < 0) {
        return 'Bust';
      } else if (score === 0) {
        return 'Win';
      } else {
        return score;
      }
    } else if (winner === this.C_WINNER_P1) {
      return 'Win';
    } else if (winner === this.C_WINNER_P2) {
      return score;
    } else if (winner === this.C_WINNER_NONE) {
      return 'Draw';
    } else {
      return 0;      // ERROR
    }
  }

  private get scorePlayer2(): number | string {
    const winner = this.$store.getters['darts/getWinner']();
    const score = this.$store.getters['darts/getPlayer2Score']();

    if (winner === this.C_WINNER_TBD) {
      if (score < 0) {
        return 'Bust';
      } else if (score === 0) {
        return 'Win';
      } else {
        return score;
      }
    } else if (winner === this.C_WINNER_P2) {
      return 'Win';
    } else if (winner === this.C_WINNER_P1) {
      return score;
    } else if (winner === this.C_WINNER_NONE) {
      return 'Draw';
    } else {
      return 0;      // ERROR
    }
  }

  private get raceToPlayer1(): number {
    return this.$store.getters['darts/getPlayer1RaceTo']();
  }

  private get raceToPlayer2(): number {
    return this.$store.getters['darts/getPlayer2RaceTo']();
  }

  private get btnColorP1(): string {
    const score  = this.$store.getters['darts/getPlayer1Score']();
    const status = this.$store.getters['darts/getGameStatus']();
    const isFirst = this.isFirst;
    if (status < this.C_STATUS_PLAY) {
      return 'btn-secondary';
    } else {
      if (isFirst) {
        if (score <= 60) {
          return 'btn-outline-danger';
        } else if (score <= 180) {
          return 'btn-outline-warning';
        } else {
          return 'btn-outline-primary';
        }
      } else {
        if (score <= 60) {
          return 'btn-danger disabled';
        } else if (score <= 180) {
          return 'btn-warning disabled';
        } else {
          return 'btn-primary disabled';
        }
      }
    }
  }

  private get btnColorP2(): string {
    const score  = this.$store.getters['darts/getPlayer2Score']();
    const status = this.$store.getters['darts/getGameStatus']();
    const isFirst = this.isFirst;
    if (status < this.C_STATUS_PLAY) {
      return 'btn-secondary';
    } else {
      if (!isFirst) {
        if (score <= 60) {
          return 'btn-outline-danger';
        } else if (score <= 180) {
          return 'btn-outline-warning';
        } else {
          return 'btn-outline-primary';
        }
      } else {
        if (score <= 60) {
          return 'btn-danger disabled';
        } else if (score <= 180) {
          return 'btn-warning disabled';
        } else {
          return 'btn-primary disabled';
        }
      }
    }
  }
}
