


















































































import {Component, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue'; // @ is an alias to /src
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class ProfileMail extends Vue {
  private mail1: string = '';
  private errMail1: string = '';
  private mail2: string = '';
  private token: string = '';
  private errToken: string = '';
  private stepNum: number = 0;
  private connection: boolean = false;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: '個人メニュー', to: { name: 'personal-menu' }},
    { text: 'メール登録', active: true},
  ];

  private get canSend(): boolean {
    if (this.mail1 !== '' && this.mail1 === this.mail2) {
      return true;
    }
    return false;
  }

  private get canSendToken(): boolean {
    if (this.mail1 !== '' && this.mail1 === this.mail2) {
      return true;
    }
    return false;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        // ログインしているのでトップに飛ばす
        if (isLogin) {
          this.stepNum = 1;
        } else {
         this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async sendToken() {
    this.connection = true;
    this.errToken = '';
    const postParam = {
      token: this.token,
    };
    const token = this.$store.getters.getToken();
    return await libAxios.put('mail', token, postParam)
    .then(() => {
      this.stepNum = 3;
      this.connection = false;
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        for (const err of Object.keys(tmpJson.validate)) {
          switch (err) {
            case 'mail':
              this.errToken = tmpJson.validate[err];
              break;
          }
        }
        this.connection = false;
      } else {
        this.$router.push({name: 'error'});
      }
    });
  }

   private async sendMail() {
    this.connection = true;
    this.errMail1 = '';
    const postParam = {
      mail: this.mail1,
    };
    const token = this.$store.getters.getToken();
    return await libAxios.post('mail', token, postParam)
    .then(() => {
      this.stepNum = 2;
      this.connection = false;
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        for (const err of Object.keys(tmpJson.validate)) {
          switch (err) {
            case 'mail':
              this.errMail1 = tmpJson.validate[err];
              break;
          }
        }
        this.connection = false;
      } else {
        this.$router.push({name: 'error'});
      }
    });
  }
}
