




























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam}   from '@/model/interface/MatchDataParam';
import NumberUpDownButton from '@/components/score/common/NumberUpDownButton.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    NumberUpDownButton,
  },
})
export default class ConfigRaceTo extends Vue {
  @Prop() private isReverse!: boolean;
  private gameType: number = this.$store.getters['match/getGameType']();
  private config!: {[key: string]: number};

  private get isDisabled(): boolean {
    return (this.config.isEdit === 0);
  }

  get player1RaceTo(): number {
    return this.$store.getters['match/getPlayer1RaceTo']();
  }

  get player2RaceTo(): number {
    return this.$store.getters['match/getPlayer2RaceTo']();
  }

  private created(): void {
    this.config = this.$store.getters['match/getConfig']();
  }

  private addPlayer1RaceTo(addNum: number): void {
    this.addRaceTo(true, addNum);
  }

  private addPlayer2RaceTo(addNum: number): void {
    this.addRaceTo(false, addNum);
  }

  private addRaceTo(is1st: boolean, addNum: number): void {
    this.$store.dispatch('match/addRaceToAction', {isFirst: is1st, addPoint: addNum});
  }
}
