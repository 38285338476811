























import {Component, Emit, Prop, Watch, Vue} from 'vue-property-decorator';
import {PlaceParam} from '@/model/interface/PlaceParam';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class PlaceTableSelect extends Vue {
  @Prop() private defaultTable!: number;
  private deptID: number = 0;
  private deptOptions: Array<{value: number, text: string, numOfTable: number}> = [];
  private tableIndex: number = 0;
  private tableOptions: Array<{value: number, text: number}> = [];
  private usrType!: number;
  private tableNum!: number;

  private placeSelected: PlaceParam = {id: 0, name: '', tableNum: 0};

  @Emit('setPlaceSelected') private setPlaceSelected(place: PlaceParam): void { return; }

  private get canSelectDept(): boolean {
    return (this.deptOptions.length > 1);
  }

  private get canSelectTable(): boolean {
    if (this.tableOptions.length > 1) {
      if (this.usrType !== this.C_MODE_STAFF || this.tableNum === 0) {
        return true;
      }
    }
    return false;
  }

  private created(): void {
    this.usrType = this.$store.getters.getUsrType();
    this.tableNum = this.$store.getters.getTableNum();

    const tmpDepts = this.$store.getters.getDepartments();
    for (const tmpDept of tmpDepts) {
      if (tmpDept.isUsePlace && tmpDept.numOfTable > 0) {
        this.deptOptions.push({value: tmpDept.id, text: tmpDept.name, numOfTable: tmpDept.numOfTable});
      }
    }
    const tmpLength = this.deptOptions.length;
    if (tmpLength === 0) {
      // TODO
    } else {
      this.deptID = this.deptOptions[0].value;
      this.placeSelected.id = this.deptID;
      this.placeSelected.name = this.deptOptions[0].text;
    }
  }

  @Watch('defaultTable')
  private onTableChange() {
    window.console.log(this.defaultTable);
  }

  @Watch('deptID')
  private onDeptChange(newValue: number, oldValue: number): void {
    this.tableOptions = [];
    let numOfTable = 0;
    for (const tmpDept of this.deptOptions) {
      if (tmpDept.value === newValue) {
        numOfTable = tmpDept.numOfTable;
        break;
      }
    }
    for (let i = 1; i <= numOfTable; i++) {
      this.tableOptions.push({value: i, text: i});
    }
    if ( this.tableNum !== 0 && this.tableIndex === 0) {
      this.tableIndex = this.tableNum;
    } else {
      this.tableIndex = 1;
    }
    this.placeSelected.tableNum = this.tableIndex;
    this.setPlaceSelected(this.placeSelected);
  }
}
