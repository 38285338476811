





































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class GameRotationBall extends Vue {
  @Prop() private isFirst!: boolean;
  @Prop() private workBalls!: number[];
  @Prop() private initBalls!: number[];

  @Emit('update:workBalls') private updateBalls(nums: number[]): void { return; }

  get _workBalls(): number[] {
    return this.workBalls;
  }

  set _workBalls(value: number[]) {
    this.updateBalls(value);
  }

  private buttonColor(num: number): string {
    if (this.initBalls[num] === 1) {
      return 'primary';
    } else if (this.initBalls[num] === 2) {
      return 'success';
    } else {
      let score: number;
      let raceTo: number;
      let ballColor: string;
      if (this.isFirst) {
        score = this.$store.getters['match/getPlayer1Score']();
        raceTo = this.$store.getters['match/getPlayer1RaceTo']();
        ballColor = 'outline-primary';
      } else {
        score = this.$store.getters['match/getPlayer2Score']();
        raceTo = this.$store.getters['match/getPlayer2RaceTo']();
        ballColor = 'outline-success';
      }

      if ( score + num + 1 - raceTo >= 0 ) {
        ballColor = 'outline-danger';
      }
      return ballColor;
    }
  }

  private isDisabled(num: number): boolean {
     if (this.initBalls[num] !== 0) {
       return true;
    } else {
       return false;
    }
  }
  // 初期化
  /*
  private created(): void {
    const numChrs = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬', '⑭', '⑮'];

    let score: number;
    let raceTo: number;
    let ballColor: string;
    if (this.isFirst) {
      score = this.$store.getters['match/getPlayer1Score']();
      raceTo = this.$store.getters['match/getPlayer1RaceTo']();
      ballColor = 'outline-primary';
    } else {
      score = this.$store.getters['match/getPlayer2Score']();
      raceTo = this.$store.getters['match/getPlayer2RaceTo']();
      ballColor = 'outline-success';
    }

    for (let i = 0; i < 15; i++) {
      if ( score + i + 1 - raceTo >= 0 ) {
        this.balls.push({num: numChrs[i], state: false, variant: 'outline-danger'});
      } else {
        this.balls.push({num: numChrs[i], state: false, variant: ballColor});
      }
    }
  }

  private setBallStates(): void {
    const retBalls: number[] = [];
    for (let i = 0; i < 15; i++) {
      if (this.balls[i].state) {
        retBalls.push(1);
      } else {
        retBalls.push(0);
      }
    }
    this.updateBalls(retBalls);
  }
  */
}
