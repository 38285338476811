






















































import {Component, Emit, Prop, Watch, Vue} from 'vue-property-decorator';
import {EventStep}     from '@/model/class/EventStep';
import {EventGame}     from '@/model/class/EventGame';
import {EventPlayer}   from '@/model/class/EventPlayer';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class EventGameResult extends Vue {
  @Prop() private currentStep!: EventStep;
  @Prop() private currentGame!: EventGame;
  private player1Name: string = '';
  private player2Name: string = '';
  private player1RaceTo: number = 0;
  private player2RaceTo: number = 0;
  private player1Score: number = 0;
  private player2Score: number = 0;
  private winnerType: number = 0;
  private noGameFlg: boolean = false;

  @Emit('updateGame') private _UpdateGame(stepId: number, gameID: number, param: {[key: string]: any}): void { return; }

  @Watch('winnerType')
  private onWinnerChange(newValue: number, oldValue: number): void {
    if (newValue === 1) {
      this.player1Score = this.player1RaceTo;
    } else if (newValue === 2) {
      this.player2Score = this.player2RaceTo;
    }
  }

  private created(): void {
    if (this.currentGame === null) {
      return;
    }
    const player1 = this.$store.getters['event/getPlayer'](this.currentGame.eventPlayerId1);
    this.player1Name = player1.usrName;
    const player2 = this.$store.getters['event/getPlayer'](this.currentGame.eventPlayerId2);
    this.player2Name = player2.usrName;
    this.player1RaceTo = this.currentGame.raceTo1;
    this.player2RaceTo = this.currentGame.raceTo2;
    this.player1Score = this.currentGame.score1;
    this.player2Score = this.currentGame.score2;
  }

  private saveGame(): void {
    if (this.winnerType === 0) {
      if (!confirm('引分でいいですか')) {
        return;
      }
    }
    const postParam = {
      eg_status: this.C_GAME_STATUS_END,
      score_1: this.player1Score,
      score_2: this.player2Score,
      winner: this.winnerType,
      edit_dt: this.currentGame.editDateTime,
    };
    if (this.noGameFlg && this.winnerType !== 0) {
      postParam.winner += 2;
    }
    this._UpdateGame(this.currentGame.eventStepId, this.currentGame.eventGameId, postParam);
  }
}
