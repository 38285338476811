







































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class NumberUpDownButton extends Vue {
  @Prop() private gameType!: number;
  private scale: {min: number, mid: number, max: number} = {min: 0, mid: 0, max: 0};

  @Emit('addNumber') private addNumber(num: number): void { return; }

  private created(): void {
    if (this.gameType === this.C_NUM_9B || this.gameType === this.C_NUM_10B) {
      this.scale = this.C_SCORE_SCALE_S;
    } else if (this.gameType === this.C_NUM_14_1 || this.gameType === this.C_NUM_ROTE) {
      this.scale = this.C_SCORE_SCALE_L;
    } else if (this.gameType === this.C_NUM_FREE_POINT) {
      this.scale = this.C_SCORE_SCALE_L;
    } else if (this.gameType === this.CD_NUM_01) {
      this.scale = this.C_SCORE_SCALE_L;
    }
  }

  private pushNum(addNum: number) {
    this.addNumber(addNum);
  }
}
