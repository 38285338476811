
















import {Component, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Error extends Vue {
  private gotoTop(): void {
    this.$router.push({name: 'top'});
  }

  private cacheClear(): void {
    window.localStorage.removeItem('profile');
    window.localStorage.removeItem('currentGame');
    this.$router.push({name: 'top'});
  }
}
