











































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game14OneBall extends Vue {
  @Prop() private workBall!: number;
  @Prop() private currentBall!: number;
  @Prop() private isFirst!: boolean;
  @Prop() private isBreak!: boolean;

  @Emit('setBall') private setBall(nums: number): void { return; }

  get _WorkBall(): number {
    return this.workBall;
  }

  set _WorkBall(value: number) {
    this.setBall(value);
  }

  private buttonColor(num: number): string {
    const scoreP1 = this.$store.getters['match/getPlayer1Score']();
    const scoreP2 = this.$store.getters['match/getPlayer2Score']();
    const raceToP1 = this.$store.getters['match/getPlayer1RaceTo']();
    const raceToP2 = this.$store.getters['match/getPlayer2RaceTo']();
    const diff1 = raceToP1 - scoreP1;
    const diff2 = raceToP2 - scoreP2;
    const isFirst = this.isFirst;
    if (num < this.currentBall) {
      return 'secondary';
    } else {
      const diff = ((isFirst) ? diff1 : diff2) + this.currentBall - num;
      if (diff < 0) {
        return 'secondary';
      } else if (diff === 0) {
        return 'outline-danger';
      } else if (diff <= 5) {
        return 'outline-warning';
      } else {
        return 'outline-info';
      }
    }
  }

  private isDisabled(num: number): boolean {
    const scoreP1 = this.$store.getters['match/getPlayer1Score']();
    const scoreP2 = this.$store.getters['match/getPlayer2Score']();
    const raceToP1 = this.$store.getters['match/getPlayer1RaceTo']();
    const raceToP2 = this.$store.getters['match/getPlayer2RaceTo']();
    const diff1 = raceToP1 - scoreP1 + this.currentBall;
    const diff2 = raceToP2 - scoreP2 + this.currentBall;
    const isFirst = this.isFirst;

    let maxNum: number = (isFirst) ? diff1 : diff2 ;

    if (this.isBreak && maxNum > 5) {
      maxNum = 5;
    }

    if (num > maxNum) {
      return true;
    } else {
      return (num < this.currentBall);
    }
  }

  // 初期化
  private created(): void {
    // TODO
  }
}
