


























































import {Component, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Header extends Vue {
  private messageList: Array<{[key: string]: any}> = [];

  private get usrName(): string {
    return this.$store.getters.getUsrName();
  }
  private get usrType(): number {
    return this.$store.getters.getUsrType();
  }

  private created(): void {
    this.messageList = this.$store.getters.getMessage();
  }

  private toEvent() {
    this.$store.dispatch('searchEventAction', {});
  }
}
