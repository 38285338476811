import {EventPlayerParam}   from '@/model/interface/EventPlayerParam';

export class EventPlayer implements EventPlayerParam {
  public eventPlayerId!: number;
  public eventPlayerType!: number;
  public eventPlayerStatus!: number;
  public eventId!: number;
  public eventStepId!: number;
  public parentId!: number;
  public usrId!: number;
  public usrName!: string;
  public usrNameShort!: string;
  public usrClass!: string;
  public usrSex!: number;
  public usrBday!: string;
  public deptId!: number;
  public deptName!: string;
  public deptNameShort!: string;
  public setNumber!: number;
  public setOrder!: number;
  public setPosition!: number;
  public point!: number;
  public runsScore!: number;
  public runsAgainst!: number;
  public deleteFlag!: boolean;
  public editId!: number;
  public editDateTime!: string;

  public setByApi(param: {[key: string]: any}): void {
    this.eventPlayerId     = param.ep_id;
    this.eventPlayerType   = param.ep_type;
    this.eventPlayerStatus = param.ep_status;
    this.eventId           = param.e_id;
    this.eventStepId       = param.es_id;
    this.parentId          = param.ep_p_id;
    this.usrId             = param.u_id;
    this.usrName           = param.u_name;
    this.usrNameShort      = param.u_name_s;
    this.usrClass          = param.u_class;
    this.usrSex            = param.u_sex;
    this.usrBday           = param.u_bday;
    this.deptId            = param.d_id;
    this.deptName          = param.d_name;
    this.deptNameShort     = param.d_name_s;
    this.setNumber         = param.set_num;
    this.setOrder          = param.set_odr;
    this.setPosition       = param.set_pos;
    this.point             = param.point;
    this.runsScore         = param.runs_s;
    this.runsAgainst       = param.runs_a;
    this.deleteFlag        = param.del_flg;
    this.editId            = param.edit_id;
    this.editDateTime      = param.edit_dt;
  }
}
