


















































































import {Component, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue'; // @ is an alias to /src
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class EntryStart extends Vue {
  private title: string = '登録';
  private mail1: string = '';
  private errMail1: string = '';
  private mail2: string = '';
  private isAgree: boolean = false;
  private canEntry: boolean = true;
  private resultMsg: string = '';
  private isFin: boolean = false;
  private entryID: string = '';
  private depts: Array<{msg: string}> = [];
  private connection: boolean = false;
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: 'Entry', active: true},
  ];

  private get canSend(): boolean {
    if (this.isAgree && this.mail1 !== '' && this.mail1 === this.mail2) {
      return true;
    }
    return false;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        // ログインしているのでトップに飛ばす
        if (isLogin) {
          this.$router.push({name: 'top'});
        } else {
          this.getEntryIndex();
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getEntryIndex() {
    return await libAxios.getNoToken('entry', {})
    .then((param: {[key: string]: any}) => {
      for (const dept of param.depts.data) {
        this.depts.push({msg: dept.d_name});
      }

      if (param.status === 'OK') {
        this.title = '登録';
        this.canEntry = true;
      } else {
        this.title = '登録(一時中止)';
        this.canEntry = false;
      }
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.message) {
        alert(tmpJson.message);
        this.$router.push({name: 'top'});
      } else {
        this.$router.push({name: 'error'});
      }
    });
  }

  private async sendMail() {
    this.connection = true;
    this.errMail1 = '';
    const postParam = {
      mail: this.mail1,
    };
    return await libAxios.postNoToken('entry', postParam)
    .then((param: {[key: string]: any}) => {
      if (param.message === 're-entry') {
        this.resultMsg = '登録用URLを記載したメールを再送信しました。<br/>以前のURLは使用できません';
      } else {
        this.resultMsg = '登録用URLを記載したメールを送信しました。<br/>問い合わせID:' + param.pk;
      }

      this.isFin = true;
      this.connection = false;
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        for (const err of Object.keys(tmpJson.validate)) {
          switch (err) {
            case 'mail':
              this.errMail1 = tmpJson.validate[err];
              break;
          }
        }
        this.connection = false;
      } else if (tmpJson.code === 1001) {
        alert('すでに登録されたメールアドレスか登録できないメールアドレスです。\n別のメールアドレスを使用するか管理者にお問い合わせください');
        this.$router.push({name: 'top'});
      } else if (tmpJson.code === 1002) {
        alert('入力されたメールアドレスで5回以上失敗したため処理を中止します\n別のメールアドレスを使用するか管理者にお問い合わせください');
        this.$router.push({name: 'top'});
      } else {
        this.$router.push({name: 'error'});
      }
    });
  }
}
