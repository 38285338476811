



















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game10BallResult extends Vue {
  @Prop() private gameParam!: {[key: string]: any};
  @Prop() private isEvent!: boolean;
  private maxLen: number = 0;
  private startDateTime: string = '';
  private endDateTime: string = '';
  private player1: string = '';
  private player2: string = '';
  private results1: number[] = [];
  private results2: number[] = [];
  private param: string[] = [];
  private detailHtml: string = '';

  // 初期化
  private created(): void {
    let player1;
    let player2;
    const results1: number[] = [];
    const results2: number[] = [];
    let first = true;
    let result: string;
    this.startDateTime = '開始:' + this.gameParam.startDateTime;
    this.endDateTime = '終了:' + this.gameParam.endDateTime;
    if (this.isEvent) {
      const tmpPlayer1 = this.$store.getters['event/getPlayer'](this.gameParam.eventPlayerId1);
      player1 = tmpPlayer1.usrName;
      const tmpPlayer2 = this.$store.getters['event/getPlayer'](this.gameParam.eventPlayerId2);
      player2 = tmpPlayer2.usrName;
    } else {
      player1 = 'YOU';
      player2 = this.gameParam.plyerName;
      first = this.gameParam.isFirst;
    }
    result = this.gameParam.result;

    this.maxLen = result.length;
    for ( let i = 0; i < this.maxLen; i++) {
      const tmpChar = result.charAt(i);
      if (tmpChar === 'a') {
        results1[i] = 1;
        results2[i] = 0;
      } else if (tmpChar === 'A') {
        results1[i] = 2;
        results2[i] = 0;
      } else if (tmpChar === 'b') {
        results1[i] = 0;
        results2[i] = 1;
      } else if (tmpChar === 'B') {
        results1[i] = 0;
        results2[i] = 2;
      }
    }
    if (first) {
      this.player1 = player1;
      this.player2 = player2;
    } else {
      this.player1 = player2;
      this.player2 = player1;
    }
    this.results1 = results1;
    this.results2 = results2;
    this.param = this.gameParam.param.split(',');
  }

  private showDetail(inningNum: number) {
    const inningData = this.param[inningNum - 1];
    const turnData = inningData.split(';');
    const turnLen = turnData.length;
    if (turnLen < 2) {// 最低でも2はある
      return;
    }

    let isFirst;
    if (turnData[0] === 'A') {
      isFirst = true;
    } else if (turnData[0] === 'B') {
      isFirst = false;
    } else {
      return;
    }

    let tmpDetailHtml = '<tr><td>' + this.player1 + '</td><td>' + this.player2 + '</td></tr>';
    for (let i = 1; i < turnLen; i++) {
      let orgfirst = isFirst;
      const tmpData = turnData[i].split(':');
      let tmpHtml = '';
      let tmpBalls = '';

      if (tmpData.length >= 2 && tmpData[1] !== '') {
        tmpBalls = tmpData[1]; // replaceBall(tmp_data[1])
      }
      switch (tmpData[0]) {
        case 'a': // ブレイクエース
            tmpHtml = tmpBalls + '⑩番IN。ブレイクエースでPoint。';
            break;
        case 'c': // ブレイク交代
            tmpHtml = 'ブレイク失敗でブレイク交代';
            isFirst = !isFirst;
            break;
        case 'D': // フロック ポケットイン
            tmpHtml = tmpBalls + 'IN。目的も成功';
            break;
        case 'E': // フロック ポケットイン
            tmpHtml = tmpBalls + 'IN。目的は失敗';
            break;
        case 'F': // foul
            if (tmpBalls !== '') {
                tmpHtml = tmpBalls + 'INするが';
            }
            tmpHtml += 'ファールで交代';
            isFirst = !isFirst;
            break;
        case 'f': // BREAK FOUL
            if (tmpBalls !== '') {
                tmpHtml = tmpBalls + 'INするが';
            }
            tmpHtml += 'BREAK SCRATCHで交代';
            isFirst = !isFirst;
            break;
        case 'G': // SCRATCH
            tmpHtml = 'SCRATCHで交代';
            isFirst = !isFirst;
            break;
        case 'g': // BREAK SCRATCH
            tmpHtml = 'BREAK SCRATCHで交代';
            isFirst = !isFirst;
            break;
        case 'H': // USH OUT
            if (tmpBalls !== '') {
                tmpHtml = tmpBalls + 'IN。';
            }
            tmpHtml += 'プッシュアウトを選択';
            isFirst = !isFirst;
            break;
        case 'i': // イリーガル
            tmpHtml = 'イリーガルブレイク';
            isFirst = !isFirst;
            break;
        case 'J': // OPTIONからのplay
            tmpHtml = 'プレイを選択';
            break;
        case 'K': // OPTIONからのパス
            tmpHtml = 'パスを選択';
            isFirst = !isFirst;
            break;
        case 'L': // フロック ポケットイン
            tmpHtml = tmpBalls + 'フロックIN。';
            break;
        case 'M': // MISS
            tmpHtml = 'MISS';
            isFirst = !isFirst;
            break;
        case 'm': // ブレイクNOIN
            tmpHtml = 'ブレイクNO-IN';
            isFirst = !isFirst;
            break;
        case 'O': // オプション
            tmpHtml = tmpBalls + 'INでオプション';
            isFirst = !isFirst;
            break;
        case 'P': // POINT ポケットイン
            tmpHtml = tmpBalls + 'IN。';
            break;
        case 'p': // ブレイクIN PUSHOUTが選べる
            tmpHtml = 'ブレイクで' + tmpBalls + 'IN';
            break;
        case 'Q': // Lucky-win
            tmpHtml = tmpBalls + 'フロックINでPoint。';
            break;
        case 'R': // RUNOUTでPoint
            tmpHtml = tmpBalls + '⑩番IN。ランアウトでPoint';
            break;
        case 'S': // BREAK SCRATCH
            tmpHtml = 'SAFETY';
            isFirst = !isFirst;
            break;
        case 'T': // RUNOUTでPoint
            tmpHtml = '3ファールでPoint';
            break;
        case 'U': // RUNOUTでPoint
            tmpHtml = tmpBalls + '⑩番IN。フロックランアウトでPoint';
            break;
        case 'V': // 相手側のPoint
            orgfirst = !orgfirst; // ここだけ例外
            tmpHtml = 'Point。';
            break;
        case 'W': // Point
            tmpHtml = tmpBalls + '⑩番INでPoint。';
            break;
        case 'X': // イリーガルからの実行 PUSHOUTが選べない
            tmpHtml = 'プレイを選択';
            break;
        case 'Y': // イリーガルからのパス PUSHOUTが選べる
            tmpHtml = 'パスを選択';
            isFirst = !isFirst;
            break;
      }
      if (orgfirst) {
        tmpDetailHtml += '<tr><td>' + tmpHtml + '</td><td>&nbsp;</td></tr>';
      } else {
        tmpDetailHtml += '<tr><td>&nbsp;</td><td>' + tmpHtml + '</td></tr>';
      }
    }
    this.detailHtml = tmpDetailHtml;
  }
}
