




























































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class PointPlayer extends Vue {
  @Prop() private id!: number;
  @Prop() private name!: string;
  @Prop() private handicap!: string;
  @Prop() private etc!: string;
  @Prop() private isFix!: boolean;
  @Prop() private point!: number;
  @Prop() private index!: number;
  @Prop() private playerLen!: number;
  @Prop() private useHandi!: boolean;
  @Prop() private useEtc!: boolean;
  private usrType!: number;
  private usrId!: number;

  @Emit('update:name') private updateName(val: string): void { return; }
  @Emit('update:handicap') private updateHandicap(val: string): void { return; }
  @Emit('update:etc') private updateEtc(val: string): void { return; }
  @Emit('update:point') private updatePoint(val: number): void { return; }
  @Emit('openModalSetPlayer') private openModalSetPlayer(num: number): void { return; }
  @Emit('updown') private updown(num: number, moveType: number): void { return; }

  private get isUseUp(): boolean {
    if (this.index === 0) {
      return true;
    } else {
      return false;
    }
  }

  private get isUseDown(): boolean {
    if ((this.index + 1) === this.playerLen) {
      return true;
    } else {
      return false;
    }
  }

  private get isUseDel(): boolean {
    if (this.playerLen <= 2 || this.isFix) {
      return true;
    } else {
      return false;
    }
  }

  private get _Id(): number {
    return this.id;
  }

  private get _Name(): string {
    return this.name;
  }

  private set _Name(value: string) {
    this.updateName(value);
  }

  private get _Handicap(): string {
    return this.handicap;
  }

  private set _Handicap(value: string) {
    this.updateHandicap(value);
  }

  private get _Etc(): string {
    return this.etc;
  }

  private set _Etc(value: string) {
    this.updateEtc(value);
  }

  private get _Point(): number {
    return this.point;
  }

  private set _Point(value: number) {
    this.updatePoint(value);
  }

  private get canChange(): boolean {
    return this.usrType !== this.C_MODE_GUEST && this.usrId !== this.id;
  }

  private created(): void {
    this.usrType = this.$store.getters.getUsrType();
    this.usrId = this.$store.getters.getUsrId();
  }


}
