






import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventGame}     from '@/model/class/EventGame';
import {EventPlayer}   from '@/model/class/EventPlayer';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class TournamentWin extends Vue {
  @Prop() private stepId!: number;
  @Prop() private setNum!: number;
  @Prop() private tnmId!: string;
  @Prop() private boxResult!: string;
  @Prop() private boxType!: string;
  private lineStyle: string = '';
  private name: string = '';
  private dept: string = '';

  private created(): void {
    const key = this.stepId + '_' + this.setNum + this.tnmId;
    this.lineStyle = 'type' + this.boxResult + '-' + this.boxType;
    const tmpGame: EventGame = this.$store.getters['event/getGame'](key);
    if (tmpGame === undefined) {
      return;
    }
    if (tmpGame.eventGameStatus === this.C_GAME_STATUS_FIX) {
      if (tmpGame.winner === 1 || tmpGame.winner === 3) {
        if (tmpGame.eventPlayerId1 === 0) {
          this.name = 'no player';
          this.dept = '';
        } else {
          const player1: EventPlayer = this.$store.getters['event/getPlayer'](tmpGame.eventPlayerId1);
          this.name = player1.usrName;
          this.dept = player1.deptName;
        }
      } else if (tmpGame.winner === 2 || tmpGame.winner === 4) {
        if (tmpGame.eventPlayerId2 === 0) {
          this.name = 'no player';
          this.dept = '';
        } else {
          const player2: EventPlayer = this.$store.getters['event/getPlayer'](tmpGame.eventPlayerId2);
          this.name = player2.usrName;
          this.dept = player2.deptName;
        }
      }
    }
  }
}
