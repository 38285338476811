import {Component, Vue} from 'vue-property-decorator';
import {Department} from '@/model/class/department';
import {User}       from '@/model/class/user';
import libAxios     from '@/mixin/LibAxios';

declare module 'vue/types/vue' {
  interface Vue {
    _checkLogin(): any;
    _exeLogin(param: any[]): void;
    _checkApiError(errorMsg: string): any;
    _getResumeData(gameType: number): any;
    _getConfig(dateTime: any, format: string): {[key: string]: number};
    _formatTimeSpan(sec: number): string;
    _formatDate(dateTime: any, format: string): string;
    _shuffle(array: any[]): any[];
    _bitCalc(num: number, key: number): boolean;
    _replaceBall(str: string): string;
    _vd(obj: any): void;
  }
}
@Component
export default class LibCommon extends Vue {
  public async _checkLogin() {
    const usrType: number = this.$store.getters.getUsrType();
    if (usrType !== this.C_MODE_GUEST) {
      return true;
    }
    const saveProfile: string|null = window.localStorage.getItem('profile');
    if (saveProfile) {
      const tmpJson = JSON.parse(saveProfile);
      return await this._reLogin(tmpJson.token, tmpJson.usrId)
      .then(() => { // 正常ならこっちにくる
        const tmpDepts = [];
        for (const dept of tmpJson.departments) {
          const tmpUsrs = [];
          for (const usr of dept.users) {
            tmpUsrs.push(
              new User(
                usr.id,
                usr.name,
                usr.nameShort,
              ),
            );
          }
          tmpDepts.push(
            new Department(
              dept.id,
              dept.name,
              dept.numOfTable,
              dept.isUsePlace,
              tmpUsrs,
            ),
          );
        }

        tmpJson.departments = tmpDepts;
        // 年 + 月 から年齢計算
        const today = new Date();
        let tmpAge = today.getFullYear() * 100 + (today.getMonth() + 1);
        tmpAge -= parseInt(tmpJson.birthday, 10);
        tmpJson.age = Math.floor(tmpAge / 100);

        this.$store.dispatch('loginAction', tmpJson);
        return true;
      }).catch((e: Error) => {
        window.console.log(e); // throwしない
        window.localStorage.removeItem('profile');
        this.$router.push({name: 'login'});
      });
    } else { // GUEST
      return false;
    }
  }

  public _checkApiError(errorMsg: string): any {
    try {
      if (errorMsg === 'SYSTEM ERROR' || errorMsg === 'NETWORK ERROR') {
        this.$router.push({name: 'error'});
        return {};
      } else {
        return JSON.parse( errorMsg );
      }
    } catch (e) {
      window.console.log(e);
      this.$router.push({name: 'error'});
    }
  }

  public _getResumeData(gameType: number): any {
    const tmpGame: string|null = window.localStorage.getItem('currentGame');
    if (tmpGame) {
      const tmpJson = JSON.parse(tmpGame);
      if (tmpJson.gameType === gameType) {
        return tmpJson;
      } else {
        if (tmpJson.gameType === this.C_NUM_BOWLARD) {
          this.$router.push({name: 'score-bowlard'});
        } else if (tmpJson.gameType === this.C_NUM_POINT) {
          this.$router.push({name: 'score-point'});
        } else if (tmpJson.gameType === this.C_NUM_FREE_POINT) {
          this.$router.push({name: 'score-free-point'});
        } else if (tmpJson.gameType === this.C_NUM_9B) {
          this.$router.push({name: 'score-9ball'});
        } else if (tmpJson.gameType === this.C_NUM_10B) {
          this.$router.push({name: 'score-10ball'});
        } else if (tmpJson.gameType === this.C_NUM_14_1) {
          this.$router.push({name: 'score-14one'});
        } else if (tmpJson.gameType === this.C_NUM_ROTE) {
          this.$router.push({name: 'score-rotation'});
        }
      }
    }
    return '';
  }

  public _getConfig(defaultConfig: {[key: string]: number}, gameKey: string): {[key: string]: number} {
    const tmpKey: string = 'cfg_' + gameKey;
    const tmpJson = localStorage.getItem(tmpKey);
    let config: {[key: string]: number} = {};
    let tmpConfig: {[key: string]: number} = {};
    if (tmpJson) {
      try {
        tmpConfig = JSON.parse(tmpJson);
      } catch (err) {
        localStorage.removeItem(tmpKey);
      }
    }

    if (Object.keys(tmpConfig).length === 0) {
      config = defaultConfig;
    } else {
      for (const key of Object.keys(defaultConfig)) {
        if (tmpConfig[key] === undefined) {
          config[key] = defaultConfig[key];
        } else {
          config[key] = tmpConfig[key];
        }
      }
    }
    return config;
  }

  public _formatTimeSpan(sec: number): string {
    const hh = Math.floor(sec / 3600);
    const mm = Math.floor((sec % 3600) / 60);
    const ss = sec % 60;

    let timeSpan = ('0' + hh).slice(-2);
    timeSpan += ':' + ('0' + mm).slice(-2);
    timeSpan += ':' + ('0' + ss).slice(-2);
    return timeSpan;
  }

  public _formatDate(dateTime: any, format: string): string {
    if (!format) {
      format = 'Y-m-d H:i:s';
    }
    format = format.replace('Y', String(dateTime.getFullYear()));
    format = format.replace('m', ('0' + (dateTime.getMonth() + 1)).slice(-2));
    format = format.replace('d', ('0' + dateTime.getDate()).slice(-2));
    format = format.replace('H', ('0' + dateTime.getHours()).slice(-2));
    format = format.replace('i', ('0' + dateTime.getMinutes()).slice(-2));
    format = format.replace('s', ('0' + dateTime.getSeconds()).slice(-2));
    return format;
  }

  public _shuffle(array: any[]): any[] {
    let n = array.length;
    let t;
    let i;
    while (n) {
        i = Math.floor(Math.random() * n--);
        t = array[n];
        array[n] = array[i];
        array[i] = t;
    }
    return array;
  }

  public _vd(obj: any): void {
    window.console.log(obj);
  }

  /**
   * ビット演算子
   *
   * 2進数で指定されたビットが立っているかどうかを返す。
   *
   * @param num - The first input number
   * @param key - The second input number
   * @returns boolean
   */
  public _bitCalc(num: number, key: number): boolean {
    const tmpStr = num.toString(2);
    const tmpLen = tmpStr.length;
    if (tmpLen < key) {
      return false;
    }
    const chr = parseInt(tmpStr.substr(tmpLen - key, 1), 10);
    return (chr === 1);
  }

  public _replaceBall(str: string): string {
    str = str.replace('1', '①'); // &#9312;
    str = str.replace('2', '②'); // &#9313;
    str = str.replace('3', '③'); // &#9314;
    str = str.replace('4', '④'); // &#9315;
    str = str.replace('5', '⑤'); // &#9316;
    str = str.replace('6', '⑥'); // &#9317;
    str = str.replace('7', '⑦'); // &#9314;
    str = str.replace('8', '⑧'); // &#9319;
    str = str.replace('9', '⑨'); // &#9320;
    str = str.replace('a', '⑩'); // &#9321;
    str = str.replace('b', '⑪'); // &#9312;
    str = str.replace('c', '⑫'); // &#9313;
    str = str.replace('d', '⑬'); // &#9314;
    str = str.replace('e', '⑭'); // &#9315;
    str = str.replace('f', '⑮'); // &#9316;
    return str;
  }

  // privateはpublicの後
  private async _reLogin(token: string, usrId: number) {
    return await libAxios.post('re-login', token, {usr_id: usrId})
    .then(() => {
      return;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
