




















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {EventStep}  from '@/model/class/EventStep';
import draggable    from 'vuedraggable';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue, draggable);

@Component({
  components: {
    draggable,
  },
})
export default class EventSetOrder extends Vue {
  @Prop() private currentStep!: EventStep;
  @Prop() private editSetNum!: number;
  private fixPlayers!: {[key: number]: Array<{id: number, name: string, pos: number}>};
  private freePlayers: Array<{id: number, name: string, pos: number}> = [];
  // private tnmArray:
  private tnm: number[] = [];
  private tnmLen!: number;
  private enabled: boolean[] = [];
  private isFix: boolean[] = [];
  private options: { group: string, animation: number} = {
    group: 'myGroup',
    animation: 200,
  };

  @Emit('executeStep') private executeStep(stepId: number, param: {[key: string]: any}): void { return; }
  @Emit('cancel') private cancel(): void { return; }

  private created(): void {
    const tmpPlayers = this.$store.getters['event/getPlayers']();
    const tmpGames = this.$store.getters['event/getGames']();
    const tmpStyle = this.currentStep.style;
    const fixIds: number[] = [];
    this.fixPlayers = {};
    // 総当たりとトーナメントで処理を分ける
    if (tmpStyle === 'R00') {
      const baseKey = this.currentStep.eventStepId + '_' + this.editSetNum + '_0_';
      const firstGame = tmpGames[baseKey + 1];
      this.tnm = [0, 1];
      if (firstGame.eventGameStatus >= 2) {
        fixIds.push(firstGame.eventPlayerId1);
        fixIds.push(firstGame.eventPlayerId2);
      }

      for (let i = 2; i < this.currentStep.setLimit; i++) {
        const tmpGame = tmpGames[baseKey + i];
        if (tmpGame.eventGameStatus >= 2) {
          fixIds.push(tmpGame.eventPlayerId2);
        }
        this.tnm.push(i);
      }
      this.tnmLen = this.tnm.length;
      for (let i = 0; i < this.tnmLen; i++) {
        this.fixPlayers[i] = [];
        this.enabled[i] = false;
        this.isFix[i] = false;
      }
    } else {
      const tnmNum: number = parseInt(tmpStyle.charAt(1), 10);
      const tnm1st: number = Math.pow(2, (tnmNum - 1));
      const baseKey = this.currentStep.eventStepId + '_' + this.editSetNum + '_0_';

      /*
      if (tmpStyle.charAt(0) === 'D') {
        this.tnm = this.C_TNM_ORDER_D[tnmNum];
      } else {
        this.tnm = this.C_TNM_ORDER[tnmNum];
      }
      */
      this.tnm = [];
      const tmpLen = tnm1st * 2;
      for (let i = 0; i < tmpLen; i++) {
        this.tnm[i] = i;
      }
      this.tnmLen = tmpLen;
      for (let i = 0; i < this.tnmLen; i++) {
        this.fixPlayers[i] = [];
        this.enabled[i] = false;
        this.isFix[i] = false;
      }
      // 一回戦だけ取得
      for (let i = 0; i < tnm1st; i++) {
        const tmpGame = tmpGames[baseKey + i];
        if (tmpGame.eventGameStatus >= 2) {
          fixIds.push(tmpGame.eventPlayerId1);
          fixIds.push(tmpGame.eventPlayerId2);
          this.enabled[this.tnm[(i * 2)]] = true;
          this.enabled[this.tnm[(i * 2 + 1)]] = true;
        }
      }
    }

    Object.keys(tmpPlayers).map((str: string) => {
      const key = parseInt(str, 10);
      if (tmpPlayers[key].eventStepId === this.currentStep.eventStepId
        && tmpPlayers[key].setNumber === this.editSetNum) {
        const setOrder = tmpPlayers[key].setOrder;
        if (setOrder < 0) {
          const setNum = tmpPlayers[key].setNumber;
          this.freePlayers.push({
            id: tmpPlayers[key].eventPlayerId,
            name: tmpPlayers[key].usrNameShort + '(' + tmpPlayers[key].usrClass + ')' + tmpPlayers[key].deptNameShort,
            pos: tmpPlayers[key].setOrder,
          });
        } else {
          this.fixPlayers[setOrder].push({
            id: tmpPlayers[key].eventPlayerId,
            name: tmpPlayers[key].usrNameShort + '(' + tmpPlayers[key].usrClass + ')' + tmpPlayers[key].deptNameShort,
            pos: tmpPlayers[key].setOrder,
          });
          if (tmpPlayers[key].eventPlayerStatus !== this.C_PLAYER_STATUS_INI) {
            this.enabled[setOrder] = true;
            this.isFix[setOrder] = true;
          } else {
            this.enabled[setOrder] = false;
            this.isFix[setOrder] = false;
          }
        }
      }
    });
  }

  private onEnd(): void {
    for (let i = 0; i < this.tnmLen; i++) {
      if (this.fixPlayers[i].length === 0) {
        this.enabled[i] = false;
      } else {
        this.enabled[i] = true;
      }
    }
    this.$forceUpdate();
  }

  private release(num: number) {
    const tmpPlayer: {id: number, name: string, pos: number} = this.fixPlayers[num][0];
    this.freePlayers.push(tmpPlayer);
    this.fixPlayers[num] = [];
    this.enabled[num] = false;
    this.$forceUpdate();
  }

  // Step0の場合とわける。
  private saveOrder(): void {
    const fixIds: number[] = [];
    for (let i = 0; i < this.tnmLen; i++) {
      if (this.fixPlayers[i].length === 0) {
        fixIds.push(0);
      } else {
        fixIds.push(this.fixPlayers[i][0].id);
      }
    }

    const freeIds: number[] = [];
    const freeLen = this.freePlayers.length;
    for (let i = 0; i < freeLen; i++) {
      freeIds.push(this.freePlayers[i].id);
    }

    const postParam = {
      command: 'arrange',
      fix_ids: fixIds.join(','),
      free_ids: freeIds.reverse().join(','),
      set_num: this.editSetNum,
    };
    this.executeStep(this.currentStep.eventStepId, postParam);
  }
}
