



































import {Component, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game9BallConfig extends Vue {
  private gamePointVal = [
    {text: '301', value: 301},
    {text: '501', value: 501},
    {text: '701', value: 701},
    {text: '901', value: 901},
    {text: '1101', value: 1101},
    {text: '1301', value: 1301},
    {text: '1501', value: 1501},
  ];

  private gamePoint: number = 501;
  private maxRound: number = 20;

  private config!: {[key: string]: number};

  private created(): void {
    // this.goalSet = this.$store.getters['darts/getGoalSet']();
  }

  private get _gamePoint(): number {
    return this.gamePoint;
  }

  private set _gamePoint(value: number) {
    this.$store.dispatch('darts/setRaceToAction', {isFirst: true, point: value});
    this.$store.dispatch('darts/setRaceToAction', {isFirst: false, point: value});
  }

  private get _MaxRound(): number {
    return this.maxRound;
  }

  private set _MaxRound(value: number) {
    this.$store.dispatch('darts/setMaxRoundAction', value);
  }
}
