






































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game14OneReport extends Vue {
  @Prop() private gameData!: any[];
  private totalData: Array<{[key: string]: any}> = [];
  private usrData: Array<{[key: string]: any}> = [];
  private placeData: Array<{[key: string]: any}> = [];

  private created(): void {
    for (const rec of this.gameData) {
      const tmpData: {[key: string]: any} = {
        matchCount: rec.match_cnt,
        winCount: rec.win_cnt,
        loseCount: rec.match_cnt - rec.win_cnt,
        winPoint: rec.win_point,
        losePoint: rec.lose_point,
        turn: rec.turn,
        highRun: rec.high_run,
        foul: rec.foul,
        safety: rec.safety,
      };

      const totalPoint = rec.win_point + rec.lose_point;
      if (totalPoint > 0) {
        tmpData.playTimeAvg = Math.floor(rec.play_ts / totalPoint);
      } else {
        tmpData.playTimeAvg = '-';
      }

      if (rec.turn > 0) {
        tmpData.pointAvg = (rec.win_point / rec.turn).toFixed(2);
      } else {
        tmpData.pointAvg = '-';
      }

      if (rec.rf_type === 1 || rec.rf_type === 11) {
        if (rec.ym === '999999') {
          tmpData.name = 'TOTAL';
        } else {
          tmpData.name = rec.ym.substr(0, 4) + '-' + rec.ym.substr(4, 2);
        }
        this.totalData.push(tmpData);
      } else if (rec.rf_type === 12) {
        tmpData.name = rec.tgt_name;
        this.usrData.push(tmpData);
      } else if (rec.rf_type === 13) {
        tmpData.name = rec.tgt_name + rec.tgt_num + '番台';
        this.placeData.push(tmpData);
      }
    }
  }
}
