import {EventParam}        from '@/model/interface/EventParam';
import {EventRefereeParam} from '@/model/interface/EventRefereeParam';
import {PlaceParam}        from '@/model/interface/PlaceParam';
import {EventEntry}         from '@/model/class/EventEntry';
import {EventStep}         from '@/model/class/EventStep';
import {EventPlayer}       from '@/model/class/EventPlayer';
import {EventGame}         from '@/model/class/EventGame';
import {EventConfig}       from '@/model/class/EventConfig';
import libAxios            from '@/mixin/LibAxios';

export default {
  namespaced: true,
  state: {
    eventId: 0,
    eventType: 0,
    eventStatus: 0,
    eventMode: 0,
    regularId: 0,
    toType: 0,
    toOrgId: 0,
    toDeptId: 0,
    toPrefId: 0,
    fromType: 0,
    fromId: 0,
    fromName: '',
    fromUrl: '',
    cycle: 0,
    firstDateTime: '',
    duration: 0,
    openDateTime: '',
    closeDateTime: '',
    entryDateTime: '',
    entryEndDateTime: '',
    entryMessage: '',
    entryOption: '',
    entryScorp: 0,
    conditionClass: '',
    conditionSex: 0,
    conditionAge: 0,
    conditionLimit: 0,
    conditionWait: 0,
    category: 0,
    title: '',
    summary: '',
    body: '',
    imagePath: '',
    imageText: '',
    hp: '',
    fb: '',
    configIds: '',
    referencPlace: 0,
    resultSummary: '',
    resultBody: '',
    resultImagePath: '',
    resultImageText: '',
    revision: 0,
    deleteFlag: false,
    editId: 0,
    editDateTime: '',
    entries: [],
    steps: {},
    players: {},
    games: {},
    configs: {},
    referees: [],
    places: [],
  } as EventParam,
  getters: {
    getToken: (state: EventParam, getters: any, RootState: any) => (): string => {
      return RootState.token;
    },
    getEventId: (state: EventParam, getters: any) => (): number => {
      return state.eventId;
    },
    getEventStatus: (state: EventParam, getters: any) => (): number => {
      return state.eventStatus;
    },
    getEventTitle: (state: EventParam, getters: any) => (): string => {
      return state.title;
    },
    getEventEditDateTime: (state: EventParam, getters: any) => (): string => {
      return state.editDateTime;
    },
    getEventBody: (state: EventParam, getters: any) => (): string => {
      return state.body;
    },
    // entry
    getEntries: (state: EventParam, getters: any) => (): EventEntry[] => {
      return state.entries;
    },
    // STEP
    getStep: (state: EventParam, getters: any) => (num: number): EventStep => {
      return state.steps[num];
    },
    getSteps: (state: EventParam, getters: any) => (): {[key: number]: EventStep} => {
      return state.steps;
    },
    getStepId: (state: EventParam, getters: any) => (num: number): number => {
      return state.steps[num].eventStepId;
    },
    getStepStatus: (state: EventParam, getters: any) => (num: number): number => {
      return state.steps[num].eventStepStatus;
    },
    getStepIsFin: (state: EventParam, getters: any) => (num: number): boolean => {
      return state.steps[num].isFinal;
    },
    getStepEditDT: (state: EventParam, getters: any) => (num: number): string => {
      return state.steps[num].editDateTime;
    },
    getStepsLength: (state: EventParam, getters: any) => (): number => {
      return Object.keys(state.steps).length;
    },
    getPlayers: (state: EventParam, getters: any) => (): {[key: number]: EventPlayer} => {
      return state.players;
    },
    getPlayer: (state: EventParam, getters: any) => (num: number): EventPlayer => {
      return state.players[num];
    },
    // Gane
    getGames: (state: EventParam, getters: any) => (): {[key: string]: EventGame} => {
      return state.games;
    },
    getGame: (state: EventParam, getters: any) => (key: string): EventGame => {
      return state.games[key];
    },
    getConfigs: (state: EventParam, getters: any) => (): {[key: number]: EventConfig} => {
      return state.configs;
    },
    getConfig: (state: EventParam, getters: any) => (key: any): EventConfig => {
      return state.configs[key];
    },
    getReferees: (state: EventParam, getters: any) => (): EventRefereeParam[] => {
      return state.referees;
    },
    getPlaces: (state: EventParam, getters: any) => (): PlaceParam[] => {
      return state.places;
    },
  },
  mutations: {
    clearData(state: EventParam) {
      state.eventId = 0;
      state.eventType = 0;
      state.eventStatus = 0;
      state.eventMode = 0;
      state.regularId = 0;
      state.toType = 0;
      state.toOrgId = 0;
      state.toDeptId = 0;
      state.toPrefId = 0;
      state.fromType = 0;
      state.fromId = 0;
      state.fromName = '';
      state.fromUrl = '';
      state.cycle = 0;
      state.firstDateTime = '';
      state.duration = 0;
      state.openDateTime = '';
      state.closeDateTime = '';
      state.entryDateTime = '';
      state.entryEndDateTime = '';
      state.entryMessage = '';
      state.entryOption = '';
      state.entryScorp = 0;
      state.conditionClass = '';
      state.conditionSex = 0;
      state.conditionAge = 0;
      state.conditionLimit = 0;
      state.conditionWait = 0;
      state.category = 0;
      state.title = '';
      state.summary = '';
      state.body = '';
      state.imagePath = '';
      state.imageText = '';
      state.hp = '';
      state.fb = '';
      state.configIds = '';
      state.referencPlace = 0;
      state.resultSummary = '';
      state.resultBody = '';
      state.resultImagePath = '';
      state.resultImageText = '';
      state.revision = 0;
      state.deleteFlag = false;
      state.editId = 0;
      state.editDateTime = '';
      state.entries = [];
      state.steps = {};
      state.players = {};
      state.games = {};
      state.configs = {};
      state.referees = [];
      state.places = [];
    },
    setEvent(state: EventParam, payload: {[key: string]: any}) {
      state.eventId          = payload.e_id;
      state.eventType        = payload.e_type;
      state.eventStatus      = payload.e_status;
      state.eventMode        = payload.e_mode;
      state.regularId        = payload.re_id;
      state.toType           = payload.to_type;
      state.toOrgId          = payload.to_o_id;
      state.toDeptId         = payload.to_d_id;
      state.toPrefId         = payload.to_p_id;
      state.fromType         = payload.from_type;
      state.fromId           = payload.from_id;
      state.fromName         = payload.from_name;
      state.fromUrl          = payload.from_url;
      state.cycle            = payload.cycle;
      state.firstDateTime    = payload.first_dt;
      state.duration         = payload.duration;
      state.openDateTime     = payload.open_dt;
      state.closeDateTime    = payload.close_dt;
      state.entryDateTime    = payload.entry_dt;
      state.entryEndDateTime = payload.entry_end_dt;
      state.entryMessage     = payload.entry_msg;
      state.entryOption      = payload.entry_opt;
      state.entryScorp       = payload.entry_scorp;
      state.conditionClass   = payload.cond_cls;
      state.conditionSex     = payload.cond_sex;
      state.conditionAge     = payload.cond_age;
      state.conditionLimit   = payload.cond_lim;
      state.conditionWait    = payload.cond_wait;
      state.category         = payload.category;
      state.title            = payload.title;
      state.summary          = payload.summary;
      state.body             = payload.body;
      state.imagePath        = payload.img_path;
      state.imageText        = payload.img_text;
      state.hp               = payload.hp;
      state.fb               = payload.fb;
      state.configIds        = payload.configs;
      state.referencPlace    = payload.ref_place;
      state.resultSummary    = payload.rst_summary;
      state.resultBody       = payload.rst_body;
      state.resultImagePath  = payload.rst_img_path;
      state.resultImageText  = payload.rst_img_text;
      state.revision         = payload.revision;
      state.deleteFlag       = payload.del_flg;
      state.editId           = payload.edit_id;
      state.editDateTime     = payload.edit_dt;
    },
    setEntriesAll(state: EventParam, payload: any[]) {
      const entries: EventEntry[] = [];
      for (const rec of payload) {
        const tmpEntry = new EventEntry();
        tmpEntry.setByApi(rec);
        entries.push(tmpEntry);
      }
      state.entries = entries;
    },
    setStepsAll(state: EventParam, payload: any[]) {
      const steps: {[key: number]: EventStep} = {};
      for (const rec of payload) {
        const tmpStep = new EventStep();
        tmpStep.setByApi(rec);
        steps[tmpStep.eventStepNum] = tmpStep;
      }
      state.steps = steps;
    },
    setStep(state: EventParam, payload: {[key: string]: any}) {
      const tmpStep = new EventStep();
      tmpStep.setByApi(payload);
      state.steps[tmpStep.eventStepNum] = tmpStep;
    },
    setPlayersAll(state: EventParam, payload: any[]) {
      const players: {[key: number]: EventPlayer} = {};
      for (const rec of payload) {
        const tmpPlayer = new EventPlayer();
        tmpPlayer.setByApi(rec);
        players[tmpPlayer.eventPlayerId] = tmpPlayer;
      }
      state.players = players;
    },
    setPlayers(state: EventParam, payload: any[]) {
      for (const rec of payload) {
        const tmpPlayer = new EventPlayer();
        tmpPlayer.setByApi(rec);
        state.players[tmpPlayer.eventPlayerId] = tmpPlayer;
      }
    },
    setPlayer(state: EventParam, payload: {[key: string]: any}) {
      const tmpPlayer = new EventPlayer();
      tmpPlayer.setByApi(payload);
      state.players[tmpPlayer.eventPlayerId] = tmpPlayer;
    },
    setGamesAll(state: EventParam, payload: any[]) {
      const games: {[key: string]: EventGame} = {};
      for (const rec of payload) {
        const tmpGame = new EventGame();
        tmpGame.setByApi(rec);
        const key = tmpGame.eventStepId + '_'
                  + tmpGame.setNumber + '_'
                  + tmpGame.roundNumber + '_'
                  + tmpGame.gameNumber;
        games[key] = tmpGame;
      }
      state.games = games;
    },
    setGames(state: EventParam, payload: any[]) {
      for (const rec of payload) {
        const tmpGame = new EventGame();
        tmpGame.setByApi(rec);
        const key = tmpGame.eventStepId + '_'
                  + tmpGame.setNumber + '_'
                  + tmpGame.roundNumber + '_'
                  + tmpGame.gameNumber;
        state.games[key] = tmpGame;
      }
    },
    setGame(state: EventParam, payload: {[key: string]: any}) {
      const tmpGame = new EventGame();
      tmpGame.setByApi(payload);
      const key = tmpGame.eventStepId + '_'
                + tmpGame.setNumber + '_'
                + tmpGame.roundNumber + '_'
                + tmpGame.gameNumber;
      state.games[key] = tmpGame;
    },
    setConfigsAll(state: EventParam, payload: any[]) {
      const configs: {[key: number]: EventConfig} = {};
      for (const rec of payload) {
        const tmpConfig = new EventConfig();
        tmpConfig.setByApi(rec);
        configs[tmpConfig.eventConfigId] = tmpConfig;
      }
      state.configs = configs;
    },
    setRefereesAll(state: EventParam, payload: any[]) {
      const referees: EventRefereeParam[] = [];
      for (const rec of payload) {
        referees.push({usrId: rec.u_id, usrName: rec.u_name, tableNum: rec.table_num});
      }
      state.referees = referees;
    },
    setPlacesAll(state: EventParam, payload: any[]) {
      const places: PlaceParam[] = [];
      for (const rec of payload) {
        places.push({id: rec.d_id, name: rec.d_name, tableNum: rec.d_cnt_tbl});
      }
      state.places = places;
    },
  },
  actions: {
    async getEventAction(context: any, eventId: number) {
      context.commit('clearData');
      const token = context.getters.getToken();
      return await libAxios.get('event-data/' + eventId, token, [])
      .then((data: {[key: string]: any}) => {
        context.commit('setEvent', data.event);
        context.commit('setEntriesAll', data.entry);
        context.commit('setStepsAll', data.steps.data);
        context.commit('setPlayersAll', data.players.data);
        context.commit('setGamesAll', data.games.data);
        context.commit('setConfigsAll', data.configs.data);
        context.commit('setRefereesAll', data.referees.data);
        context.commit('setPlacesAll', data.places.data);
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async executeEventAction(context: any, param: {[key: string]: any}) {
      const token = context.getters.getToken();
      const eventId = context.getters.getEventId();
      return await libAxios.post('event-data/' + eventId, token, param)
      .then((data: {[key: string]: any}) => {
        context.commit('setEvent', data.event);
        context.commit('setStepsAll', data.steps.data);
        context.commit('setPlayersAll', data.players.data);
        context.commit('setGamesAll', data.games.data);
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async storeStepAction(context: any, param: {[key: string]: any}) {
      const token = context.getters.getToken();
      const eventId = context.getters.getEventId();
      return await libAxios.post('event-data/' + eventId + '/steps', token, param)
      .then((data: {[key: string]: any}) => {
        context.commit('setEvent', data.event);
        context.commit('setStepsAll', data.steps.data);
        context.commit('setPlayersAll', data.players.data);
        context.commit('setGamesAll', data.games.data);
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async executeStepAction(context: any, opetions: {stepId: number, param: {[key: string]: any}}) {
      const token = context.getters.getToken();
      const eventId = context.getters.getEventId();
      return await libAxios.post('event-data/' + eventId + '/steps/' + opetions.stepId, token, opetions.param)
      .then((data: {[key: string]: any}) => {
        context.commit('setStep', data.step);
        context.commit('setPlayers', data.players.data);
        context.commit('setGames', data.games.data);
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async destroyStepAction(context: any, opetions: {stepId: number, param: {[key: string]: any}}) {
      const token = context.getters.getToken();
      const eventId = context.getters.getEventId();
      return await libAxios.delete('event-data/' + eventId + '/steps/' + opetions.stepId, token, {data: opetions.param})
      .then((data: {[key: string]: any}) => {
        context.commit('setEvent', data.event);
        context.commit('setStepsAll', data.steps.data);
        context.commit('setPlayersAll', data.players.data);
        context.commit('setGamesAll', data.games.data);
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    // Guest add
    async storePlayerAction(context: any, opetions: {stepId: number, param: {[key: string]: any}}) {
      const token = context.getters.getToken();
      const eventId = context.getters.getEventId();
      const url = 'event-data/' + eventId + '/steps/' + opetions.stepId + '/players';
      return await libAxios.post(url, token, opetions.param)
      .then((data: {[key: string]: any}) => {
        context.commit('setStep', data.step);
        context.commit('setPlayers', data.players.data);
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async updatePlayerAction(context: any, opetions: {stepId: number, playerID: number, param: {[key: string]: any}}) {
      const token = context.getters.getToken();
      const eventId = context.getters.getEventId();
      return await libAxios.put(
        'event-data/' + eventId + '/steps/' + opetions.stepId + '/players/' + opetions.playerID,
        token,
        opetions.param,
      ).then((data: {[key: string]: any}) => {
        context.commit('setPlayer', data.player); // stepは０しかこない
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async showGameAction(context: any, opetions: {stepId: number, gameID: number}) {
      const token = context.getters.getToken();
      const eventId = context.getters.getEventId();
      return await libAxios.get(
        'event-data/' + eventId + '/steps/' + opetions.stepId + '/games/' + opetions.gameID,
        token,
        [],
      ).then((data: {[key: string]: any}) => {
        context.commit('setGame', data.game);
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async updateGameAction(context: any, opetions: {stepId: number, gameID: number, param: {[key: string]: any}}) {
      const token = context.getters.getToken();
      const eventId = context.getters.getEventId();
      return await libAxios.put(
        'event-data/' + eventId + '/steps/' + opetions.stepId + '/games/' + opetions.gameID,
        token,
        opetions.param,
      ).then((data: {[key: string]: any}) => {
        context.commit('setGame', data.game);
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async executeGameAction(context: any, opetions: {stepId: number, gameID: number, param: {[key: string]: any}}) {
      const token = context.getters.getToken();
      const eventId = context.getters.getEventId();
      return await libAxios.post(
        'event-data/' + eventId + '/steps/' + opetions.stepId + '/games/' + opetions.gameID,
        token,
        opetions.param,
      ).then((data: {[key: string]: any}) => {
        context.commit('setPlayers', data.players.data);
        context.commit('setGames', data.games.data);
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    setEventAction(context: any, data: {[key: string]: any}) {
      context.commit('clearData');
      context.commit('setEvent', data.event);
      context.commit('setStepsAll', data.steps.data);
      context.commit('setPlayersAll', data.players.data);
      context.commit('setGamesAll', data.games);
    },
  },
};
