











































































































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);


@Component({
  components: {
  },
})
export default class GameRotationPlayLv1 extends Vue {
  @Prop() private balls!: number[];
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  @Prop() private preScore1!: number;
  @Prop() private preScore2!: number;
  private buttonClassP1: string[] = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
  private buttonClassP2: string[] = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
  private activeClass: string[] = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
  private useNext: boolean = false;
  private winNum: number = 0;

  @Emit('setBall') private setBall(val: number, num: number): void { return; }
  @Emit('actionTypeLv1') private playPointLv1(action: string, balls1: number[], balls2: number[]): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get _Ball01(): number {
    return this.balls[0];
  }

  private set _Ball01(val: number) {
    this.setBalls(val, 0);
  }

  private get _Ball02(): number {
    return this.balls[1];
  }


  private set _Ball02(val: number) {
    this.setBalls(val, 1);
  }

  private get _Ball03(): number {
    return this.balls[2];
  }

  private set _Ball03(val: number) {
    this.setBalls(val, 2);
  }

  private get _Ball04(): number {
    return this.balls[3];
  }

  private set _Ball04(val: number) {
    this.setBalls(val, 3);
  }

  private get _Ball05(): number {
    return this.balls[4];
  }

  private set _Ball05(val: number) {
    this.setBalls(val, 4);
  }

  private get _Ball06(): number {
    return this.balls[5];
  }

  private set _Ball06(val: number) {
    this.setBalls(val, 5);
  }

  private get _Ball07(): number {
    return this.balls[6];
  }

  private set _Ball07(val: number) {
    this.setBalls(val, 6);
  }

  private get _Ball08(): number {
    return this.balls[7];
  }

  private set _Ball08(val: number) {
    this.setBalls(val, 7);
  }

  private get _Ball09(): number {
    return this.balls[8];
  }

  private set _Ball09(val: number) {
    this.setBalls(val, 8);
  }

  private get _Ball10(): number {
    return this.balls[9];
  }

  private set _Ball10(val: number) {
    this.setBalls(val, 9);
  }

  private get _Ball11(): number {
    return this.balls[10];
  }

  private set _Ball11(val: number) {
    this.setBalls(val, 10);
  }

  private get _Ball12(): number {
    return this.balls[11];
  }

  private set _Ball12(val: number) {
    this.setBalls(val, 11);
  }

  private get _Ball13(): number {
    return this.balls[12];
  }

  private set _Ball13(val: number) {
    this.setBalls(val, 12);
  }

  private get _Ball14(): number {
    return this.balls[13];
  }

  private set _Ball14(val: number) {
    this.setBalls(val, 13);
  }

  private get _Ball15(): number {
    return this.balls[14];
  }

  private set _Ball15(val: number) {
    this.setBalls(val, 14);
  }

  private get useBall01(): boolean {
    return (this.activeClass[0] === 'disabled');
  }

  private get useBall02(): boolean {
    return (this.activeClass[1] === 'disabled');
  }

  private get useBall03(): boolean {
    return (this.activeClass[2] === 'disabled');
  }

  private get useBall04(): boolean {
    return (this.activeClass[3] === 'disabled');
  }

  private get useBall05(): boolean {
    return (this.activeClass[4] === 'disabled');
  }

  private get useBall06(): boolean {
    return (this.activeClass[5] === 'disabled');
  }

  private get useBall07(): boolean {
    return (this.activeClass[6] === 'disabled');
  }

  private get useBall08(): boolean {
    return (this.activeClass[7] === 'disabled');
  }

  private get useBall09(): boolean {
    return (this.activeClass[8] === 'disabled');
  }

  private get useBall10(): boolean {
    return (this.activeClass[9] === 'disabled');
  }

  private get useBall11(): boolean {
    return (this.activeClass[10] === 'disabled');
  }

  private get useBall12(): boolean {
    return (this.activeClass[11] === 'disabled');
  }

  private get useBall13(): boolean {
    return (this.activeClass[12] === 'disabled');
  }

  private get useBall14(): boolean {
    return (this.activeClass[13] === 'disabled');
  }

  private get useBall15(): boolean {
    return (this.activeClass[14] === 'disabled');
  }

  private setBalls(val: number, num: number): void {
    this.setBall(val, num);
    this.calc();
    this.view();
    this.$forceUpdate();
  }

  private clear(): void {
    for (let i = 0; i < 15; i++) {
      if ( this.activeClass[i] === '' ) {
        this.balls[i] = 0;
      }
    }
    this.calc();
    this.view();
    this.$forceUpdate();
  }

  private created(): void {
    for (let i = 0; i < 15; i++) {
      if (this.balls[i] === 0) {
        this.activeClass[i] = '';
      } else {
        this.activeClass[i] = 'disabled';
      }
    }
    this.calc();
    this.view();
  }

  private view(): void {
    const score1 = this.$store.getters['match/getPlayer1Score']();
    const raceTo1 = this.$store.getters['match/getPlayer1RaceTo']();
    const score2 = this.$store.getters['match/getPlayer2Score']();
    const raceTo2 = this.$store.getters['match/getPlayer2RaceTo']();
    for (let i = 0; i < 15; i++) {
      if (this.balls[i] === 1) {
        this.buttonClassP1[i] = 'btn-primary';
        this.buttonClassP2[i] = 'btn-dark';
      } else if (this.balls[i] === 2) {
        this.buttonClassP1[i] = 'btn-dark';
        this.buttonClassP2[i] = 'btn-success';
      } else {
        if ( score1 + i + 1 - raceTo1 >= 0 ) {
          this.buttonClassP1[i] = 'btn-danger';
        } else {
          this.buttonClassP1[i] = 'btn-outline-primary';
        }
        if ( score2 + i + 1 - raceTo2 >= 0 ) {
          this.buttonClassP2[i] = 'btn-danger';
        } else {
          this.buttonClassP2[i] = 'btn-outline-success';
        }
      }
    }
  }

  private calc(): void {
    let tmpScore1 = this.preScore1;
    let tmpScore2 = this.preScore2;
    let isNext = true;
    let winner = 0;
    for (let i = 0; i < 15; i++) {
      if (this.balls[i] === 1) {
        tmpScore1 += i + 1;
      } else if (this.balls[i] === 2) {
        tmpScore2 += i + 1;
      } else {
        isNext = false;
      }
    }
    this.$store.dispatch('match/setScoreAction', {isFirst: true, point: tmpScore1});
    this.$store.dispatch('match/setScoreAction', {isFirst: false, point: tmpScore2});

    const raceTo1 = this.$store.getters['match/getPlayer1RaceTo']();
    if (tmpScore1 >= raceTo1) {
      winner = 1;
    }
    const raceTo2 = this.$store.getters['match/getPlayer2RaceTo']();
    if (tmpScore2 >= raceTo2) {
      winner = 2;
    }
    this.$store.dispatch('match/setWinnerAction', winner);
    this.winNum = winner;
    this.useNext = isNext;
  }

  private save(): void {
    this.playPoint('');
    this.view();
    this.$forceUpdate();
  }

  private playPoint(playType: string): void {
    const tmpBall1: number[] = [];
    const tmpBall2: number[] = [];
    for (let i = 0; i < 15; i++) {
      if (this.activeClass[i] === '' && this.balls[i] !== 0) {
        this.$set(this.activeClass, i, 'disabled');
        if (this.balls[i] === 1) {
          tmpBall1.push(i + 1);
        } else if (this.balls[i] === 2) {
          tmpBall2.push(i + 1);
        }
      }
    }
    this.playPointLv1(playType, tmpBall1, tmpBall2);
  }
}
