

















import {Component, Prop, Vue} from 'vue-property-decorator';
import EventHeader  from '@/components/event-admin/common/EventHeader.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    EventHeader,
  },
})
export default class EATop extends Vue {
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Event管理 Top', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          // this.usrType = this.$store.getters.getUsrType();
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }
}
