import {User} from '@/model/class/user';

export class Department {
  private id!: number;
  private name!: string;
  private numOfTable!: number;
  private isUsePlace!: boolean;
  private users!: User[];

  constructor(id: number, name: string, numOfTable: number, isUsePlace: boolean, users: User[]) {
    this.id = id;
    this.name = name;
    this.numOfTable = numOfTable;
    this.isUsePlace = isUsePlace;
    this.users = users;
  }

  public getId(): number {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public getNumOfTable(): number {
    return this.numOfTable;
  }

  public getIsUsePlace(): boolean {
    return this.isUsePlace;
  }

  public getUsers(): User[] {
    return this.users;
  }
}
