


























































































































































































































































































































































































import {Component, Watch, Vue} from 'vue-property-decorator';
import {EventStep}    from '@/model/class/EventStep';
import EventHeader    from '@/components/event-admin/common/EventHeader.vue';
import ImageUpload    from '@/components/common/ImageUpload.vue';
import libAxios       from '@/mixin/LibAxios';
import BootstrapVue   from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    EventHeader,
    ImageUpload,
  },
})
export default class EAEventDetail extends Vue {
  private isLoading: boolean = true;
  private usrType!: number;
  private eventId: number = 0;
  private regularId: number = 0;
  private cycle: number = 0;
  private configList: Array<{[text: string]: any}> = [];
  private tabIndex: number = 0;
  private editDateTime!: string;

  private title: string = '';
  private eventStatus: number = 0;

  private eType: number = 0;
  private mode1: boolean = false;
  private mode2: boolean = false;
  private mode3: boolean = false;

  // msg使用時
  private toType1: boolean = false;
  private toType2: boolean = false;
  private toType3: boolean = false;
  private toType4: boolean = false;
  private valToType1: boolean = false;
  private valToType2: boolean = false;
  private valToType3: boolean = false;
  private valToType4: boolean = false;

  // 選択した権限で場所が決まる。
  private fromId: number = -1;
  private fromName: string = '';
  private fromUrl: string = '';
  private fromData: Array<{[key: string]: any}> = [];
  private category: number = 0;
  private firstDate: string = '';
  private firstTime: string = '';
  private duration: number = 1;
  private openDate: string = '';
  private openTime: string = '';
  private closeDate: string = '';
  private closeTime: string = '';

  private summary: string = '';
  private body: string = '';
  private imgPath: string = '';
  private imgText: string = '';
  private hp: string = '';
  private fb: string = '';

  // 登録情報
  private entryDate: string = '';
  private entryTime: string = '';
  private entryEndDate: string = '';
  private entryEndTime: string = '';
  private condCls: string = '';
  private condSex: number = 0;
  private condAge: number = 0;
  private condLim: number = 0;
  private condWait: number = 0;
  private entryMsg: string = '';
  private entryOpt: string = '';
  private entryScorp: number = 0;

  // 試合情報
  private configs: number[] = [];
  private refPlace1: boolean = false;
  private refPlace2: boolean = false;
  private refPlace3: boolean = false;
  private refPlace4: boolean = false;
  private valRefPlace1: boolean = false;
  private valRefPlace2: boolean = false;
  private valRefPlace3: boolean = false;
  private valRefPlace4: boolean = false;

  private rstSummary: string = '';
  private rstBody: string = '';
  private rstImgPath: string = '';
  private rstImgText: string = '';

  private revision: number = 0;

  // IMG UPLOADモーダル用
  private imageType: string = '';
  private imageKey: string = '';
  private imageId: number = 0;

  private modalImageUpload: boolean = false;
  private connection: boolean = false;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'event-admin-top' }},
    { text: 'イベント一覧', to: { name: 'event-admin-event-list' }},
  ];

  @Watch('fromId')
  private onChangeReFrom(newValue: number, oldValue: number): void {
    const target = this.fromData.find((item) => {
      return (item.value === newValue);
    });
    if (target) {
      this.fromName = target.ref_name;
      this.valRefPlace1 = this.valToType1 = this._bitCalc(target.target, 1);
      if (!this.valToType1 && this.toType1) {
        this.toType1 = false;
      }
      if (!this.valRefPlace1 && this.refPlace1) {
        this.refPlace1 = false;
      }
      this.valRefPlace2 = this.valToType2 = this._bitCalc(target.target, 2);
      if (!this.valToType2 && this.toType2) {
        this.toType2 = false;
      }
      if (!this.valRefPlace2 && this.refPlace2) {
        this.refPlace2 = false;
      }
      this.valRefPlace3 = this.valToType3 = this._bitCalc(target.target, 3);
      if (!this.valToType3 && this.toType3) {
        this.toType3 = false;
      }
      if (!this.valRefPlace3 && this.refPlace3) {
        this.refPlace3 = false;
      }
      this.valRefPlace4 = this.valToType4 = this._bitCalc(target.target, 4);
      if (!this.valToType4 && this.toType4) {
        this.toType4 = false;
      }
      if (!this.valRefPlace4 && this.refPlace4) {
        this.refPlace4 = false;
      }
    }
  }

  private get titleStatus(): boolean {
    return (this.title.length > 0 && this.title.length <= 64) ? true : false;
  }

  private get fromIdStatus(): boolean {
    return (this.fromId !== -1) ? true : false;
  }

  private get firstDateStatus(): boolean {
    return (this.firstDate !== '') ? true : false;
  }

  private get firstTimeStatus(): boolean {
    return (this.firstTime && this.firstTime !== '') ? true : false;
  }

  private get openDateStatus(): boolean {
    return (this.mode1 && this.openDate === '') ? false : true;
  }

  private get openTimeStatus(): boolean {
    return (this.mode1 && this.openTime === '') ? false : true;
  }

  private get categoryStatus(): boolean {
    return (this.mode1 && this.category === 0) ? false : true;
  }

  private get summaryStatus(): boolean {
    return (this.mode1 && this.summary === '') ? false : true;
  }

  private get bodyStatus(): boolean {
    return (this.mode1 && this.body === '') ? false : true;
  }

  private get toTypeStatus(): boolean {
    return ((this.mode1 && !this.toType1 && !this.toType2 && !this.toType3 && !this.toType4)
            || (this.mode2 && !this.toType1 && !this.toType2 && !this.toType3 && !this.toType4)) ? false : true;
  }
  // エントリーバリデーション
  private get entryDateStatus(): boolean {
    return (this.mode2 && this.entryDate === '') ? false : true;
  }

  private get entryTimeStatus(): boolean {
    return (this.mode2 && this.entryTime === '') ? false : true;
  }

  private get entryMsgStatus(): boolean {
    return (this.mode2 && this.entryMsg === '') ? false : true;
  }


  // 試合バリデーション
  private get configsStatus(): boolean {
    return (this.mode3 && this.configs.length === 0) ? false : true;
  }

  private get refPlaceStatus(): boolean {
    return (this.mode3 && !this.refPlace1 && !this.refPlace2 && !this.refPlace3 && !this.refPlace4) ? false : true;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.usrType = this.$store.getters.getUsrType();
          // r_id=0
          // ロールの確認
          const roles = this.$store.getters.getRoles();
          for (const item of roles) {
            this.fromData.push({
              text: item.r_name,
              value: item.r_id,
              target: item.r_target,
              ref_name: item.ref_name,
              ref_id: item.ref_id,
              ref_pref: item.ref_pref,
              ref_type: item.ref_type,
            });
          }

          this.getConfigList();
          if (this.$route.params.id) {
            this.eventId = parseInt(this.$route.params.id, 10);
            this.getEvent();
          } else {
            this.breadItems.push({ text: '定例新規作成', active: true});
            this.isLoading = false;
          }
        } else {
          this.$router.push({name: 'error'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getConfigList() {
    this.isLoading = true;
    const token = this.$store.getters.getToken();
    libAxios.get('event-config', token, {})
    .then((response) => {
      for (const item of response.data) {
        this.configList.push({ text: item.ec_title, value: item.ec_id});
      }
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private async getEvent() {
    const token = this.$store.getters.getToken();
    libAxios.get('event-data/' + this.eventId + '/edit', token, {})
    .then((param: {[key: string]: any}) => {
      this.regularId = param.re_id;
      this.eventStatus   = param.e_status;
      this.eType     = param.e_type;
      this.title     = param.title;
      this.mode1     = this._bitCalc(param.e_mode, 1);
      this.mode2     = this._bitCalc(param.e_mode, 2);
      this.mode3     = this._bitCalc(param.e_mode, 3);
      this.category  = param.category;
      this.cycle     = param.cycle;

      const firstBuf = param.first_dt.split(' ');
      this.firstDate = firstBuf[0];
      this.firstTime = firstBuf[1].substring(0, 5);
      this.duration  = param.duration;
      if (param.open_dt !== '2100-01-01 00:00:00') {
        const openBuf = param.open_dt.split(' ');
        this.openDate = openBuf[0];
        this.openTime = openBuf[1].substring(0, 5);
      }
      if (param.close_dt !== '2100-01-01 00:00:00') {
        const closeBuf = param.close_dt.split(' ');
        this.closeDate = closeBuf[0];
        this.closeTime = closeBuf[1].substring(0, 5);
      }
      this.toType1  = this._bitCalc(param.to_type, 1);
      this.toType2  = this._bitCalc(param.to_type, 2);
      this.toType3  = this._bitCalc(param.to_type, 3);
      this.toType4  = this._bitCalc(param.to_type, 4);

      if (param.from_type === 1) {
        this.fromId   = 0;
      } else {
        this.fromId   = param.from_id;
      }
      this.fromName = param.from_name;
      this.fromUrl  = param.from_url;

      this.body     = param.body;
      this.summary  = param.summary;
      this.hp       = param.hp;
      this.fb       = param.fb;
      this.imgPath  = param.img_path;
      this.imgText  = param.img_text;

      // 登録情報
      if (param.entry_dt !== '2100-01-01 00:00:00') {
        const entryBuf = param.entry_dt.split(' ');
        this.entryDate    = entryBuf[0];
        this.entryTime    = entryBuf[1].substring(0, 5);
      }
      if (param.entry_end_dt !== '2100-01-01 00:00:00') {
        const entryEndBuf = param.entry_end_dt.split(' ');
        this.entryEndDate = entryEndBuf[0];
        this.entryEndTime = entryEndBuf[1].substring(0, 5);
      }

      this.entryMsg   = param.entry_msg;
      this.entryOpt   = param.entry_opt;
      this.entryScorp = param.entry_scorp;
      this.condLim    = param.cond_lim;
      this.condWait   = param.cond_wait;
      this.condSex    = param.cond_sex;
      this.condAge    = param.cond_age;
      this.condCls    = param.cond_cls;

      // 試合情報
      if (param.configs !== '') {
        const tmpConfigs = param.configs.split(',');
        for (const tmpConfig of tmpConfigs) {
          this.configs.push(parseInt(tmpConfig, 10));
        }
      }
      this.refPlace1 = this._bitCalc(param.ref_place, 1);
      this.refPlace2 = this._bitCalc(param.ref_place, 2);
      this.refPlace3 = this._bitCalc(param.ref_place, 3);
      this.refPlace4 = this._bitCalc(param.ref_place, 4);

      // 試合結果
      this.rstSummary = param.rst_summary;
      this.rstBody    = param.rst_body;
      this.rstImgPath = param.rst_img_path;
      this.rstImgText = param.rst_img_text;

      this.editDateTime = param.edit_dt;
      this.breadItems.push({ text: param.title + '詳細', active: true});
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private setUploadImage(key: string) {
    this.imageType = 'event';
    this.imageKey  = key;
    this.imageId   = this.eventId;
    this.modalImageUpload = true;
  }

  private clearUploadImage() {
    this.imageType = '';
    this.imageKey  = '';
    this.imageId   = 0;
    this.modalImageUpload = false;
  }

  private setImageUrl(url: string) {
    if (this.imageKey === 'main') {
      this.imgPath = url;
    } else if (this.imageKey === 'result') {
      this.rstImgPath = url;
    }
    this.clearUploadImage();
  }

  private deleteImage() {
    this.imgPath = '';
  }

  private deleterstImgPath() {
    this.rstImgPath = '';
  }

  private updateBase(status: number) {
    if (!this.titleStatus || !this.fromIdStatus || !this.firstDateStatus || !this.firstTimeStatus
      || !this.openDateStatus || !this.openTimeStatus || !this.categoryStatus
      || !this.summaryStatus || !this.bodyStatus || !this.toTypeStatus
      || !this.entryDateStatus || !this.entryTimeStatus || !this.entryMsgStatus
      || !this.configsStatus || !this.refPlaceStatus) {
      alert('必須情報を入力してください。');
      return;
    }

    let tmpFromType;
    let tmpFromId = 1;
    if (this.fromId === 0) {
      tmpFromType = 1;
      tmpFromId = 1;
    } else {
      tmpFromType = 2;
      tmpFromId = this.fromId;
    }

    const target = this.fromData.find((item) => {
      return (item.value === this.fromId);
    });
    if (!target) {
      alert('送信先に不整合がみつかりました。');
      return;
    }

    let tmpToType = 0;
    tmpToType += (this.toType1) ? 1 : 0;
    tmpToType += (this.toType2) ? 2 : 0;
    tmpToType += (this.toType3) ? 4 : 0;
    tmpToType += (this.toType4) ? 8 : 0;

    let tmpToOId = 0;
    let tmpToDId = 0;
    let tmpToPId = 0;

    if (this._bitCalc(target.target, 1) && target.ref_type === 1) {
      tmpToOId = target.ref_id;
    }

    if (this._bitCalc(target.target, 2) && target.ref_type === 2) {
      tmpToDId = target.ref_id;
    }

    if (this._bitCalc(target.target, 3)) {
      tmpToPId = target.ref_pref;
    }

    let tmpRefPlace = 0;
    tmpRefPlace += (this.refPlace1) ? 1 : 0;
    tmpRefPlace += (this.refPlace2) ? 2 : 0;
    tmpRefPlace += (this.refPlace3) ? 4 : 0;
    tmpRefPlace += (this.refPlace4) ? 8 : 0;

    let tmpMode = 0;
    tmpMode += (this.mode1) ? 1 : 0;
    tmpMode += (this.mode2) ? 2 : 0;
    tmpMode += (this.mode3) ? 4 : 0;

    const postParam: {[key: string]: any} = {
      e_mode:     tmpMode,
      e_type:     this.eType,
      e_status:   status,
      re_id:      this.regularId,
      from_type:  tmpFromType,
      from_id:    tmpFromId,
      from_name:  this.fromName,
      from_url:   this.fromUrl,
      cycle:      this.cycle,
      first_dt:   this.firstDate + ' ' + this.firstTime + ':00',
      duration:   this.duration,
      category:   this.category,
      title:      this.title,
      summary:    this.summary,
      body:       this.body,
      img_path:   this.imgPath,
      img_text:   this.imgText,
      hp:         this.hp,
      fb:         this.fb,

      to_type:    tmpToType,
      to_o_id:    tmpToOId,
      to_d_id:    tmpToDId,
      to_p_id:    tmpToPId,

      entry_msg:   this.entryMsg,
      entry_opt:   this.entryOpt,
      entry_scorp: this.entryScorp,
      cond_cls:    this.condCls,
      cond_sex:    this.condSex,
      cond_age:    this.condAge,
      cond_lim:    this.condLim,
      cond_wait:   this.condWait,

      configs:     this.configs.join(','),
      ref_place:   tmpRefPlace,
      edit_dt:     this.editDateTime,
    };

    if (this.openDate !== '' && this.openTime !== '') {
      postParam.open_dt = this.openDate + ' ' + this.openTime + ':00';
    }

    if (this.closeDate !== '' && this.closeTime !== '') {
      postParam.close_dt = this.closeDate + ' ' + this.closeTime + ':00';
    }

    if (this.entryDate !== '' && this.entryTime !== '') {
      postParam.entry_dt = this.entryDate + ' ' + this.entryTime + ':00';
    }

    if (this.entryEndDate !== '' && this.entryEndTime !== '') {
      postParam.entry_end_dt = this.entryEndDate + ' ' + this.entryEndTime + ':00';
    }

    if (this.eventId === 0) {
      this.create(postParam);
    } else {
      this.update(postParam);
    }
  }

  private create(postParam: {[key: string]: any}) {
    const token = this.$store.getters.getToken();
    libAxios.post('event-data', token, postParam)
    .then((param: {[key: string]: any}) => {
      this.$router.replace({name: 'event-admin-event-detail', params: {id: param.pk}});
      this.$router.go(0);
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private updateBaseAT() {
    this.updateBase(this.eventStatus);
  }

  private update(postParam: {[key: string]: any}) {
    this.connection = true;
    const token = this.$store.getters.getToken();
    libAxios.put('event-data/' + this.eventId, token, postParam)
    .then((param: {[key: string]: any}) => {
      this.editDateTime = param.edit_dt;
      if (postParam.e_status) {
        this.eventStatus = postParam.e_status;
      }
      alert('更新しました');
      this.connection = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private updateResult(isFin: boolean) {
    const postParam: {[key: string]: any} = {
      rst_summary:  this.rstSummary,
      rst_body:     this.rstBody,
      rst_img_path: this.rstImgPath,
      rst_img_text: this.rstImgText,
      edit_dt:      this.editDateTime,
    };
    if (isFin) {
      postParam.e_status = this.C_EVENT_FIN;
    }
    this.updateEx(postParam);
  }

  private updateEx(postParam: {[key: string]: any}) {
    const token = this.$store.getters.getToken();
    libAxios.put('event-data-ex/' + this.eventId, token, postParam)
    .then((param: {[key: string]: any}) => {
      this.editDateTime = param.edit_dt;
      if (postParam.e_status) {
        this.eventStatus = postParam.e_status;
      }
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}

