
















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import Game10BallBall   from '@/components/score/match/Game10BallBall.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Game10BallBall,
  },
})
export default class Game10BallBreak extends Vue {
  @Prop() private isReverse!: boolean;
  @Prop() private isFirst!: boolean;
  private workBalls: number[] = [];
  private emptyBalls: number[] = [];

  @Emit('actionType') private breakPoint(breakType: string, balls: number[]): void { return; }

  private get _IsReverse(): boolean {
    if (this.isFirst) {
      return this.isReverse;
    } else {
      return !this.isReverse;
    }
  }

  private get isNoIn(): boolean {
    return (this.workBalls.length === 0);
  }

  private created(): void {
    this.workBalls = []; // 初期化
  }

  private breakType(breakChar: string): void {
    this.breakPoint(breakChar, this.workBalls);
  }
}
