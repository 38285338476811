
















































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventStep}     from '@/model/class/EventStep';
import {EventGame}     from '@/model/class/EventGame';
// import {EventPlayer}     from '@/model/class/EventPlayer';
import RoundRobinDetail   from '@/components/event/table/RoundRobinDetail.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

interface Result {
  id: number;
  type: number;
  win: number;
  lose: number;
  draw: number;
  pos: number;
  point: number;
  lost: number;
  turn: number;
  avg: number;
  highRun: number;
  runOut: number;
}

@Component({
  components: {
    RoundRobinDetail,
  },
})
export default class RoundRobin extends Vue {
  @Prop() private eventStep!: any;

  private players!: {[key: number]: Array<{id: number, name: string, nameShort: string, order: number, pos: number}>};
  private results!: {[key: number]: Result[]};
  //  Array<{id: number, win: number, lose: number, draw: number, pos: number, tuen: number, avg: number, hr: number}>};

  private isAllFin: boolean = true;

  @Emit('viewGame') private _ViewGame(key: string): void { return; }

  private viewGame(key: string): void {
    this._ViewGame(key);
  }

  private created(): void {
    const tmpPlayers = this.$store.getters['event/getPlayers']();
    this.players = {};
    this.results = {};
    for (let i = 0; i < this.eventStep.setCount; i++) {
      this.players[i] = [];
      this.results[i] = [];
    }

    Object.keys(tmpPlayers).map((str: string) => {
      const key = parseInt(str, 10);
      if (tmpPlayers[key].eventStepId === this.eventStep.eventStepId
        && tmpPlayers[key].setOrder >= 0) {
        const setNum = tmpPlayers[key].setNumber;
        this.players[setNum].push({
          id: tmpPlayers[key].eventPlayerId,
          name: tmpPlayers[key].usrName,
          nameShort: tmpPlayers[key].usrNameShort,
          order: tmpPlayers[key].setOrder,
          pos: tmpPlayers[key].setPosition,
        });
      }
    });

    const tmpGames = this.$store.getters['event/getGames']();
    for (const tmpKey in tmpGames) {
      if (tmpGames[tmpKey].eventStepId === this.eventStep.eventStepId) {
        if (tmpGames[tmpKey].eventGameStatus !== this.C_GAME_STATUS_FIX) {
          this.isAllFin = false;
          break;
        }
      }
    }

    if (this.eventStep.eventStepStatus < this.C_STEP_FIX) {
      for (let i = 0; i < this.eventStep.setCount; i++) {
        this.players[i].sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    } else {
      for (let i = 0; i < this.eventStep.setCount; i++) {
        this.players[i].sort((a, b) => {
          if (a.pos > b.pos) {
            return 1;
          } else {
            return -1;
          }
        });
      }

      // status > 4
      for (let i = 0; i < this.eventStep.setCount; i++) {
        for (const players of this.players[i]) {
          const j = players.order;
          const tmpResult: Result = {
            id: players.id,
            type: 0,
            win: 0,
            lose: 0,
            draw: 0,
            pos: players.pos,
            point: 0,
            lost: 0,
            turn: 0,
            avg: 0,
            highRun: 0,
            runOut: 0,
          };
          const maxLen = this.players[i].length;
          for (let k = 0; k < maxLen; k++) {
            if (j === k) {
              continue;
            }
            if (j < k) {
              const tmpKey = this.eventStep.eventStepId + '_' + i + '_' + j + '_' + k;
              const tmpGame = this.$store.getters['event/getGame'](tmpKey);
              tmpResult.type = tmpGame.eventGameType;
              if (tmpGame.winner === 1) {
                tmpResult.win++;
              } else if (tmpGame.winner === 2) {
                tmpResult.lose++;
              } else {
                tmpResult.draw++;
              }
              tmpResult.point = tmpGame.score1;
              tmpResult.lost = tmpGame.score2;

              if (tmpResult.avg < tmpGame.average1) {
                tmpResult.avg = tmpGame.average1;
              }

              if (tmpResult.highRun < tmpGame.highRun1) {
                tmpResult.highRun = tmpGame.highRun1;
              }

              if (tmpGame.result) {
                tmpResult.runOut += tmpGame.result.split('A').length - 1;
              }
            } else {
              const tmpKey = this.eventStep.eventStepId + '_' + i + '_' + k + '_' + j;
              const tmpGame = this.$store.getters['event/getGame'](tmpKey);
              tmpResult.type = tmpGame.eventGameType;
              if (tmpGame.winner === 1) {
                tmpResult.lose++;
              } else if (tmpGame.winner === 2) {
                tmpResult.win++;
              } else {
                tmpResult.draw++;
              }
              tmpResult.point = tmpGame.score2;
              tmpResult.lost = tmpGame.score1;

              if (tmpResult.avg < tmpGame.average2) {
                tmpResult.avg = tmpGame.average2;
              }
              if (tmpResult.highRun < tmpGame.highRun2) {
                tmpResult.highRun = tmpGame.highRun2;
              }

              if (tmpGame.result) {
                tmpResult.runOut += tmpGame.result.split('B').length - 1;
              }
            }
          }
          this.results[i].push(tmpResult);
        }
      }
    }
  }
}
