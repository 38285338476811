



























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Header        from '@/components/common/Header.vue';
import PointTable    from '@/components/score/multi/PointTable.vue';
import FreePointTable    from '@/components/score/multi/FreePointTable.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    PointTable,
    FreePointTable,
  },
})
export default class ScoreMultiList extends Vue {
  private usrType!: number;
  private gameName!: string;
  private gameNum: number = 0;
  private nextUrl!: {name: string};
  private searchParam: {[key: string]: any} = {};
  private gameList: any[] = [];
  private modalShow: boolean = false;
  private gameCnt: number = 0;
  private playerData: Array<{[key: string]: any}> = [];
  private frameData: Array<{[key: string]: any}> = [];
  private currentPage: number = 0;
  private rows: number = 0;
  private perPage: number = 10;
  private connection: boolean = true;

  private breadItems: Array<{[key: string]: any}> = [
    {text: 'Top', to: { name: 'top' }},
    {text: 'Score', to: { name: 'score' }},
  ];

  @Watch('currentPage')
  private onTextChanged(newNum: number, oldNum: number) {
    this.searchParam.game_type = this.gameNum;
    this.searchParam.page_num = newNum;
    this.searchParam.page_limit = 10;
    this.searchParam.order_column = 'gm_id';
    this.searchParam.order_option = 'DESC';
    this.$store.dispatch('searchScoreMultiAction', this.searchParam);
    this.getMultiScoreList();
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          const pageNum = 1;
          this._getResumeData(0); // Gameではないので戻り値は無視
          this.usrType = this.$store.getters.getUsrType();
          this.gameName = this.$route.params.type;

          if (this.gameName === this.C_NAME_POINT) {
            this.breadItems.push({ text: 'PointGame Score List', active: true});
            this.gameNum = this.C_NUM_POINT;
            this.nextUrl = {name: 'score-point'};
          } else if (this.gameName === this.C_NAME_FREE_POINT) {
            this.gameNum = this.C_NUM_FREE_POINT;
            this.breadItems.push({ text: 'FreePointGame Score List', active: true});
            this.nextUrl = {name: 'score-free-point'};
          } else {
            throw new Error('引数エラー');
          }
          this.currentPage = pageNum;
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }
  private getMultiScoreList() {
    this.connection = true;

    // 保存先から取得する
    const saveParam = this.$store.getters.getSearchScoreMulti();
    const search = JSON.parse(saveParam);

    const token = this.$store.getters.getToken();
    libAxios.get('game-data/multi', token, search)
    .then((param) => {
      const tempList: any[] = [];
      const nowDT = this._formatDate(new Date(), 'Y-m-d');

      for (const item of param.data) {
        const tmpItem: {[key: string]: any} = {};
        tmpItem.gameID = item.gm_id;
        tmpItem.refID = item.L_gmu_id;
        let tmpViewDT = item.start_dt;
        if (tmpViewDT.substr(0, 10) === nowDT) {
          tmpItem.isToday = true;
          tmpViewDT = tmpViewDT.substr(11, 5);
        } else {
          tmpItem.isToday = false;
          tmpViewDT = tmpViewDT.substr(2, 8);
        }
        tmpItem.viewDT = tmpViewDT;
        tmpItem.score = item.L_score;
        tmpItem.names = item.names;
        tmpItem.place = item.d_name;
        if (item.d_id !== 0 && item.d_tbl !== 0) {
          tmpItem.place += ' ' + item.d_tbl + '番台';
        }
        // 確定か未確定か
        tmpItem.isFix = (item.gm_status === this.C_STATUS_FIX);
        tmpItem.isMark = false;
        if (!tmpItem.isFix && item.e_id === 0 && item.L_checked === 1) {
          tmpItem.isMark = true;
        }
        tmpItem.inning = item.inning;
        tmpItem.param = [];
        tmpItem.param.push(item.param);

        if (param.child.cnt !== 0) {
          for (const child of param.child.data) {
            if (child.gm_pre_id === item.gm_id) {
              tmpItem.param.push(child.param);
              tmpItem.inning += child.inning;
              tmpItem.score = child.L_score;
            }
          }
        }
        tempList.push(tmpItem);
      }
      this.gameList = tempList;
      this.rows = param.cnt;
      this.connection = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private viewDetail(num: number) {
    const gameParam = this.gameList[num];
    const paramList = gameParam.param;

    this.playerData = [];
    this.frameData = [];
    let counter = 0;
    if (this.gameName === this.C_NAME_POINT) {
      for (const param of paramList) {
        const tmpParam = JSON.parse(param);
        this.playerData.push(tmpParam.player);
        this.frameData.push(tmpParam.data);
        counter++;
      }
    } else if (this.gameName === this.C_NAME_FREE_POINT) {
      for (const param of paramList) {
        const tmpParam = JSON.parse(param);
        this.playerData.push(tmpParam);
        counter++;
      }
    }
    if (this.frameData.length > 0) {
      const frameLen = this.frameData[0].length - 1;
      this.$store.dispatch('multi/setConfigParamAction', {key: 'limitInning', val: frameLen});
    } else {
      this.$store.dispatch('multi/setConfigParamAction', {key: 'limitInning', val: 10});
    }

    this.gameCnt = counter;
    this.modalShow = true;
  }

  private async delScore(gameID: number) {
    if (!window.confirm('削除してよいですか？\n他のメンバーのデータとして残ることがあります。')) {
      return;
    }
    this.connection = true;
    const token = this.$store.getters.getToken();
    return await libAxios.delete('game-data/multi/' + gameID, token, {})
    .then(() => {
      this.getMultiScoreList();
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private async fixScore(gameID: number, refID: number) {
    this.connection = true;
    const postParam = {
      command: 'check',
      gmu_id: refID,
    };

    const token = this.$store.getters.getToken();
    return await libAxios.post('game-data/multi/' + gameID, token, postParam)
    .then(() => {
      // this.getMultiScoreList();
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
