






























import {Component, Emit, Prop, Vue}  from 'vue-property-decorator';
import Header          from '@/components/common/Header.vue';
import Game9BallReport from '@/components/score/match/Game9BallReport.vue';
import Game10BallReport from '@/components/score/match/Game10BallReport.vue';
import Game14OneReport from '@/components/score/match/Game14OneReport.vue';
import libAxios        from '@/mixin/LibAxios';
import BootstrapVue    from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    Game9BallReport,
    Game10BallReport,
    Game14OneReport,
  },
})
export default class ScoreReport extends Vue {
  private usrType!: number;
  private gameName: string = '';
  private gameNum: number = 0;
  private viewGameNum: number = 0;
  private setType!: number; // 1 solo 2 match-member 3 match-staff
  private gameData!: Array<{[key: string]: any}>;
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: 'Score', to: { name: 'score' }},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this._getResumeData(0); // Gameではないので戻り値は無視
          this.usrType = this.$store.getters.getUsrType();
          this.gameName = this.$route.params.type;

          if (this.gameName === this.C_NAME_9B) {
            this.gameNum = this.C_NUM_9B;
            this.breadItems.push({text: '9Ball 成績レポート', active: true});
          } else if (this.gameName === this.C_NAME_10B) {
            this.gameNum = this.C_NUM_10B;
            this.breadItems.push({text: '10Ball 成績レポート', active: true});
          } else if (this.gameName === this.C_NAME_14_1) {
            this.gameNum = this.C_NUM_14_1;
            this.breadItems.push({text: '14-1 成績レポート', active: true});
          } else if (this.gameName === this.C_NAME_ROTE) {
            this.gameNum = this.C_NUM_ROTE;
          } else {
            throw new Error('引数エラー');
          }
          this.getScoreReport();
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getScoreReport() {
    const token = this.$store.getters.getToken();
    return await libAxios.get('report-data/match/' + this.gameNum, token, {})
    .then((param: any) => {
      if (param === '') {
        this.viewGameNum = 999;
      } else {
        this.gameData = param;
        this.viewGameNum = this.gameNum;
      }
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
