

































import {Component, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue'; // @ is an alias to /src
// import LibCommon from '@/mixin/LibCommon.ts'; 実装方法を残すためコメントアウト
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  // mixins: [LibCommon],実装方法を残すためコメントアウト
  components: {
    Header,
  },
})
export default class About extends Vue {
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: 'About', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then(() => {
        this._getResumeData(0); // Gameではないので戻り値は無視
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }
}
