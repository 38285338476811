

























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import AdminHeader  from '@/components/admin/common/AdminHeader.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    AdminHeader,
  },
})
export default class DeptUsrList extends Vue {
  private usrRole: number = this.$store.getters.getUsrRole();
  private roles: any[] = this.$store.getters.getRoles();

  private mode: number = 0;  // 0:初期値 1:組織選択 2:部門選択
  private orgList: Array<{value: string, text: string}> = [];
  private org: string = '';
  private orgSel: string = '';
  private deptList: Array<{value: string, text: string}> = [];
  private dept: string = '';
  private deptSel: string = '';
  private usrData: { [key: string]: any } = {};
  private staffList: Array<{id: number, name: string, dt: string, role: number}> = []; // 店舗用アカウント
  private mainList: Array<{id: number, name: string, dt: string, role: number}> = []; // メイン所属
  private subList: Array<{id: number, name: string, dt: string, role: number}> = []; // サブアカウント
  private entryList: Array<{id: number, name: string, dt: string, role: number}> = []; // メインアカウント
  private subEntryList: Array<{id: number, name: string, dt: string, role: number}> = []; // メインアカウント
  private modalEntry: boolean = false;
  private entrySel: number = 0;
  private entryName: string = '';
  private playerName: string = '';
  private modalReEntry: boolean = false;
  private modalPassword: boolean = false;
  private modalName: boolean = false;
  private password: string = '';
  private connection: boolean = true;

  private breadItems: Array<{[key: string]: any}> = [
    { text: '管理Top', to: { name: 'admin-top' }},
    { text: '所属一覧', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (!isLogin) {
          this.$router.push({name: 'top'});
        }

        let canCallApi = false;
        // switch 文の書式
        switch (this.usrRole) {
          case this.C_ROLE_SYSTEM:
          case this.C_ROLE_ADMIN:
          case this.C_ROLE_MANAGER:
            canCallApi = true;
            break;
          case this.C_ROLE_STAFF:
          case this.C_ROLE_MEMBER:
            for (const tmpRole of this.roles) {
              if (tmpRole.ref_type === this.C_REF_ORG) {
                canCallApi = true;
              } else if (tmpRole.ref_type === this.C_REF_DEPT) {
                canCallApi = true;
              }
            }
            break;
        }
        if (canCallApi) {
          this.getOrgListByRole();
        } else {
          // 権限エラー
          this.$router.push({name: 'error'});
          return;
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      if (e instanceof Error) {
        // Here you go.
      }
      this.$router.push({name: 'error'});
    }
  }

  private getOrgListByRole() {
    const token = this.$store.getters.getToken();
    libAxios.get('org-for-dept', token, {})
    .then((param: {[key: string]: any}) => {
      if (param.cnt  === 0) {
        return;
      }

      for (const tmpOrg of param.data) {
        this.orgList.push({value: tmpOrg.o_id, text: tmpOrg.o_name});
      }

      if (param.cnt  === 1) {
        this.org = param.data[0].o_id;
        this.orgSel = this.org;
        this.mode = 2;
        this.getDeptByRole(true);
      } else {
        this.mode = 1;
        this.connection = false;
      }
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.message) {
        alert(tmpJson.message);
      }
      this.$router.push({name: 'error'});
    });
  }

  private getDeptByRole(isFirst: boolean) {
    const token = this.$store.getters.getToken();
    libAxios.get('dept-for-dept/' + this.orgSel, token, {})
    .then((param: {[key: string]: any}) => {
      if (param.cnt  === 0) {
        return;
      }
      this.deptList = [];
      for (const tmpDept of param.data) {
        this.deptList.push({value: tmpDept.d_id, text: tmpDept.d_name});
        this.usrData[tmpDept.d_id] = [];
      }

      if (param.cnt  === 1) {
        // TODO
        this.dept = param.data[0].d_id;
        this.deptSel = this.dept;
        if (isFirst) {
          this.mode = 3;
        }
        this.getDeptUsrByRole();
      } else {
        if (isFirst) {
          this.mode = 2;
        }
        this.connection = false;
      }
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private getDeptUsrByRole() {
    const token = this.$store.getters.getToken();
    libAxios.get('usr-for-dept/' + this.dept, token, {})
    .then((param: {[key: string]: any}) => {
      if (param.cnt  === 0) {
        return;
      }

      this.usrData[this.dept] = param.data;
      this.viewUsrList();
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private clearList() {
    this.staffList = [];
    this.entryList = [];
    this.mainList = [];
    this.subEntryList = [];
    this.subList = [];

  }
  private viewUsrList() {
    // 表示関連のパラメータを初期化
    this.entrySel = 0;
    this.modalEntry = false;
    const tmpUsrs = this.usrData[this.dept];
    this.clearList();

    for (const usr of tmpUsrs) {
      const tmpUsr = {id: usr.u_id, name: usr.u_pri_n, dt: usr.edit_dt, role: usr.u_role};
      if (usr.u_type === 2) {
        this.staffList.push(tmpUsr);
      } else if (usr.u_type === 0) {
        if (usr.L_du_lv === 0) {
          this.entryList.push(tmpUsr);
        } else {
          this.subEntryList.push(tmpUsr);
        }
      } else {
        if (usr.L_du_lv === 0) {
          this.mainList.push(tmpUsr);
        } else {
          this.subList.push(tmpUsr);
        }
      }
    }
    this.connection = false;
  }

  private setOrg() {
    if (!this.org || this.org === this.orgSel) {
      alert('組織を選択してください');
      return;
    }
    this.orgSel = this.org;
    this.clearList();
    this.getDeptByRole(false);
  }

  private setDetp() {
    if (!this.dept || this.dept === this.deptSel) {
      alert('所属を選択してください');
      return;
    }
    this.deptSel = this.dept;
    if (this.usrData[this.deptSel].length === 0) {
      this.getDeptUsrByRole();
    } else {
      this.viewUsrList();
    }
  }

  private setEditPlayer(entryId: string) {
    window.console.log(entryId);
  }

  // スタッフの情報変更
  private resetPlayer(entryId: number, mode: string) {
    this.password = '';
    this.playerName = '';
    for (const tmpStaff of this.staffList) {
      if (tmpStaff.id === entryId) {
        this.entrySel = entryId;
        this.entryName = tmpStaff.name;
        if (mode === 'password') {
          this.modalPassword =  true;
        } else if (mode === 'name') {
          this.modalName =  true;
        }
        return;
      }
    }
  }

  private checkEntryPlayer(entryId: number) {
    for (const tmpEntry of this.entryList) {
      if (tmpEntry.id === entryId) {
        this.entrySel = entryId;
        this.entryName = tmpEntry.name;
        this.modalEntry = true;
        return;
      }
    }
  }

 private checkReEntryPlayer(entryId: number) {
    for (const tmpEntry of this.subEntryList) {
      if (tmpEntry.id === entryId) {
        this.entrySel = entryId;
        this.entryName = tmpEntry.name;
        this.modalReEntry = true;
        return;
      }
    }
  }
  // 一人用だけど多人数と一緒
  private regEntryPlayer() {
    const entries: Array<{u_id: number, edit_dt: string}> = [];
    for (const tmpEntry of this.entryList) {
      if (tmpEntry.id === this.entrySel) {
        entries.push( {u_id: tmpEntry.id,  edit_dt: tmpEntry.dt});
        const postParam: {[key: string]: any} = {
          command: 'belong',
          entries,
        };
        this.regEntries(postParam, true);
        return;
      }
    }
    alert('データに不備があります。再描画します。');
    this.getDeptUsrByRole();
  }

  private async regEntries(param: {[key: string]: any}, reView: boolean) {
    const token = this.$store.getters.getToken();
    return await libAxios.post('usr-for-dept/' + this.dept, token, param)
    .then((data: {[key: string]: any}) => {
      alert('更新しました');
      if (reView) {
        this.getDeptUsrByRole();
      }
    }).catch((e: Error) => {
      // throw new Error(e.message);
    });
  }

  // 再登録はひとりづつ
  private regReEntryPlayer() {
    const entries: Array<{u_id: number, edit_dt: string}> = [];
    for (const tmpEntry of this.subEntryList) {
      if (tmpEntry.id === this.entrySel) {
        entries.push( {u_id: tmpEntry.id,  edit_dt: tmpEntry.dt});
        const postParam: {[key: string]: any} = {
          command: 'rebelong',
          entries,
        };
        this.regEntries(postParam, true);
        return;
      }
    }
    alert('データに不備があります。再描画します。');
    this.getDeptUsrByRole();
  }

  // パスワード変更サーバ送信
  private regPasswordPlayer(event: any) {
    event.preventDefault();
    if (this.password === '') {
      alert('パスワードを入力してください');
      return false;
    }
    const postParam: {[key: string]: any} = {
      command: 'password',
      u_id: this.entrySel,
      pwd: this.password,
    };
    this.regEntries(postParam, false);
  }

  // 名前変更サーバ送信
  private regNamePlayer(event: any) {
    event.preventDefault();
    if (this.playerName === '') {
      alert('名前を入力してください');
      return false;
    }
    const postParam: {[key: string]: any} = {
      command: 'name',
      u_id: this.entrySel,
      name: this.playerName,
    };
    this.regEntries(postParam, true);
    this.modalName = false;
  }
}
