



























































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MatchDataParam} from '@/model/interface/MatchDataParam';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {},
})
export default class Game9BallPlayLv1 extends Vue {
  @Prop() private matchData!: MatchDataParam;
  @Prop() private isReverse!: boolean;

  private config!: {[key: string]: number};

  @Emit('actionType') private playPointLv1(playType: string, balls: number[]): void { return; }

  private get useRunout1(): boolean {
    if (this.matchData.isFirst && this.matchData.isRunOut) {
      return true;
    } else {
      return false;
    }
  }

  private get useRunout2(): boolean {
    if (!this.matchData.isFirst && this.matchData.isRunOut) {
      return true;
    } else {
      return false;
    }
  }

  private get usePush(): boolean {
    return (this.config.pushOut === 1 && this.matchData.canPush);
  }

  private get usePush1(): boolean {
    return this.matchData.isFirst;
  }

  private get usePush2(): boolean {
    return !this.matchData.isFirst;
  }

  private created(): void {
    this.config = this.$store.getters['match/getConfig']();
  }

  private playTypeLv1(playChar: string, playerNum: number): void {
    if (playChar === 'W') {
      if ((this.matchData.isFirst && playerNum === 2) || (!this.matchData.isFirst && playerNum === 1)) {
        this.playPointLv1('V', []);
        return;
      }
    }
    this.playPointLv1(playChar, []);
  }
}
