














import {Component, Prop, Vue} from 'vue-property-decorator';
import AdminHeader  from '@/components/admin/common/AdminHeader.vue';
import OrgParam     from '@/components/admin/OrgParam.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    AdminHeader,
    OrgParam,
  },
})
export default class OrgDetail extends Vue {
  private errParam: string[] = [];
  private connection: boolean = false;

  private breadItems: Array<{[key: string]: any}> = [
    { text: '管理Top', to: { name: 'admin-top' }},
    { text: '組織一覧', to: { name: 'admin-org-list' }},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        this.breadItems.push({ text: '組織新規作成', active: true});
      }).catch((e: Error) => {
        // throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private get refs(): any {
    return this.$refs;
  }

  private async saveData(sendParam: {[key: string]: any} ) {
    this.connection = true;
    this.errParam = [];
    sendParam.is_new = 1;
    const token = this.$store.getters.getToken();
    return await libAxios.post('org-for-org' , token, sendParam)
    .then((param: {[key: string]: any}) => {
      alert('更新しました');
      this.$router.push({ name: 'admin-org-detail', params: {id: param.pk} });
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        for (const err of Object.keys(tmpJson.validate)) {
          this.errParam.push(err);
        }
        window.console.log(this.errParam);
        this.refs.org_param.checkErrMsg();
      } else {
        this.$router.push({name: 'error'});
      }
      this.connection = false;
    });
  }
}
