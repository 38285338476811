import {MatchParam}       from '@/model/interface/MatchParam';
import {MatchPlayerParam} from '@/model/interface/MatchPlayerParam';
import {MatchEventParam}  from '@/model/interface/MatchEventParam';
import {MatchGameParam}   from '@/model/interface/MatchGameParam';
import {PlaceParam}       from '@/model/interface/PlaceParam';
import libAxios           from '@/mixin/LibAxios';

export default {
  namespaced: true,
  state: {
    gameId: 0,
    gameType: 0,
    gameStatus: 0,
    gameMode: 0,
    event: {id: 0, gameId: 0, name: '', playerId1: 0, playerId2: 0},
    place: {id: 0, name: '', tableNum: 0},
    player1: {id: 0, name: '', race_to: 0},
    player2: {id: 0, name: '', race_to: 0},
    config: {},
    startDateTime: '',
    first: 0,
    matchGame: {
      g_status: 0,
      eg_id: 0,
      end_dt: '',
      play_ts: 0,
      score_1: 0,
      turn_1: 0,
      avg_1: 0,
      highrun_1: 0,
      score_2: 0,
      turn_2: 0,
      avg_2: 0,
      highrun_2: 0,
      winner: 0,
      result: '',
      param: '',
    },
    scorp: 0,
    checked: 0,
  } as MatchParam,
  getters: {
    getToken: (state: MatchParam, getters: any, RootState: any) => (): string => {
      return RootState.token;
    },
    getGameId: (state: MatchParam, getters: any) => (): number => {
      return state.gameId;
    },
    getGameType: (state: MatchParam, getters: any) => (): number => {
      return state.gameType;
    },
    getGameStatus: (state: MatchParam, getters: any) => (): number => {
      return state.gameStatus;
    },
    getGameMode: (state: MatchParam, getters: any) => (): number => {
      return state.gameMode;
    },
    getEvent: (state: MatchParam, getters: any) => (): MatchEventParam => {
      return state.event;
    },
    getEventId: (state: MatchParam, getters: any) => (): number => {
      return state.event.id;
    },
    getPlace: (state: MatchParam, getters: any) => (): PlaceParam => {
      return state.place;
    },
    getPlayer1: (state: MatchParam, getters: any) => (): MatchPlayerParam => {
      return state.player1;
    },
    getPlayer2: (state: MatchParam, getters: any) => (): MatchPlayerParam => {
      return state.player2;
    },
    getConfig: (state: MatchParam, getters: any) => (): {[key: string]: number} => {
      return state.config;
    },
    getConfigParam: (state: MatchParam, getters: any) => (key: string): number => {
      return state.config[key];
    },
    getStartDateTime: (state: MatchParam, getters: any) => (): string => {
      return state.startDateTime;
    },
    getFirst: (state: MatchParam, getters: any) => (): number => {
      return state.first;
    },
    // 詳細
    getPlayer1Name: (state: MatchParam, getters: any) => (): string => {
      return state.player1.name;
    },
    getPlayer1RaceTo: (state: MatchParam, getters: any) => (): number => {
      return state.player1.race_to;
    },
    getPlayer2Name: (state: MatchParam, getters: any) => (): string => {
      return state.player2.name;
    },
    getPlayer2RaceTo: (state: MatchParam, getters: any) => (): number => {
      return state.player2.race_to;
    },
    getTimeSpan: (state: MatchParam, getters: any) => (): number => {
      return state.matchGame.play_ts;
    },
    getWinner: (state: MatchParam, getters: any) => (): number => {
      return state.matchGame.winner;
    },
    getResult: (state: MatchParam, getters: any) => (): string => {
      return state.matchGame.result;
    },
    getPlayer1Score: (state: MatchParam, getters: any) => (): number => {
      return state.matchGame.score_1;
    },
    getPlayer2Score: (state: MatchParam, getters: any) => (): number => {
      return state.matchGame.score_2;
    },
    getMatchGameParam: (state: MatchParam, getters: any) => (): string => {
      return state.matchGame.param;
    },
  },
  mutations: {
    setGameId(state: MatchParam, payload: number) {
      state.gameId = payload;
    },
    setConfig(state: MatchParam, payload: {[key: string]: number}) {
      state.config = payload;
    },
    setConfigParam(state: MatchParam, payload: {key: string, val: number}) {
      state.config[payload.key] = payload.val;
    },
    addPlayer1RaceTo(state: MatchParam, payload: number) {
      const tmpRaceTo = state.player1.race_to + payload;
      if (tmpRaceTo > 0) {
        state.player1.race_to = tmpRaceTo;
      }
    },
    addPlayer2RaceTo(state: MatchParam, payload: number) {
      const tmpRaceTo = state.player2.race_to + payload;
      if (tmpRaceTo > 0) {
        state.player2.race_to = tmpRaceTo;
      }
    },
    setPlayer1Score(state: MatchParam, payload: number) {
      state.matchGame.score_1 = payload;
    },
    setPlayer2Score(state: MatchParam, payload: number) {
      state.matchGame.score_2 = payload;
    },
    addPlayer1Score(state: MatchParam, payload: number) {
      state.matchGame.score_1 += payload;
    },
    addPlayer2Score(state: MatchParam, payload: number) {
      state.matchGame.score_2 += payload;
    },
    setPlayer1Highrun(state: MatchParam, payload: number) {
      state.matchGame.highrun_1 = payload;
    },
    setTurnAvr(state: MatchParam, payload: {p1Inning: number, p2Inning: number}) {
      state.matchGame.turn_1 = payload.p1Inning;
      if (payload.p1Inning > 0) {
        state.matchGame.avg_1 =  (state.matchGame.score_1 / payload.p1Inning);
      }
      state.matchGame.turn_2 = payload.p2Inning;
      if (payload.p2Inning > 0) {
        state.matchGame.avg_2 =  (state.matchGame.score_2 / payload.p2Inning);
      }
    },
    setPlayer2Highrun(state: MatchParam, payload: number) {
      state.matchGame.highrun_2 = payload;
    },
    addGameResult(state: MatchParam, payload: string) {
      state.matchGame.result += payload;
    },
    setGameResult(state: MatchParam, payload: string) {
      state.matchGame.result = payload;
    },
    setGameWinner(state: MatchParam, payload: number) {
      state.matchGame.winner = payload;
    },
    clearData(state: MatchParam) {
      state.gameId = 0;
      state.gameType = 0;
      state.gameStatus = 0;
      state.gameMode = 0;
      state.event = {id: 0, gameId: 0, name: '', playerId1: 0, playerId2: 0};
      state.place = {id: 0, name: '', tableNum: 0};
      state.player1 = {id: 0, name: '', race_to: 0};
      state.player2 = {id: 0, name: '', race_to: 0};
      state.config = {};
      state.startDateTime = '';
      state.first = 0;
      // state.matchGame = gameParamは別設定;
      state.scorp = 0;
      state.checked = 0;
    },
    retryData(state: MatchParam) {
      state.gameId = 0;
      state.gameStatus = 0;
      state.gameMode = 0;
      state.startDateTime = '';
      state.first = 0;
    },
    clearPostData(state: MatchParam) {
      state.matchGame = {
        g_status: 0,
        eg_id: state.event.gameId,
        end_dt: '',
        play_ts: 0,
        score_1: 0,
        turn_1: 0,
        avg_1: 0,
        highrun_1: 0,
        score_2: 0,
        turn_2: 0,
        avg_2: 0,
        highrun_2: 0,
        winner: 0,
        result: '',
        param: '',
      };
    },
    setResumeData(state: MatchParam, payload: {[key: string]: any}) {
      state.gameId     = payload.gameId;
      state.gameType   = payload.gameType;
      state.gameStatus = payload.gameStatus;
      state.gameMode   = payload.gameMode;
      state.event      = payload.event;
      state.place      = payload.place;
      state.player1    = payload.player1;
      state.player2    = payload.player2;
      state.config     = payload.config;
      state.startDateTime = payload.startDateTime;
      state.first      = payload.first;
      state.matchGame  = payload.matchGame;
      state.scorp      = payload.scorp;
      state.checked    = payload.checked;
    },
    initData(state: MatchParam, payload: {[key: string]: any}) {
      state.gameType   = payload.gameType;
      state.gameStatus = 0; // C_STATUS_INIT
      state.place      = payload.place;
      state.player1    = payload.player1;
      state.player2    = payload.player2;
      state.checked    = payload.checked;
    },
    initGuest(state: MatchParam, payload: {[key: string]: any}) {
      state.gameType   = payload.gameType;
      state.gameStatus = 0; // C_STATUS_INIT
      state.player1    = payload.player1;
      state.player2    = payload.player2;
    },
    initEventData(state: MatchParam, payload: {[key: string]: any}) {
      state.gameType   = payload.gameType;
      state.gameStatus = 0; // C_STATUS_INIT
      state.place      = payload.place;
      state.player1    = payload.player1;
      state.player2    = payload.player2;
      state.checked    = payload.checked;
      state.event      = payload.event;
      state.config     = payload.config;
    },
    resumeEventData(state: MatchParam, payload: {[key: string]: any}) {
      state.gameStatus    = payload.g_status;
      state.startDateTime = payload.start_dt;
      state.first         = payload.first;
      state.matchGame  = {
        g_status: payload.g_status,
        eg_id: payload.eg_id,
        end_dt: payload.end_dt,
        play_ts: payload.play_ts,
        score_1: payload.score_1,
        turn_1: payload.turn_1,
        avg_1: payload.avg_1,
        highrun_1: payload.highrun_1,
        score_2: payload.score_2,
        turn_2: payload.turn_2,
        avg_2: payload.avg_2,
        highrun_2: payload.highrun_2,
        winner: payload.winner,
        result: payload.result,
        param: payload.param,
      };
    },
    readyData(state: MatchParam) {
      state.gameStatus = 2; // C_STATUS_READY
    },
    startData(state: MatchParam, payload: {[key: string]: any}) {
      state.gameStatus = 3; // C_STATUS_PLAY
      state.startDateTime = payload.startDateTime;
      state.first = payload.first;
      state.matchGame  = {
        g_status: 3,
        eg_id: state.event.gameId,
        end_dt: '',
        play_ts: 0,
        score_1: 0,
        turn_1: 0,
        avg_1: 0,
        highrun_1: 0,
        score_2: 0,
        turn_2: 0,
        avg_2: 0,
        highrun_2: 0,
        winner: 0,
        result: '',
        param: '',
      };
    },
    saveData(state: MatchParam, payload: {[key: string]: any}) {
      state.matchGame.end_dt = payload.endDateTime;
      state.matchGame.play_ts = payload.playTimeSpan;
      state.matchGame.param = payload.param;
    },
    endData(state: MatchParam) {
      state.gameStatus = 6; // C_STATUS_END
    },
  },
  actions: {
    async initAction(context: any, param: {
      gameType: number,
      place: PlaceParam,
      player1: MatchPlayerParam,
      player2: MatchPlayerParam,
      checked: number,
    }) {
      // 初期化
      window.localStorage.removeItem('currentGame');
      context.commit('clearData');
      context.commit('clearPostData');
      const token = context.getters.getToken();
      const postParam = {
        g_type:   param.gameType,
        eg_id:    context.state.event.gameId,
        d_id:     param.place.id,
        d_name:   param.place.name,
        d_tbl:    param.place.tableNum,
        u_id_1:   param.player1.id,
        u_name_1: param.player1.name,
        u_id_2:   param.player2.id,
        u_name_2: param.player2.name,
        checked:  param.checked,
      };
      return await libAxios.post('game-data/match', token, postParam)
      .then((data: {[key: string]: any}) => {
        context.commit('setGameId', data.g_id);
        context.commit('initData', param);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async initEventAction(context: any, param: {
      gameType: number,
      place: PlaceParam,
      player1: MatchPlayerParam,
      player2: MatchPlayerParam,
      checked: number,
      event: MatchEventParam,
      config: {[key: string]: number},
    }) {
      // 初期化
      window.localStorage.removeItem('currentGame');
      context.commit('clearData');
      context.commit('clearPostData');
      const token = context.getters.getToken();
      const postParam = {
        g_type:   param.gameType,
        eg_id:    param.event.gameId,
        d_id:     param.place.id,
        d_name:   param.place.name,
        d_tbl:    param.place.tableNum,
        u_id_1:   param.player1.id,
        u_name_1: param.player1.name,
        u_id_2:   param.player2.id,
        u_name_2: param.player2.name,
        checked:  param.checked,
      };
      return await libAxios.post('game-data/match', token, postParam)
      .then((data: {[key: string]: any}) => {
        context.commit('setGameId', data.g_id);
        context.commit('initEventData', param);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async resumeEventAction(context: any, param: {
      gameType: number,
      place: PlaceParam,
      player1: MatchPlayerParam,
      player2: MatchPlayerParam,
      checked: number,
      event: MatchEventParam,
      config: {[key: string]: number},
      g_id: number,
    }) {
      const token = context.getters.getToken();
      return await libAxios.get('game-data/match/' + param.g_id, token, {})
      .then((data: {[key: string]: any}) => {
        context.commit('setGameId', data.g_id);
        context.commit('initEventData', param);
        context.commit('resumeEventData', data);
        window.console.log(context.state);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    async retryAction(context: any) {
      const token = context.getters.getToken();
      const postParam = {
        g_type:   context.state.gameType,
        eg_id:    context.state.event.gameId,
        d_id:     context.state.place.id,
        d_name:   context.state.place.name,
        d_tbl:    context.state.place.tableNum,
        u_id_1:   context.state.player1.id,
        u_name_1: context.state.player1.name,
        u_id_2:   context.state.player2.id,
        u_name_2: context.state.player2.name,
        checked:  context.state.checked,
      };
      return await libAxios.post('game-data/match', token, postParam)
      .then((data: {[key: string]: any}) => {
        context.commit('setGameId', data.g_id);
        window.localStorage.setItem('currentGame', JSON.stringify(context.state));
        return true;
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },
    initGuestAction(context: any, param: {
      gameType: number,
      player1: MatchPlayerParam,
      player2: MatchPlayerParam,
    }) {
      context.commit('clearData');
      context.commit('clearPostData');
      context.commit('initGuest', param);
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
    },
    retryGuestAction(context: any) {
      context.commit('clearPostData');
      context.commit('retryData');
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
    },
    // postしない
    readyAction(context: any) {
      context.commit('readyData');
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
    },
    toGameAction(context: any) {
      const token = context.getters.getToken();
      const postParam = {
        g_status:  context.state.gameStatus,
        eg_id:     context.state.event.gameId,
        race_to_1: context.state.player1.race_to,
        race_to_2: context.state.player2.race_to,
        config:    JSON.stringify(context.state.config),
        scorp:     context.state.config.isOpen,
      };
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      if (context.state.gameId !== 0) {
        libAxios.asyncPut('game-data/match/' + context.state.gameId, token, postParam);
      }
    },
    startAction(context: any, param: {
      startDateTime: string,
      first: number,
    }) {
      // 同期は不要
      context.commit('startData', param);
      const token = context.getters.getToken();
      const postParam = {
        g_status:  context.state.gameStatus,
        eg_id:     context.state.event.gameId,
        start_dt:  param.startDateTime,
        race_to_1: context.state.player1.race_to,
        race_to_2: context.state.player2.race_to,
        config:    JSON.stringify(context.state.config),
        scorp:     context.state.config.isOpen,
        first:     param.first,
      };
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      if (context.state.gameId !== 0) {
        libAxios.asyncPut('game-data/match/' + context.state.gameId, token, postParam);
      }
    },
    saveAction(context: any, param: {
      endDateTime: string,
      playTimeSpan: number,
      param: string,
    }) {
      // 同期は不要
      context.commit('saveData', param);
      const token = context.getters.getToken();
      window.localStorage.setItem('currentGame', JSON.stringify(context.state));
      if (context.state.gameId !== 0) {
        libAxios.asyncPut('game-data/match/' + context.state.gameId, token, context.state.matchGame);
      }
    },
    async finAction(context: any, isFin: boolean) {
      const token = context.getters.getToken();
      const postParam = {
        g_status:  6,
        eg_id:     context.state.event.gameId,
      };
      return await libAxios.put('game-data/match/' + context.state.gameId, token, postParam)
      .then(() => {
        window.localStorage.removeItem('currentGame');
        context.commit('clearPostData');
        if (isFin) {
          context.commit('clearData');
        } else {
          context.commit('retryData');
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    },

    // TODO END
    // 個別
    setConfigAction(context: any, configData: {[key: string]: number}) {
      context.commit('setConfig', configData);
    },
    setConfigParamAction(context: any, configParam: {key: string, val: number}) {
      context.commit('setConfigParam', configParam);
    },
    // 共通
    addRaceToAction(context: any, param: {isFirst: boolean, addPoint: number}) {
      if (param.isFirst) {
        context.commit('addPlayer1RaceTo', param.addPoint);
      } else {
        context.commit('addPlayer2RaceTo', param.addPoint);
      }
    },
    addScoreAction(context: any, param: {isFirst: boolean, addPoint: number}) {
      if (param.isFirst) {
        context.commit('addPlayer1Score', param.addPoint);
      } else {
        context.commit('addPlayer2Score', param.addPoint);
      }
    },
    setScoreAction(context: any, param: {isFirst: boolean, point: number}) {
      if (param.isFirst) {
        context.commit('setPlayer1Score', param.point);
      } else {
        context.commit('setPlayer2Score', param.point);
      }
    },
    addResultAction(context: any, param: {isFirst: boolean, addPoint: number, isRunOut: boolean}) {
      if (param.isFirst) {
        context.commit('addPlayer1Score', param.addPoint);
        if (param.isRunOut) {
          context.commit('addGameResult', 'A');
        } else {
          context.commit('addGameResult', 'a');
        }
      } else {
        context.commit('addPlayer2Score', param.addPoint);
        if (param.isRunOut) {
          context.commit('addGameResult', 'B');
        } else {
          context.commit('addGameResult', 'b');
        }
      }
    },
    setHighRunAction(context: any, param: {isFirst: boolean, highRun: number}) {
      if (param.isFirst) {
        if (context.state.matchGame.highrun_1 < param.highRun) {
          context.commit('setPlayer1Highrun', param.highRun);
        }
      } else {
        if (context.state.matchGame.highrun_2 < param.highRun) {
          context.commit('setPlayer2Highrun', param.highRun);
        }
      }
    },
    setWinnerAction(context: any, winner: number) {
      context.commit('setGameWinner', winner);
    },
    setWinnerInningAction(context: any, param: {winner: number, player1Inning: number, player2Inning: number}) {
      context.commit('setGameWinner', param.winner);
      context.commit('setTurnAvr', {p1Inning: param.player1Inning, p2Inning: param.player2Inning});
    },
    popResultAction(context: any) {
      if (context.state.matchGame.result === '') {
        return;
      }
      const lastWin = context.state.matchGame.result.slice(-1);
      if (lastWin === 'a' || lastWin === 'A') {
        context.commit('addPlayer1Score', -1);
      } else {
        context.commit('addPlayer2Score', -1);
      }
      const popResult = context.state.matchGame.result.slice(0, -1);
      context.commit('setGameResult', popResult);
      context.commit('setGameWinner', 0);
    },
    destroyAction(context: any) {
      context.commit('clearData');
      // TODO NET
      window.localStorage.removeItem('currentGame');
    },
    clearDataAction(context: any) {
      context.commit('clearPostData');
    },
    resumeAction(context: any, resumeData: {[key: string]: any}) {
      context.commit('setResumeData', resumeData);
    },
  },
};
