






















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {SoloDataParam} from '@/model/interface/SoloDataParam';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class BowlardTable extends Vue {
  @Prop() private soloData!: SoloDataParam;
  @Prop() private currentNum!: number;

  private boxChar(num: number): string {
    const changeParam = this.currentNum;
    return this.soloData.boxChars[num];
  }

  private frameScore(num: number): number|string {
    const changeParam = this.currentNum;
    const frameNum = Math.floor( this.soloData.boxNum / 2 );
    if (num <= frameNum) {
      return this.soloData.frameScore[num];
    } else {
      return '';
    }
  }
}
