














































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {MatchParam}        from '@/model/interface/MatchParam';
import {MatchPlayerParam}  from '@/model/interface/MatchPlayerParam';
import {MatchDataParam}    from '@/model/interface/MatchDataParam';
import {PlayPointParam}    from '@/model/interface/PlayPointParam';
import MatchHeader         from '@/components/score/match/MatchHeader.vue';
import NamePlate           from '@/components/score/match/NamePlate.vue';
import ConfigRaceTo        from '@/components/score/match/ConfigRaceTo.vue';
import GameRotationConfig  from '@/components/score/match/GameRotationConfig.vue';
import MatchConfig         from '@/components/score/match/MatchConfig.vue';
import PointPlate          from '@/components/score/match/PointPlate.vue';
import GameStartPanel      from '@/components/score/match/GameStartPanel.vue';
import GameRotationBreak   from '@/components/score/match/GameRotationBreak.vue';
import YesNoPanel          from '@/components/score/match/YesNoPanel.vue';
import GameRotationPlayLv1 from '@/components/score/match/GameRotationPlayLv1.vue';
import GameRotationPlay    from '@/components/score/match/GameRotationPlay.vue';
import StopWatch           from '@/components/common/StopWatch.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    MatchHeader,
    NamePlate,
    ConfigRaceTo,
    GameRotationConfig,
    MatchConfig,
    PointPlate,
    GameStartPanel,
    GameRotationBreak,
    YesNoPanel,
    GameRotationPlayLv1,
    GameRotationPlay,
    StopWatch,
  },
})
export default class GameRotation extends Vue {
  private isLoading: boolean = true;
  private isSetting: boolean = true;
  private isReverse: boolean = false;
  private usrType!: number;
  private eventId!: number;
  private config!: {[key: string]: number};
  private matchData!: MatchDataParam;
  private baseTime: number = 0;
  private preScore1: number = 0;
  private preScore2: number = 0;

 private get scoreLv(): number {
    return this.$store.getters['match/getConfigParam']('scoreLv');
  }

  private get useWatch(): number {
    return this.$store.getters['match/getConfigParam']('stopWatch');
  }

  private created(): void {
    try {
      this._checkLogin().
      then((result: boolean) => {
        this.usrType = this.$store.getters.getUsrType();
        this.eventId = this.$store.getters['match/getEventId']();
        this._created();
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private _created(): void {
    const suspend = this._getResumeData(this.C_NUM_ROTE);
    if (suspend === '') {
      this.$router.push({name: 'error'}); // SYSTEM ERROR
    }

    const tmpGameId = this.$store.getters['match/getGameId']();
    if (tmpGameId !== 0) {
      if (tmpGameId !== suspend.gameId) {
        this.$router.push({name: 'error'}); // SYSTEM ERROR
      }
    } else { // suspendからすべてを復活させる
      this.$store.dispatch('match/resumeAction', suspend);
    }

    const tmpStatus = this.$store.getters['match/getGameStatus']();
    this.matchData = {
      viewMode: this.C_VIEW_START,
      isFirst: true, // Player1?
      firstPlayer: 0,
      gameNum: -1,
      gameParam: [],
      player1foul: 0, // 使う
      player2foul: 0, // 使う
      balls: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      isRunOut: true, // 使う
      canPush: true, // 使う
    };
    this.$store.dispatch('match/setScoreAction', {isFirst: true, point: 0});
    this.$store.dispatch('match/setScoreAction', {isFirst: false, point: 0});

    if (tmpStatus === this.C_STATUS_INIT) {
      const tmpConfig = Object.assign(this.C_CFG_ROTE_PARAM, this.C_CFG_MATCH_COMMON);
      this.config = this._getConfig(tmpConfig, this.C_NAME_ROTE);
      if (this.eventId !== 0) {
        const eventConfig = this.$store.getters['match/getConfig']();
        for (const param of Object.keys(eventConfig)) {
          tmpConfig[param] = eventConfig[param];
        }
      }
      this.$store.dispatch('match/setConfigAction', this.config);
    } else if (tmpStatus === this.C_STATUS_READY) {
      this.config = this.$store.getters['match/getConfig']();
      this.isSetting = false;
    } else if (tmpStatus === this.C_STATUS_PLAY) {
      this.config = this.$store.getters['match/getConfig']();
      this.matchData.firstPlayer = this.$store.getters['match/getFirst']();

      // ここで C_STATUS_PLAY
      const dateTime = new Date() ;
      this.baseTime = Math.floor( dateTime.getTime() / 1000 ) ;
      const tmpParam = this.$store.getters['match/getMatchGameParam']();
      if (tmpParam === '') {
        this.$router.push({name: 'error'}); // SYSTEM ERROR
      }

      this.matchData.gameParam = tmpParam.split(',');
      this.calcParam();
      this.isSetting = false;
    } else {
      // TODO
    }
    this.isLoading = false;
    this.$forceUpdate();
  }

  private calcParam(): void {
    const isLv1 = (this.config.scoreLv === 1);
    for (const param of this.matchData.gameParam) {
      if (param[0] === 'A' ) {
        this.matchData.isFirst = true;
      } else if (param[0] === 'B' ) {
        this.matchData.isFirst = false;
      } else {
        return;
      }
      // dummy break
      this.setBreakParam();

      const tmpBuf = param.split(';');

      const maxLen = tmpBuf.length;
      for (let i = 1; i < maxLen; i++) {
        const tmp1Set = tmpBuf[i].split(':');
        const tmpBalls: number[] = [];
        if (tmp1Set.length !== 1) {
          const strLen = tmp1Set[1].length;
          for (let j = 0; j < strLen; j++) {
            tmpBalls.push(parseInt(tmp1Set[1].charAt(j), 16));
          }
        }
        if (tmp1Set[0] === 'a' || tmp1Set[0] === 'b') {
          this.execActionLv1(tmp1Set[0], tmpBalls);
        } else {
          this.execAction(tmp1Set[0], tmpBalls, false);
        }
      }
    }
  }

  private execActionLv1(action: string, balls: number[]): void {
    const len = balls.length;
    if (len !== 0) {
      for (let i = 0; i < len; i++) {
        if (action === 'a') {
          // this.matchData.balls[balls[i] - 1] = 1;
          this.$set(this.matchData.balls, balls[i] - 1, 1);
          this.$store.dispatch('match/addScoreAction', {isFirst: true, addPoint: balls[i]});
        } else {
          this.matchData.balls[balls[i] - 1] = 2;
          this.$store.dispatch('match/addScoreAction', {isFirst: false, addPoint: balls[i]});
        }
      }
      this.matchData.viewMode = this.C_VIEW_PLAY;
    }
  }

  private toGame(): void {
    // Status
    const tmpStatus: number = this.$store.getters['match/getGameStatus']();
    if (tmpStatus < this.C_STATUS_READY) {
      this.$store.dispatch('match/readyAction');
    } else {
      this.$store.dispatch('match/reStartAction');
    }
    this.config = this.$store.getters['match/getConfig']();
    this.isSetting = false;
  }

  private gameStart(playerNum: number): void {
    this.matchData = {
      viewMode: this.C_VIEW_START,
      isFirst: true,
      firstPlayer: playerNum,
      gameNum: -1,
      gameParam: [],
      player1foul: 0, // 使う
      player2foul: 0, // 使う
      balls: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      isRunOut: true, // 使う
      canPush: true, // 使う
    };

    // ここで C_STATUS_PLAY
    const dateTime = new Date() ;
    this.baseTime = Math.floor( dateTime.getTime() / 1000 ) ;

    const startParam = {
      startDateTime: this._formatDate(dateTime, ''),
      first: playerNum,
    };
    this.$store.dispatch('match/startAction', startParam);
    this.toBreak(playerNum);
  }

  private undo(): void {
    this.isLoading = true;

    let maxNum = this.matchData.gameNum;
    let lastParam = this.matchData.gameParam[maxNum];
    if (lastParam.length === 1) {
      if (maxNum === 0) {
        this.$forceUpdate();
        return;
      } else {
        maxNum--;
        this.matchData.gameNum--;
        this.matchData.gameParam.pop();
        lastParam = this.matchData.gameParam[maxNum];
      }
    }
    const tmpBuf = lastParam.split(';');
    tmpBuf.pop();
    this.matchData.gameParam[maxNum] = tmpBuf.join(';');
    this.$store.dispatch('match/setScoreAction', {isFirst: true, point: 0});
    this.$store.dispatch('match/setScoreAction', {isFirst: false, point: 0});
    this.matchData.viewMode = this.C_VIEW_START;
    this.matchData.isFirst = true;
    this.matchData.firstPlayer = 0;
    this.matchData.gameNum = -1;
    this.calcParam();
    setTimeout(
      this.reView,
      100,
    );
  }

  private reView(): void {
    this.isLoading = false;
    this.$forceUpdate();
  }

  private toBreak(playerNum: number): void {
    if (playerNum === 1) {
      this.matchData.isFirst = true;
      this.matchData.gameParam.push('A');
    } else {
      this.matchData.isFirst = false;
      this.matchData.gameParam.push('B');
    }
    this.setBreakParam();
    this.$forceUpdate();
  }

  private setBreakParam(): void {
    this.matchData.gameNum++;
    this.matchData.player1foul = 0;
    this.matchData.player2foul = 0;
    this.matchData.balls = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.matchData.isRunOut = true;
    this.matchData.canPush = true;

    this.matchData.viewMode = this.C_VIEW_BREAK;
    this.preScore1 = this.$store.getters['match/getPlayer1Score']();
    this.preScore2 = this.$store.getters['match/getPlayer2Score']();
  }

  private postPlayData(): void {
    const dateTime = new Date() ;
    const nowDate = Math.floor( dateTime.getTime() / 1000 ) ;

    const saveParam: {[key: string]: any} = {
      endDateTime: this._formatDate(dateTime, ''),
      playTimeSpan: nowDate - this.baseTime,
      param: this.matchData.gameParam.join(','),
    };
    this.$store.dispatch('match/saveAction', saveParam);
  }

  private setBall(val: number, num: number): void {
    this.matchData.balls[num]  = val;
  }

  private setBalls(balls: number[]): void {
    this.matchData.balls = balls;
  }

  private actionTypeLv1(action: string, balls1: number[], balls2: number[]): void {
    const len1 = balls1.length;
    let str1 = '';
    if (len1 !== 0) {
      for (let i = 0; i < len1; i++) {
        str1 += balls1[i].toString(16);
      }
      this.matchData.gameParam[this.matchData.gameNum] += ';a:' + str1;
    }
    const len2 = balls2.length;
    let str2 = '';
    if (len2 !== 0) {
      for (let i = 0; i < len2; i++) {
        str2 += balls2[i].toString(16);
      }
      this.matchData.gameParam[this.matchData.gameNum] += ';b:' + str2;
    }
    this.postPlayData();
    if (action === 'A') {
      this.toBreak(1);
    } else if (action === 'B') {
      this.toBreak(2);
    } else if (action === 'W') {
      this.matchData.viewMode = this.C_VIEW_FIN;
      this.$forceUpdate();
    }
  }

  private actionType(action: string, balls: number[]): void {
    this.execAction(action, balls, true);
  }

  private execAction(action: string, balls: number[], isView: boolean): void {
    let pointParam!: PlayPointParam;

    switch (action) {
      case 'm': // ブレイクno-in
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_PLAY,   change: true,  foul: false, point: false};
        break;
      case 'c': // ブレイク change
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_BREAK,  change: true,  foul: true,  point: false};
        break;
      case 'p': // break in
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_PLAY,   change: false, foul: false, point: false};
        break;
      case 'g': // ブレイクスクラッチ
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_PLAY,   change: true,  foul: true,  point: false};
        break;
      case 'f': // ブレイクファール
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_PLAY,   change: true,  foul: true,  point: false};
        break;
      case 'w': // win
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_BREAK,  change: false, foul: false, point: true};
        break;
      case 'J': // Pushからのプレイ
        pointParam = {mode: this.C_VIEW_OPTION, next: this.C_VIEW_PLAY,   change: false, foul: false, point: false};
        break;
      case 'K': // Pushからのパス
        pointParam = {mode: this.C_VIEW_OPTION, next: this.C_VIEW_PLAY,   change: true,  foul: false, point: false};
        break;
      case 'H': // Push
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_OPTION, change: true,  foul: false, point: false};
        break;
      case 'W': // win
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_BREAK,  change: false, foul: false, point: true};
        break;
      case 'R': // runout
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_BREAK,  change: false, foul: false, point: true};
        break;
      case 'S': // safety
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: true,  foul: false, point: false};
        break;
      case 'M': // MISS
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: true,  foul: false, point: false};
        break;
      case 'G': // スクラッチ
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: true,  foul: true, point: false};
        break;
      case 'F': // ファール
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: true,  foul: true, point: false};
        break;
      case 'P':
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: false,  foul: false, point: false};
        break;
      case 'O': // option
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_OPTION, change: true,   foul: true, point: false};
        break;
      case 'X':
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_BREAK,  change: false,  foul: false, point: false};
        break;
      case 'Y':
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_BREAK,  change: true,   foul: false, point: false};
        break;
      default:
        // ERROR
        break;
    }

    const len = balls.length;
    let str = '';
    if (len !== 0) {
      for (let i = 0; i < len; i++) {
        str += balls[i].toString(16);
      }
    }

    if (pointParam.mode === this.C_VIEW_BREAK) {
      // なにもしない
    } else if (pointParam.mode === this.C_VIEW_PLAY) {
      if (action !== 'H') {
        this.matchData.canPush = false;
      }
    }
    if (isView) {
      this.matchData.gameParam[this.matchData.gameNum] += ';' + action + ':' + str;
      this.calcPoint(pointParam);
    } else {
      this.calcSimple(pointParam, balls);
    }
  }

  private calcSimple(pointParam: PlayPointParam, balls: number[]): void {
    const len = balls.length;
    if (len !== 0) {
      for (let i = 0; i < len; i++) {
        if (this.matchData.isFirst) {
          this.matchData.balls[balls[i] - 1] = 1;
          this.$store.dispatch('match/addScoreAction', {isFirst: true, addPoint: balls[i]});
        } else {
          this.matchData.balls[balls[i] - 1] = 2;
          this.$store.dispatch('match/addScoreAction', {isFirst: false, addPoint: balls[i]});
        }
      }
    }

    if (pointParam.foul) {
      if (this.matchData.isFirst) {
        this.matchData.player1foul++;
      } else {
        this.matchData.player2foul++;
      }
    } else {
      if (this.matchData.isFirst) {
        this.matchData.player1foul = 0;
      } else {
        this.matchData.player2foul = 0;
      }
    }

    if (pointParam.change) {
      this.matchData.isFirst = !this.matchData.isFirst;
    }

    this.matchData.viewMode = pointParam.next;
  }

  private calcPoint(pointParam: PlayPointParam): void {
    if (this.config.threeFoul) { // 3ファールがある。
      if (pointParam.foul) {
        if (this.matchData.isFirst) {
          this.matchData.player1foul++;
          if (this.matchData.player1foul >= 3) {
            alert('3 Foul');
          }
        } else {
          this.matchData.player2foul++;
          if (this.matchData.player2foul >= 3) {
            alert('3 Foul');
          }
        }
      } else {
        if (this.matchData.isFirst) {
          this.matchData.player1foul = 0;
        } else {
          this.matchData.player2foul = 0;
        }
      }
    }

    if (pointParam.change) {
      this.matchData.isFirst = !this.matchData.isFirst;
    }

    if (pointParam.point === true) {
      this.matchData.viewMode = this.C_VIEW_FIN;
    } else {
      if (pointParam.next === this.C_VIEW_BREAK) {
        if (this.matchData.isFirst) {
          this.toBreak(1);
        } else {
          this.toBreak(2);
        }
      }
      this.matchData.viewMode = pointParam.next;
    }
    // status check
    this.postPlayData();
    this.$forceUpdate();
  }

  private gameFin(isFin: boolean): void {
    this.isLoading = true;
    this.isSetting = true;
    try {
      if (this.usrType === this.C_MODE_GUEST) { // ゲストの場合はデータ破棄
        if (isFin) {
          this.$store.dispatch('match/destroyAction');
          this.$router.push({name: 'score'});
        } else {
          this.$store.dispatch('match/retryGuestAction');
          this._created();
        }
      } else {
        this.$store.dispatch('match/finAction', isFin)
        .then(() => {
          if (this.eventId !== 0) {
            this.$router.push({ name: 'event-match', params: {type: this.C_NAME_MATCH} });
          } else {
            if (isFin) {
              if (this.usrType === this.C_MODE_MEMBER) {
                this.$router.push({name: 'score-list', params: {type: this.C_NAME_ROTE}});
              } else {
                this.$router.push({name: 'score'});
              }
            } else {
              this.$store.dispatch('match/retryAction')
              .then(() => {
                this._created();
              }).catch((e) => {
                throw new Error(e.message);
              });
            }
          }
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      this.matchData.viewMode = this.C_VIEW_ERROR;
      this.isLoading = true;
    }
  }

  private reverse(): void {
    this.isReverse = !this.isReverse;
  }

  private setting(): void {
    this.isSetting = true;
  }

  private destroy(): void {
    if (window.confirm('ここまでのデータを破棄しますか？')) {
      this.$store.dispatch('match/destroyAction');
      this.$router.push({name: 'score'});
    }
  }
}
