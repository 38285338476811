















import {Component, Prop, Vue} from 'vue-property-decorator';
import AdminHeader  from '@/components/admin/common/AdminHeader.vue';
import DeptParam    from '@/components/admin/DeptParam.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    AdminHeader,
    DeptParam,
  },
})
export default class DeptDetail extends Vue {
  private orgId: number = 0;
  private parentId: number = 0;
  private parentParam!: {[key: string]: any};
  private errParam!: {[key: string]: any};

  private breadItems: Array<{[key: string]: any}> = [
    { text: '管理Top', to: { name: 'admin-top' }},
    { text: '組織一覧', to: { name: 'admin-org-list' }},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        this.errParam = {};
        this.orgId = parseInt(this.$route.params.id, 10);
        this.parentId = parseInt(this.$route.params.parent, 10);
        if (this.parentId === 0) {
          this.getOrgData();
        } else {
          this.getDeptData();
        }
      }).catch((e: Error) => {
        // throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getOrgData() {
    const token = this.$store.getters.getToken();
    libAxios.get('org-for-org/' + this.orgId, token, {})
    .then((param: {[key: string]: any}) => {
      this.parentParam = param;
      this.breadItems.push({ text: param.o_name + '部門一覧', to: { name: 'admin-org-tree', params: {id: param.o_id} }});
      this.breadItems.push({ text: '新規作成', active: true});
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async getDeptData() {
    const token = this.$store.getters.getToken();
    libAxios.get('dept-for-org/' + this.parentId, token, {})
    .then((param: {[key: string]: any}) => {
      this.parentParam = param;
      this.breadItems.push({ text: param.o_name + '部門一覧', to: { name: 'admin-org-tree', params: {id: param.o_id} }});
      this.breadItems.push({ text: '新規作成', active: true});
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async saveData(sendParam: {[key: string]: any} ) {
    this.errParam = {};
    sendParam.parent_id = this.parentId;
    sendParam.org_id = this.orgId;
    const token = this.$store.getters.getToken();
    return await libAxios.post('dept-for-org' , token, sendParam)
    .then((param: {[key: string]: any}) => {
      alert('更新しました');
      this.$router.push({ name: 'admin-dept-detail', params: {id: param.pk} });
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
