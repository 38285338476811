

















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class SoloHeader extends Vue {
  @Prop() private isSetting!: boolean;
  @Prop() private viewMode!: number;
  @Prop() private isFin!: boolean;

  @Emit('undo') private undo(): void { return; }
  @Emit('destroy') private destroy(): void { return; }

  private get useUndo(): boolean {
    if (this.isSetting || this.viewMode === this.C_VIEW_START) {
      return true;
    } else {
      return false;
    }
  }

  private get playTimeSpan(): string {
    const status = this.$store.getters['solo/getGameStatus']();
    const playTimeSpan = this.$store.getters['solo/getTimeSpan']();
    if (status < this.C_STATUS_PLAY) {
      return '準備中';
    } else {
      if (this.viewMode === this.C_VIEW_START) {
        return 'START';
      } else {
        const tmpTimeSpan = Math.floor(playTimeSpan);
        return this._formatTimeSpan(tmpTimeSpan);
      }
    }
  }
}
