





























import {Component, Prop, Vue} from 'vue-property-decorator';
import AdminHeader  from '@/components/admin/common/AdminHeader.vue';
import OrgParam     from '@/components/admin/OrgParam.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    AdminHeader,
    OrgParam,
  },
})
export default class OrgDetail extends Vue {
  private isReady: boolean = false;
  private orgId: number = 0;
  private orgParam!: {[key: string]: any};
  private editDateTime: string = '';
  private errParam: string[] = [];
  private connection: boolean = false;

  private breadItems: Array<{[key: string]: any}> = [
    { text: '管理Top', to: { name: 'admin-top' }},
    { text: '組織一覧', to: { name: 'admin-org-list' }},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        this.orgId = parseInt(this.$route.params.id, 10);
        this.getOrgData();
      }).catch((e: Error) => {
        // throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getOrgData() {
    const token = this.$store.getters.getToken();
    libAxios.get('org-for-org/' + this.orgId + '/edit', token, {})
    .then((param: {[key: string]: any}) => {
      this.orgParam = param;
      this.editDateTime = param.edit_dt;
      this.breadItems.push({ text: param.o_name + '編集', active: true});
      this.isReady = true;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private get refs(): any {
    return this.$refs;
  }

  private async saveData(sendParam: {[key: string]: any} ) {
    this.connection = true;
    this.errParam = [];
    sendParam.is_new = 0;
    sendParam.edit_dt = this.editDateTime;
    const token = this.$store.getters.getToken();
    return await libAxios.put('org-for-org/' + this.orgId, token, sendParam)
    .then((param: {[key: string]: any}) => {
      this.editDateTime = param.edit_dt;
      alert('更新しました');
      this.connection = false;
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        for (const err of Object.keys(tmpJson.validate)) {
          this.errParam.push(err);
        }
        window.console.log(this.errParam);
        this.refs.org_param.checkErrMsg();
      } else {
        this.$router.push({name: 'error'});
      }
      this.connection = false;
    });
  }
}
