






























































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {PointPlayerParam} from '@/model/interface/PointPlayerParam';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class PointPanel extends Vue {
  @Prop() private playerData!: PointPlayerParam[];
  @Prop() private currentNum!: number;
  @Prop() private frameData!: Array<{breakNum: number, isDouble: boolean, isRunout: boolean}>;
  private scores: number[] = [];
  private playerLen!: number;

  @Emit('updatePoint') private updatePoints(num: number, index: number): void { return; }
  @Emit('nextFrame') private nextFrame(index: number, isRunout: boolean): void { return; }
  @Emit('clearPoint') private clearPoint(index: number): void { return; }

  private get playerCountClass(): string {
    if (this.playerLen > 5) {
      return 'player-count6';
    } else {
      return 'player-count' + this.playerLen.toString();
    }
  }

  private useNextBtn(index: number): boolean {
    if (this.playerData[index].tmpScore === 0) {
      return false;
    } else {
      return true;
    }
  }

  private useRunoutBtn(index: number): boolean {
    if (this.frameData[this.currentNum].breakNum !== index) {
      return false;
    }

    for (let i = 0; i < this.playerLen; i++) {
      if (i === index) {
        if (this.playerData[i].tmpScore === 0) {
          return false;
        }
      } else {
        if (this.playerData[i].tmpScore !== 0) {
          return false;
        }
      }
    }
    return true;
  }

  private created(): void {
    this.playerLen = this.playerData.length;
  }

  private updatePoint(num: number, index: number): void {
    this.updatePoints(num, index);
    this.$forceUpdate();
  }

  private clearWork(index: number): void {
    this.clearPoint(index);
    this.$forceUpdate();
  }
}
