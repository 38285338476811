



















































































import {Component, Vue, Watch} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue';
import EventDetail      from '@/components/event/EventDetail.vue';
import libAxios        from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    EventDetail,
  },
})
export default class Event extends Vue {
  private connection: boolean = false;
  private usrType: number = this.$store.getters.getUsrType();
  private usrSex: number = this.$store.getters.getSex();
  private currentEvent: {[key: string]: any} = {};
  private currentTitle: string = '';
  private eventList: Array<{[key: string]: any}> = [];
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
  ];
  private modalEventInfo: boolean = false;
  private regularId: string = '';
  private regularTitle: string = '';

  private serachParam: {[key: string]: any} = {};
  private currentPage: number = 0;
  private rows: number = 0;
  private perPage: number = 12;

  @Watch('currentPage')
  private onTextChanged(newNum: number, oldNum: number) {
    this.serachParam.page_num = newNum;
    this.$store.dispatch('searchEventAction', this.serachParam);
    this.getEvents();
  }

  private checkEntry(event: {[key: string]: any}): boolean {
    if (this.usrType !== this.C_MODE_MEMBER || !this._bitCalc(event.e_mode, 2)) {
      return false;
    }
    // 性別チェック
    if (event.cond_sex !== 0 && this.usrSex !== event.cond_sex) {
      return false;
    }
    return true;
  }

  private created(): void {
    try {
      let pageNum = 1;
      const saveParam = this.$store.getters.getSearchEvent();
      if (saveParam) {
        this.serachParam = JSON.parse(saveParam);
      }
      if (this.serachParam.page_num) {
        pageNum = this.serachParam.page_num;
      }

      if (this.$route.path.substr(0, 14) === '/regular-event') {
        this.regularId = this.$route.params.id;
        this.serachParam.regular_event_id = this.$route.params.id;
        this.regularTitle = this.$route.params.str;
        this.breadItems.push({ text: '定例Event一覧', to: {name: 'regular-event'}});
        this.breadItems.push({ text: this.regularTitle + '一覧', active: true});
      } else {
        // TODO
        this.breadItems.push({ text: 'Event一覧', active: true});
      }
      this._checkLogin().
      then((isLogin: boolean) => {
        this._getResumeData(0); // Gameではないので戻り値は無視
        this.currentPage = pageNum;
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getEvents() {
    this.connection = true;
    if (this.usrType === this.C_MODE_GUEST) {
      this.getEventsForGuest();
    } else {
      this.getEventsForMember();
    }
  }

  private async getEventsForMember() {
    const token = this.$store.getters.getToken();
    return await libAxios.get('event-info', token, this.serachParam)
    .then((param: {[key: string]: any}) => {
      this.rows = param.cnt;
      this.eventList = param.data;
      this.connection = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async getEventsForGuest() {
    return await libAxios.getNoToken('event-list', this.serachParam)
    .then((param: {[key: string]: any}) => {
      this.rows = param.cnt;
      this.eventList = param.data;
      this.connection = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private toDetail(eventId: string) {
    if (this.regularId === '') {
      this.$router.push({name: 'event-info', params: {id: eventId}});
    } else {
      this.$router.push({
        name: 'regular-event-event-info',
        params: {id1: this.regularId, id2: eventId, str: this.regularTitle},
      });
    }
  }

  private showInfo(num: number) {
    this.currentEvent = this.eventList[num];
    this.currentTitle = this.currentEvent.title;
    this.modalEventInfo = true;
  }
}
