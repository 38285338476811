




































import {Component, Vue} from 'vue-property-decorator';
import {Department} from '@/model/class/department';
import {User}       from '@/model/class/user';
import Header       from '@/components/common/Header.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class PersonalMenu extends Vue {
  private isLoading: boolean = true;
  private error: string = '';
  private usrType: number = 0;
  private rolesFlg: {canEditInfo: boolean, canEditMsg: boolean, canEditEvent: boolean, canEditAdmin: boolean}
    = this.$store.getters.getRolesFlg();

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: '個人メニュー', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.usrType = this.$store.getters.getUsrType();
          this.isLoading = false;
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e);
      this.$router.push({name: 'error'});
    }
  }

  private logout(): void {
    this.isLoading = true;
    const token = this.$store.getters.getToken();
    if (token !== '') { // そもそもこのページでTokenなしはないが念のため
      libAxios.asyncPost('logout', token, {});
      this.$store.dispatch('logoutAction');
      this.$router.push({name: 'top'});
    }
  }

  private async update() {
    this.isLoading = true;
    const token = this.$store.getters.getToken();
    const postParam = {
      usr_id: this.$store.getters.getUsrId(),
    };
    return await libAxios.post('update-login', token, postParam)
    .then((param: {[key: string]: any}) => {
      const tmpDepts = [];
      for (const dept of param.departments) {
        const tmpUsrs = [];
        for (const usr of dept.users) {
          if (usr.id === param.usrId) {
            continue; // 自分自身は入れない
          }
          tmpUsrs.push(
            new User(
              usr.id,
              usr.name,
              usr.nameShort,
            ),
          );
        }
        tmpDepts.push(
          new Department(
            dept.id,
            dept.name,
            dept.numOfTable,
            dept.isUsePlace,
            tmpUsrs,
          ),
        );
      }
      param.departments = tmpDepts;
      this.$store.dispatch('loginAction', param);
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e); // throwしない
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        this.error = 'val err';
      } else if (tmpJson.message) {
        this.error = tmpJson.message;
      } else {
        this.$router.push({name: 'error'});
      }
    });
  }
}
