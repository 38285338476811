

























































import {Component, Prop, Vue} from 'vue-property-decorator';
import Header        from '@/components/common/Header.vue';
import {MatchParam}       from '@/model/interface/MatchParam';
import {MatchPlayerParam} from '@/model/interface/MatchPlayerParam';
import {MatchDataParam}   from '@/model/interface/MatchDataParam';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class EventMatch extends Vue {
  private usrType!: number;
  private eventName!: string;
  private eventList: any[] = [];
  private connection: boolean = true;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: 'Score', to: { name: 'score' }},
    { text: 'イベント一覧', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this._getResumeData(0); // Gameではないので戻り値は無視
          this.usrType = this.$store.getters.getUsrType();
          this.eventName = this.$route.params.type;
          this.getEventList();
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getEventList() {
    this.connection = true;
    const searchParam = {
      usr_id: this.$store.getters.getUsrId(),
      event_type: this.eventName,
    };

    const token = this.$store.getters.getToken();
    libAxios.get('game-data/event', token, searchParam)
    .then((response) => {
      this.eventList = response.data;
      this.connection = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private startGame(num: number, isNew: boolean) {
    const tmpParam = this.eventList[num];
    const tmpEvent = {
      id: tmpParam.e_id,
      gameId: tmpParam.eg_id,
      name: tmpParam.event_title + ' ' + tmpParam.step_title,
      playerId1: tmpParam.ep_id_1,
      playerId2: tmpParam.ep_id_2,
    };
    window.console.log(tmpParam);
    const tmpConfig = this.changeConfig(JSON.parse(tmpParam.config));
    tmpConfig.isEdit = tmpParam.is_edit; // isEdit

    // 置換処理
    const gameParam: {[key: string]: any} = {
      gameType: tmpParam.eg_type,
      place: {id: tmpParam.d_id, name: tmpParam.d_name, tableNum: tmpParam.d_tbl},
      player1: {id: tmpParam.u_id_1, name: tmpParam.u_name_1, race_to: tmpParam.race_to_1},
      player2: {id: tmpParam.u_id_2, name: tmpParam.u_name_2, race_to: tmpParam.race_to_2},
      checked: 3,
      event: tmpEvent,
      config: tmpConfig,
    };
    if (isNew) {
      this.$store.dispatch('match/initEventAction', gameParam)
      .then(() => {
        this.toGame(tmpParam.eg_type);
      }).catch((e) => {
        window.console.log(e);
      });
    } else {
      gameParam.g_id = tmpParam.g_id;
      this.$store.dispatch('match/resumeEventAction', gameParam)
      .then(() => {
        this.toGame(tmpParam.eg_type);
      }).catch((e) => {
        window.console.log(e);
      });
    }
  }

  private toGame(gameType: number): void {
    if (gameType === this.C_NUM_9B) {
      this.$router.push({name: 'score-9ball'});
    } else if (gameType === this.C_NUM_10B) {
      this.$router.push({name: 'score-10ball'});
    } else if (gameType === this.C_NUM_14_1) {
      this.$router.push({name: 'score-14one'});
    } else if (gameType === this.C_NUM_ROTE) {
      this.$router.push({name: 'score-rotation'});
    } else {
      this.$router.push({name: 'error'});
    }
  }

  private changeConfig(params: {[key: string]: number}): {[key: string]: number} {
    const temConf: {[key: string]: number} = {};
    for (const param of Object.keys(params)) {
      switch (param) {
        case 'double_hit':
          temConf.doubleHit = params.double_hit;
          break;
        case 'ruck_set':
          temConf.ruckSet = params.ruck_set;
          break;
        case 'is_open':
          temConf.isOpen = params.is_open;
          break;
        case 'stop_watch':
          temConf.stopWatch = params.stop_watch;
          break;
        //
        case 'break_order':
          temConf.breakOrder = params.break_order;
          break;
        case 'push_out':
          temConf.pushOut = params.push_out;
          break;
        case 'three_point':
          temConf.threePoint = params.three_point;
          break;
        case 'break_ace':
          temConf.breakAce = params.break_ace;
          break;
        case 'three_foul':
          temConf.threeFoul = params.three_foul;
          break;
        case 'call_shot':
          temConf.callShot = params.call_shot;
          break;
        case 'nine_position':
          temConf.ninePos = params.nine_position + 1;
          break;
        case 'break_box':
          temConf.breakBox = params.break_box;
          break;
        case 'limit_type':
          temConf.limitType = params.limit_type;
          break;
        case 'limit_inning':
          temConf.limitInning = params.limit_inning;
          break;
        case 'limit_time':
          temConf.limitTime = params.limit_time;
          break;
        case 'judge_type':
          temConf.judgeType = params.judge_type;
          break;
        default:
          temConf[param] = params[param];
          break;
      }
    }
    return temConf;
  }
}
