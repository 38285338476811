









































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Header       from '@/components/common/Header.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class ScoreSimple extends Vue {
  private scoreLeft: number = 0;
  private scoreRight: number = 0;
  private raceToLeft: number = 0;
  private raceToRight: number = 0;
  private pointLeft: number = 0;
  private pointRight: number = 0;
  private isFirst: boolean = true;
  // timer
  private isViewWatch: boolean = false;
  private stopWatch!: any;
  private workTime: number = 0;

  get _ScoreLeft(): number|string {
    if (this.raceToLeft > 0 && this.scoreLeft >= this.raceToLeft) {
      return 'W';
    } else {
      return this.scoreLeft;
    }
  }

  get _ScoreRight(): number|string {
    if (this.raceToRight > 0 && this.scoreRight >= this.raceToRight) {
      return 'W';
    } else {
      return this.scoreRight;
    }
  }

  get _RaceToLeft(): number|string {
    if (this.raceToLeft === 0) {
      return '';
    } else {
      return this.raceToLeft;
    }
  }

  get _RaceToRight(): number|string {
    if (this.raceToRight === 0 ) {
      return '';
    } else {
      return this.raceToRight;
    }
  }

  get _PointLeft(): number|string {
    if (this.pointLeft === 0) {
      return '';
    } else {
      return this.pointLeft;
    }
  }

  get _PointRight(): number|string {
    if (this.pointRight === 0 ) {
      return '';
    } else {
      return this.pointRight;
    }
  }

  get isLeft(): boolean {
    if (this.scoreLeft === 0 && this.scoreRight === 0) {
      return false;
    } else {
      return !this.isFirst;
    }
  }

  get isRight(): boolean {
    if (this.scoreLeft === 0 && this.scoreRight === 0) {
      return false;
    } else {
      return this.isFirst;
    }
  }

  get btnColorLeft(): string {
    if (this.isLeft) {
      if ((this.scoreLeft >= (this.raceToLeft - 1)) && (this.raceToLeft > 1)) {
        return 'btn-outline-danger';
      } else {
        return 'btn-outline-secondary';
      }
    } else {
      if ((this.scoreLeft >= (this.raceToLeft - 1)) && (this.raceToLeft > 1)) {
        return 'btn-danger';
      } else {
        return 'btn-secondary';
      }
    }
  }

  get btnColorRight(): string {
    if (this.isRight) {
      if ((this.scoreRight >= (this.raceToRight - 1)) && (this.raceToRight > 1)) {
        return 'btn-outline-danger';
      } else {
        return 'btn-outline-secondary';
      }
    } else {
      if ((this.scoreRight >= (this.raceToRight - 1)) && (this.raceToRight > 1)) {
        return 'btn-danger';
      } else {
        return 'btn-secondary';
      }
    }
  }

  get ViewWatch(): string {
    return this.formatTimeHHSS(this.workTime);
  }

  private formatTimeHHSS(sec: number): string {
    const mm = Math.floor(sec / 60);
    const ss = sec % 60;

    let retStr = ('0' + mm).slice(-2);
    retStr += ':' + ('0' + ss).slice(-2);
    return retStr;
  }

  private addRaceToLeft(num: number): void {
    this.raceToLeft += num;
    if (this.raceToLeft < 0) {
      this.raceToLeft = 0;
    }
  }

  private addPointLeft(num: number): void {
    this.pointLeft += num;
    if (this.pointLeft < 0) {
      this.pointLeft = 0;
    }
  }

  private addRaceToRight(num: number): void {
    this.raceToRight += num;
    if (this.raceToRight < 0) {
      this.raceToRight = 0;
    }
  }

  private addPointRight(num: number): void {
    this.pointRight += num;
    if (this.pointRight < 0) {
      this.pointRight = 0;
    }
  }

  private addScoreLeft(num: number): void {
    this.isFirst = (num > 0);
    this.scoreLeft += num;
    if (this.scoreLeft < 0) {
      this.scoreLeft = 0;
    }
  }

  private addScoreRight(num: number): void {
    this.isFirst = (num < 0);
    this.scoreRight += num;
    if (this.scoreRight < 0) {
      this.scoreRight = 0;
    }
  }

  private reset(): void {
    this.scoreLeft = 0;
    this.scoreRight = 0;
  }

  private watchStart(): void {
    this.isViewWatch = true;
    this.workTime = 0;
    clearInterval(this.stopWatch);
    this.stopWatch = setInterval(() => {
      this.workTime++;
    }, 1000);
  }

  private watchStop(): void {
    clearInterval(this.stopWatch);
    this.isViewWatch = false;
  }
}
